import { MotorCarrier } from "@deathstar/motor-carrier";
import { DateTime } from "@deathstar/types/util";
import { classNames, Tooltip } from "@deathstar/ui";
import { AccessorColumnDef, ColumnDef } from "@tanstack/react-table";
import DataQIcon from "../../assets/icons/dataq-icon.png";
import OrangeCastIconSrc from "../../assets/icons/orange-cast.png";
import RedSkullAndCrossbonesIconSrc from "../../assets/icons/red-skull-and-crossbones.png";
import TowTruckIconSrc from "../../assets/icons/tow-truck.png";
import { accessorFnGenerator } from "../utils/accessorFnGenerator";
import { pluralize } from "../utils/pluralize";

const ascDateSorter = DateTime.Sorter("ASC");

const icons: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "icons",
    header: "",
    cell: (cell) => {
        const icons: { src: string; className?: string; tooltip: string; key: string }[] = [];

        if (!cell.row.original.isValid) {
            icons.push({
                src: DataQIcon,
                key: "dataq",
                tooltip: "DataQ Flagged",
                className: "m-[1px] h-[20px] w-[20px]",
            });
        } else {
            if (cell.row.original.get("TowAway")) {
                icons.push({ src: TowTruckIconSrc, key: "tow", tooltip: "Vehicle towed from crash" });
            }

            if (cell.row.original.get("TotalInjuries")) {
                icons.push({
                    src: OrangeCastIconSrc,
                    key: "injury",
                    tooltip: `${cell.row.original.get("TotalInjuries")} total ${pluralize(
                        cell.row.original.get("TotalInjuries"),
                        "injury",
                        "es"
                    )}`,
                });
            }

            if (cell.row.original.get("TotalFatalities")) {
                icons.push({
                    src: RedSkullAndCrossbonesIconSrc,
                    key: "fatalities",
                    tooltip: `${cell.row.original.get("TotalFatalities")} total ${pluralize(
                        cell.row.original.get("TotalFatalities"),
                        "fatality",
                        "es"
                    )}`,
                });
            }
        }

        return (
            <div className="flex items-center gap-2">
                {icons.map((ic) => (
                    <Tooltip title={ic.tooltip}>
                        <img className={classNames("h-4 w-4", ic.className)} src={ic.src} key={ic.key} alt="" />
                    </Tooltip>
                ))}
            </div>
        );
    },
    accessorFn: () => "",
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: (a, b) => {
        return ascDateSorter(a.original.date, b.original.date);
    },
};

const date: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "date",
    accessorFn: (row) => row.date.format("mm/dd/YYYY"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: (a, b) => {
        return ascDateSorter(a.original.date, b.original.date);
    },
};

const reportNumber: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "reportNumber",
    header: "Report Number",
    accessorFn: (row) => row.get("ReportNumber"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const state: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "state",
    accessorFn: (row) => row.get("ReportState"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const city: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "city",
    accessorFn: (row) => row.get("City"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const county: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "county",
    accessorFn: (row) => row.county,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const location: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "location",
    header: "Location",
    accessorFn: (row) => row.get("Location"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const latitude: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "latitude",
    accessorFn: (row) => row.get("CountyLatitude"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const longitude: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "longitude",
    accessorFn: (row) => row.get("CountyLongitude"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitLicenseNumber: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitLicenseNumber",
    header: "License Number",
    accessorFn: (row) => row.get("UnitLicense"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitLicenseState: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitLicenseState",
    header: "License State",
    accessorFn: (row) => row.get("UnitLicenseState"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitType: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitType",
    header: "Type",
    accessorFn: (row) => row.get("UnitType"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitVin: AccessorColumnDef<MotorCarrier.ICrash, string | undefined> = {
    id: "unitVin",
    header: "VIN",
    accessorFn: (row) => row.get("UnitVin"),
    cell: (row) => {
        const crash = row.row.original;
        const year = crash.unit?.year;
        const make = crash.unit?.make;
        const license = crash.unit?.license || crash.get("UnitLicense");
        return (
            <Tooltip title={`${year}, ${make}, ${license}`}>
                <span className="border-b border-dashed border-gray-400">{row.getValue()}</span>
            </Tooltip>
        );
    },
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitYear: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitYear",
    header: "Year",
    accessorFn: (row) => row.unit?.year,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitMake: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitMake",
    header: "Make",
    accessorFn: (row) => row.unit?.make,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitHazmatPlacardIndicator: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitHazmatPlacard",
    header: "Hazmat Placard",
    accessorFn: (row) => accessorFnGenerator(row.get("UnitHazmatPlacard")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("UnitHazmatPlacard"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalFatalities: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "totalFatalities",
    header: "Fatalaties",
    accessorFn: (row) => row.get("TotalFatalities"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalInjuries: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "totalInjuries",
    header: "Injuries",
    accessorFn: (row) => row.get("TotalInjuries"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const towIndicator: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "towIndicator",
    header: "Tow",
    accessorFn: (row) => accessorFnGenerator(row.get("TowAway")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("TowAway"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const hazmatReleaseIndicator: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "hazmatIndicator",
    header: "Hazmat Released",
    accessorFn: (row) => accessorFnGenerator(row.get("HazmatReleased")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("HazmatReleased"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const federalRecordableIndicator: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "federalRecordableIndicator",
    header: "Federal Recordable",
    accessorFn: (row) => accessorFnGenerator(row.get("FederalRecordable")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("FederalRecordable"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const stateRecordableIndicator: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "stateRecordableIndicator",
    header: "State Recordable",
    accessorFn: (row) => accessorFnGenerator(row.get("StateRecordable")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("StateRecordable"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const lightCondition: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "lightCondition",
    header: "Lighting",
    accessorFn: (row) => row.get("LightCondition"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const preventable: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "preventable",
    accessorFn: (row) => accessorFnGenerator(row.get("Preventable")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("Preventable"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const roadSurfaceCondition: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "roadSurfaceCondition",
    header: "Road",
    accessorFn: (row) => row.get("RoadSurfaceCondition"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const roadAccessControl: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "roadAccessControl",
    header: "Road Access Control",
    accessorFn: (row) => row.get("RoadAccessControl"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const weatherCondition: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "weatherCondition",
    header: "Weather",
    accessorFn: (row) => row.get("WeatherCondition"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitWeightRating: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "unitWeightRating",
    header: "Weight Rating",
    accessorFn: (row) => row.get("GrossUnitWeightRating"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalVehiclesInAccident: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "totalVehiclesInAccident",
    header: "Vehicles",
    accessorFn: (row) => row.get("TotalVehiclesInAccident"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
const reportTime: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "reportTime",
    header: "Report Time",
    accessorFn: (row) => row.get("ReportTime"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "datetime",
};
const cargoBodyType: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "cargoBodyType",
    header: "Cargo Body Type",
    accessorFn: (row) => row.get("CargoBodyType"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
const trafficway: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "trafficway",
    accessorFn: (row) => row.get("Trafficway"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
const reportingAgency: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "reportingAgency",
    header: "Reporting Agency",
    accessorFn: (row) => row.get("ReportingAgency"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const severityWeight: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "severityWeight",
    header: "Severity Weight",
    accessorFn: (row) => row.severityWeight,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const timeWeight: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "timeWeight",
    header: "Time Weight",
    accessorFn: (row) => row.getTimeWeight(),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalWeight: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "totalWeight",
    header: "Total Weight",
    accessorFn: (row) => row.getTimeWeight() * row.severityWeight,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

export const columns = [
    icons,
    date,
    reportNumber,
    reportTime,
    state,
    city,
    county,
    location,
    latitude,
    longitude,
    unitVin,
    unitYear,
    unitMake,
    unitLicenseNumber,
    unitLicenseState,
    unitType,
    unitWeightRating,
    unitHazmatPlacardIndicator,
    cargoBodyType,
    totalFatalities,
    totalInjuries,
    towIndicator,
    totalVehiclesInAccident,
    hazmatReleaseIndicator,
    federalRecordableIndicator,
    stateRecordableIndicator,
    preventable,
    roadAccessControl,
    trafficway,
    lightCondition,
    roadSurfaceCondition,
    weatherCondition,
    reportingAgency,
    severityWeight,
    timeWeight,
    totalWeight,
] as ColumnDef<MotorCarrier.ICrash>[];

export default {
    icons,
    date,
    reportNumber,
    reportTime,
    state,
    city,
    county,
    location,
    latitude,
    longitude,
    unitVin,
    unitYear,
    unitMake,
    unitLicenseNumber,
    unitLicenseState,
    unitType,
    unitWeightRating,
    unitHazmatPlacardIndicator,
    cargoBodyType,
    totalFatalities,
    totalInjuries,
    towIndicator,
    totalVehiclesInAccident,
    hazmatReleaseIndicator,
    federalRecordableIndicator,
    stateRecordableIndicator,
    preventable,
    roadAccessControl,
    trafficway,
    lightCondition,
    roadSurfaceCondition,
    weatherCondition,
    reportingAgency,
    severityWeight,
    timeWeight,
    totalWeight,
};
