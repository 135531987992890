import { FMCSA } from "@deathstar/types";
import { DateTime } from "@deathstar/types/util";

import { Data } from "../Data";
import { MotorCarrier } from "../MotorCarrier";

// ========================================================================
export class EnforcementCase extends Data<EnforcementCase.Raw> {
    // ========================================================================
    static new(carrier: MotorCarrier, rawEnforcementCase: EnforcementCase.Raw): EnforcementCase {
        const newEnforcementCase = new EnforcementCase(carrier, rawEnforcementCase);
        return newEnforcementCase;
    }

    // ========================================================================
    #date: DateTime;
    #carrier: MotorCarrier;
    private constructor(carrier: MotorCarrier, raw: EnforcementCase.Raw) {
        super({
            carrier,
            raw,
        });
        this.#carrier = carrier;
        this.#date = new DateTime(this.get("ClosedDate"));
    }

    // ========================================================================
    get [Symbol.toStringTag](): string {
        return "EnforcementCase";
    }

    // ========================================================================
    get carrier(): MotorCarrier {
        return this.#carrier;
    }

    // ========================================================================
    /**
     * The Case Number of the `EnforcementCase`
     */
    get number(): string {
        return this.get("CaseNumber");
    }

    // ========================================================================
    /**
     * The Date of the `EnforcementCase`
     */
    get date(): DateTime {
        return this.#date;
    }

    // ========================================================================
    /**
     * The Violations of the `EnforcementCase`
     */
    get violations(): FMCSA.EnforcementCaseViolation[] {
        return this.get("Violations") || [];
    }

    // ========================================================================
    /**
     * Tests if the `EnforcementCase` occurred before a particular date
     */
    occurredBeforeDate(date: Date): boolean {
        return DateTime.isBefore(this.#date, date);
    }

    // ========================================================================
    /**
     * Tests if the `EnforcementCase` occurred after a particular date
     */
    occurredAfterDate(date: Date): boolean {
        return DateTime.isAfter(this.#date, date);
    }

    // ========================================================================
    /**
     * Tests if the `EnforcementCase` occurred on a particular date
     */
    occurredOnDate(date: Date): boolean {
        return DateTime.isEqual(this.#date, date);
    }

    // ========================================================================
    /**
     * Tests if the `EnforcementCase` has a violation of a particular BASIC
     */
    hasViolationOfBasic(basic: FMCSA.BasicName): boolean {
        return this.violations.some((violation) => violation.Basic === basic);
    }

    // ========================================================================
    raw(): EnforcementCase.Raw {
        return super.json();
    }
}

export namespace EnforcementCase {
    export interface Raw extends FMCSA.EnforcementCase {}
}
