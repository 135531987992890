import { FMCSA } from "@deathstar/types";
import { DateTime } from "@deathstar/types/util";
import type { MotorCarrier } from "../../../MotorCarrier";
import type { Violation } from "../../../Violation/Violation";
import { Violations } from "../../Violations";
import type { Breakdown } from "../Breakdown";
import { BASIC } from "./BASIC";

export class VehicleMaintenance extends BASIC<VehicleMaintenance.GroupNames, VehicleMaintenance.SubGroupNames> {
    constructor(breakdown: Breakdown, carrier: MotorCarrier, date: DateTime, vmViolations: Violations) {
        if (!Array.from(vmViolations).every((viol) => viol.basic === FMCSA.BasicName.VEHICLE_MAINTENANCE)) {
            throw new Error("Not all violations are Vehicle Maintenance violations");
        }

        const violations: Record<string, Violation[]> = {
            tires: [],
            wheelsEtc: [],
            tireLoad: [],
            brakeOos: [],
            brakesOoa: [],
            brakesOther: [],
            steering: [],
            suspension: [],
            windshield: [],
            cabFrame: [],
            vehicleOther: [],
            fuelSys: [],
            exhaust: [],
            lighting: [],
            warningFlags: [],
            clearanceLamps: [],
            reflectiveSheeting: [],
            improperLoadSecurement: [],
            generalSecurement: [],
            tiedown: [],
            couplingDevices: [],
            failureToPreventMovement: [],
            walkaround: [],
            emergencyEq: [],
            jumpingOos: [],
            other: [],
        };

        Array.from(vmViolations).forEach((violation) => {
            const groupDescription = violation.get("GroupDescription");

            switch (groupDescription) {
                case "Tires":
                    violations.tires.push(violation);
                    break;
                case "Wheels Studs Clamps Etc.":
                    violations.wheelsEtc.push(violation);
                    break;
                case "Tire vs. Load":
                    violations.tireLoad.push(violation);
                    break;
                case "Brake Out Of Service":
                    violations.brakeOos.push(violation);
                    break;
                case "Brakes Out of Adjustment":
                    violations.brakesOoa.push(violation);
                    break;
                case "Brakes All Others":
                    violations.brakesOther.push(violation);
                    break;
                case "Steering Mechanism":
                    violations.steering.push(violation);
                    break;
                case "Suspension":
                    violations.suspension.push(violation);
                    break;
                case "Windshield/ Glass/ Markings":
                    violations.windshield.push(violation);
                    break;
                case "Cab Body Frame":
                    violations.cabFrame.push(violation);
                    break;
                case "Other Vehicle Defect":
                    violations.vehicleOther.push(violation);
                    break;
                case "Fuel Systems":
                    violations.fuelSys.push(violation);
                    break;
                case "Exhaust Discharge":
                    violations.exhaust.push(violation);
                    break;
                case "Lighting":
                    violations.lighting.push(violation);
                    break;
                case "Warning Flags":
                    violations.warningFlags.push(violation);
                    break;
                case "Clearance Identification Lamps/Other":
                    violations.clearanceLamps.push(violation);
                    break;
                case "Reflective Sheeting":
                    violations.reflectiveSheeting.push(violation);
                    break;
                case "Improper Load Securement":
                    violations.improperLoadSecurement.push(violation);
                    break;
                case "Securement Device":
                case "General Securement":
                    violations.generalSecurement.push(violation);
                    break;
                case "Tiedown":
                    violations.tiedown.push(violation);
                    break;
                case "Coupling Devices":
                    violations.couplingDevices.push(violation);
                    break;
                case "Failure to Prevent Movement":
                    violations.failureToPreventMovement.push(violation);
                    break;
                case "Inspection Reports":
                    violations.walkaround.push(violation);
                    break;
                case "Emergency Equipment":
                    violations.emergencyEq.push(violation);
                    break;
                case "Vehicle Jumping OOS":
                    violations.jumpingOos.push(violation);
                    break;
                default:
                    violations.other.push(violation);
                    break;
            }
        });

        super(breakdown, carrier, date, vmViolations, [
            {
                name: "Wheels",
                subGroups: [
                    { name: "Tires", violations: Violations.of(carrier, violations.tires) },
                    { name: "Wheels, Studs, Clamps, Etc.", violations: Violations.of(carrier, violations.wheelsEtc) },
                    { name: "Tire vs. Load", violations: Violations.of(carrier, violations.tireLoad) },
                ],
            },
            {
                name: "Brakes/Steering/Suspension",
                subGroups: [
                    { name: "Brake OOS", violations: Violations.of(carrier, violations.brakeOos) },
                    { name: "Brakes Out of Adjustment", violations: Violations.of(carrier, violations.brakesOoa) },
                    { name: "Brakes Other", violations: Violations.of(carrier, violations.brakesOther) },
                    { name: "Steering Mechanism", violations: Violations.of(carrier, violations.steering) },
                    { name: "Suspension", violations: Violations.of(carrier, violations.suspension) },
                ],
            },
            {
                name: "Engine/Truck",
                subGroups: [
                    { name: "Fuel Systems", violations: Violations.of(carrier, violations.fuelSys) },
                    { name: "Exhaust Discharge", violations: Violations.of(carrier, violations.exhaust) },
                    { name: "Cab Body Frame", violations: Violations.of(carrier, violations.cabFrame) },
                    { name: "Other Vehicle Defect", violations: Violations.of(carrier, violations.vehicleOther) },
                ],
            },
            {
                name: "Visibility",
                subGroups: [
                    { name: "Lighting", violations: Violations.of(carrier, violations.lighting) },
                    { name: "Warning Flags", violations: Violations.of(carrier, violations.warningFlags) },
                    { name: "Clearance Identification Lamps/Other", violations: Violations.of(carrier, violations.clearanceLamps) },
                    { name: "Reflective Sheeting", violations: Violations.of(carrier, violations.reflectiveSheeting) },
                    { name: "Windshield, Glass, Markings", violations: Violations.of(carrier, violations.windshield) },
                ],
            },
            {
                name: "Cargo Securement",
                subGroups: [
                    { name: "Improper Load Securement", violations: Violations.of(carrier, violations.improperLoadSecurement) },
                    { name: "Securement Device/General", violations: Violations.of(carrier, violations.generalSecurement) },
                    { name: "Tiedown", violations: Violations.of(carrier, violations.tiedown) },
                    { name: "Coupling Devices", violations: Violations.of(carrier, violations.couplingDevices) },
                    { name: "Failure to Prevent Movement", violations: Violations.of(carrier, violations.failureToPreventMovement) },
                ],
            },
            {
                name: "Preventative Measures",
                subGroups: [
                    { name: "Walkaround Failure", violations: Violations.of(carrier, violations.walkaround) },
                    { name: "Emergency Equipment", violations: Violations.of(carrier, violations.emergencyEq) },
                ],
            },
            {
                name: "Other",
                subGroups: [
                    { name: "Vehicle Jumping OOS", violations: Violations.of(carrier, violations.jumpingOos) },
                    { name: "Other", violations: Violations.of(carrier, violations.other) },
                ],
            },
        ]);
    }

    // ========================================================================
    get(groupName: "Wheels"): Breakdown.Group<VehicleMaintenance.GroupNames, VehicleMaintenance.SubGroupNames.Wheels>;
    get(
        groupName: "Brakes/Steering/Suspension"
    ): Breakdown.Group<VehicleMaintenance.GroupNames, VehicleMaintenance.SubGroupNames.BrakesSteeringSuspension>;
    get(groupName: "Engine/Truck"): Breakdown.Group<VehicleMaintenance.GroupNames, VehicleMaintenance.SubGroupNames.EngineTruck>;
    get(groupName: "Visibility"): Breakdown.Group<VehicleMaintenance.GroupNames, VehicleMaintenance.SubGroupNames.Visibility>;
    get(groupName: "Cargo Securement"): Breakdown.Group<VehicleMaintenance.GroupNames, VehicleMaintenance.SubGroupNames.CargoSecurement>;
    get(
        groupName: "Preventative Measures"
    ): Breakdown.Group<VehicleMaintenance.GroupNames, VehicleMaintenance.SubGroupNames.PreventativeMeasures>;
    get(groupName: "Other"): Breakdown.Group<VehicleMaintenance.GroupNames, VehicleMaintenance.SubGroupNames.Other>;
    get(groupName: VehicleMaintenance.GroupNames): Breakdown.Group<VehicleMaintenance.GroupNames, VehicleMaintenance.SubGroupNames> {
        return super.get(groupName);
    }

    // ========================================================================
    getHistory(): VehicleMaintenance[] {
        return this.breakdown.getHistory().vehicleMaintenance;
    }
}

export namespace VehicleMaintenance {
    export type GroupNames =
        | "Wheels"
        | "Brakes/Steering/Suspension"
        | "Engine/Truck"
        | "Visibility"
        | "Cargo Securement"
        | "Preventative Measures"
        | "Other";

    export namespace SubGroupNames {
        export type Wheels = "Tires" | "Wheels, Studs, Clamps, Etc." | "Tire vs. Load";

        export type BrakesSteeringSuspension =
            | "Brake OOS"
            | "Brakes Out of Adjustment"
            | "Brakes Other"
            | "Steering Mechanism"
            | "Suspension";

        export type EngineTruck = "Fuel Systems" | "Exhaust Discharge" | "Cab Body Frame" | "Other Vehicle Defect";

        export type Visibility =
            | "Lighting"
            | "Warning Flags"
            | "Clearance Identification Lamps/Other"
            | "Reflective Sheeting"
            | "Windshield, Glass, Markings";

        export type CargoSecurement =
            | "Improper Load Securement"
            | "Securement Device/General"
            | "Tiedown"
            | "Coupling Devices"
            | "Failure to Prevent Movement";

        export type PreventativeMeasures = "Walkaround Failure" | "Emergency Equipment";

        export type Other = "Vehicle Jumping OOS" | "Other";
    }

    export type SubGroupNames =
        | SubGroupNames.Wheels
        | SubGroupNames.BrakesSteeringSuspension
        | SubGroupNames.EngineTruck
        | SubGroupNames.Visibility
        | SubGroupNames.CargoSecurement
        | SubGroupNames.PreventativeMeasures
        | SubGroupNames.Other;
}
