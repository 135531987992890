import { alphaCentaurus } from "./queries/alpha-centaurus";
import { billing } from "./queries/billing";
import { certificates } from "./queries/certificates";
import { driverOfTheYear } from "./queries/driverOfTheYear";
import { drivers } from "./queries/drivers";
import { equipment } from "./queries/equipment";
import { files } from "./queries/files";
import { invitations } from "./queries/invitations";
import { inviteCodes } from "./queries/inviteCodes";
import { members } from "./queries/members";
import { monthlyReports } from "./queries/monthlyReports";
import { ooProgram } from "./queries/ooProgram";
import { organization } from "./queries/organization";
import { ownerOperatorEmployers } from "./queries/ownerOperatorEmployers";
import { policies } from "./queries/policies";
import { properties } from "./queries/properties";
import { telematics } from "./queries/telematics";
import { user } from "./queries/user";
import { zendesk } from "./queries/zendesk";

const api = {
    alphaCentaurus,
    billing,
    certificates,
    driverOfTheYear,
    drivers,
    equipment,
    files,
    invitations,
    inviteCodes,
    members,
    monthlyReports,
    ooProgram,
    organization,
    ownerOperatorEmployers,
    policies,
    telematics,
    user,
    zendesk,
    properties,
};

export default api;
