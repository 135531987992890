import { AttributeOption, CoverageOption } from "@deathstar/types/northstar";

// Central premier attribute missing... N/A

export function getBrandedCoverageLink(attributeOptionId: string, coverageOptionId: number) {
    if (
        AttributeOption.Id.ACUITY_ADVANTAGES === attributeOptionId &&
        (CoverageOption.Id.BUSINESS_AUTO_LIABILITY === coverageOptionId || CoverageOption.Id.TRUCKERS_AUTO_LIABILITY === coverageOptionId)
    ) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202402673051-Acuity-Business-Auto-Built-In-Advantages-and-Enhancements";
    }
    if (AttributeOption.Id.ACUITY_ADVANTAGES === attributeOptionId && CoverageOption.Id.GENERAL_LIABILITY === coverageOptionId) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202648866331-Acuity-General-Liability-Built-In-Advantages-and-Enhancements";
    }
    if (AttributeOption.Id.ACUITY_ADVANTAGES === attributeOptionId && CoverageOption.Id.PROPERTY === coverageOptionId) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202666666651-Acuity-Property-Built-In-Advantages-and-Enhancements";
    }
    if (AttributeOption.Id.ACUITY_TRUCKERS_ENHANCEMENTS === attributeOptionId) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202645719963-Acuity-Truckers-Built-In-Advantages-and-Enhancements";
    }
    if (AttributeOption.Id.ACUITY_EDGE === attributeOptionId && CoverageOption.Id.GENERAL_LIABILITY === coverageOptionId) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202716974875-Acuity-Edge-General-Liability";
    }
    if (AttributeOption.Id.EQUIPMENT_BREAKDOWN_TECH_ADVANTAGES === attributeOptionId) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202756442523-Central-Equipment-Breakdown-Tech-Advantages";
    }
    if (AttributeOption.Id.GENERAL_LIABILITY_PLUS === attributeOptionId) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202773100571-Central-General-Liability-Plus";
    }
    if (AttributeOption.Id.MOTOR_CARRIER_PLUS === attributeOptionId) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202761694619-Central-Motor-Carrier-Plus";
    }
    if (AttributeOption.Id.PREMIER_PLUS === attributeOptionId) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202784898715-Central-Premier-Plus";
    }
    if (AttributeOption.Id.CHOICE_PLUS === attributeOptionId) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202858467099-Great-West-Choice-Plus";
    }
    if (AttributeOption.Id.LANCER_ADVANTAGES === attributeOptionId) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202853536923-Lancer-Advantages";
    }
    if (AttributeOption.Id.DELUXE === attributeOptionId) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202878874011-Northland-Deluxe";
    }
    if (AttributeOption.Id.PREMIER === attributeOptionId) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202908266395-Northland-Premier-Cargo";
    }
    if (
        (AttributeOption.Id.ACUITY_ENHANCEMENTS_SILVER === attributeOptionId ||
            AttributeOption.Id.ACUITY_ENHANCEMENTS_GOLD === attributeOptionId) &&
        CoverageOption.Id.GENERAL_LIABILITY === coverageOptionId
    ) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23689645458843-Acuity-General-Liability-Enhancements-Silver-and-Gold";
    }
    if (
        (AttributeOption.Id.ACUITY_ENHANCEMENTS_GOLD === attributeOptionId ||
            AttributeOption.Id.ACUITY_ENHANCEMENTS_PLATINUM === attributeOptionId) &&
        CoverageOption.Id.PROPERTY === coverageOptionId
    ) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23689837022107-Acuity-Property-Enhancements-Silver-Gold-and-Platinum";
    }
    if (
        AttributeOption.Id.ACUITY_EDGE === attributeOptionId &&
        (CoverageOption.Id.BUSINESS_AUTO_LIABILITY === coverageOptionId || CoverageOption.Id.BUSINESS_AUTO_LIABILITY === coverageOptionId)
    ) {
        return "https://help.navigatortruckinsurance.com/hc/en-us/articles/23202688269851-Acuity-Edge-Truckers";
    }
    return "";
}
