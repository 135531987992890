import { FMCSA } from "@deathstar/types";
import { DateTime } from "@deathstar/types/util";
import type { MotorCarrier } from "../../../MotorCarrier";
import type { Violation } from "../../../Violation/Violation";
import { Violations } from "../../Violations";
import type { Breakdown } from "../Breakdown";
import { BASIC } from "./BASIC";

export class ControlledSubstances extends BASIC<ControlledSubstances.GroupNames, ControlledSubstances.SubGroupNames> {
    constructor(breakdown: Breakdown, carrier: MotorCarrier, date: DateTime, vmViolations: Violations) {
        if (!Array.from(vmViolations).every((viol) => viol.basic === FMCSA.BasicName.CONTROLLED_SUBSTANCES)) {
            throw new Error("Not all violations are Controlled Substances violations");
        }

        const violations: Record<string, Violation[]> = {
            alcohol: [],
            alcoholPossession: [],
            drugs: [],
            jumpingOos: [],
            other: [],
        };

        Array.from(vmViolations).forEach((violation) => {
            const groupDescription = violation.get("GroupDescription");

            switch (groupDescription) {
                case "Alcohol":
                    violations.alcohol.push(violation);
                    break;
                case "Alcohol Possession":
                    violations.alcoholPossession.push(violation);
                    break;
                case "Alcohol Jumping OOS":
                    violations.jumpingOos.push(violation);
                    break;
                case "Drugs":
                    violations.drugs.push(violation);
                    break;
                default:
                    violations.other.push(violation);
                    break;
            }
        });

        super(breakdown, carrier, date, vmViolations, [
            {
                name: "Alcohol",
                subGroups: [
                    { name: "Alcohol", violations: Violations.of(carrier, violations.alcohol) },
                    { name: "Posession", violations: Violations.of(carrier, violations.alcoholPossession) },
                ],
            },
            {
                name: "Drugs",
                subGroups: [{ name: "Drugs", violations: Violations.of(carrier, violations.drugs) }],
            },
            {
                name: "Jumping OOS",
                subGroups: [{ name: "Jumping OOS", violations: Violations.of(carrier, violations.jumpingOos) }],
            },
            {
                name: "Other",
                subGroups: [{ name: "Other", violations: Violations.of(carrier, violations.other) }],
            },
        ]);
    }

    // ========================================================================
    get(groupName: "Alcohol"): Breakdown.Group<ControlledSubstances.GroupNames, ControlledSubstances.SubGroupNames.Alcohol>;
    get(groupName: "Drugs"): Breakdown.Group<ControlledSubstances.GroupNames, ControlledSubstances.SubGroupNames.Drugs>;
    get(groupName: "Jumping OOS"): Breakdown.Group<ControlledSubstances.GroupNames, ControlledSubstances.SubGroupNames.JumpingOos>;
    get(groupName: "Other"): Breakdown.Group<ControlledSubstances.GroupNames, ControlledSubstances.SubGroupNames.Other>;
    get(groupName: ControlledSubstances.GroupNames): Breakdown.Group<ControlledSubstances.GroupNames, ControlledSubstances.SubGroupNames> {
        return super.get(groupName);
    }

    // ========================================================================
    getHistory(): ControlledSubstances[] {
        return this.breakdown.getHistory().controlledSubstances;
    }
}

export namespace ControlledSubstances {
    export type GroupNames = "Alcohol" | "Drugs" | "Jumping OOS" | "Other";

    export namespace SubGroupNames {
        export type Alcohol = "Alcohol" | "Posession";

        export type Drugs = "Drugs";

        export type JumpingOos = "Jumping OOS";

        export type Other = "Other";
    }

    export type SubGroupNames = SubGroupNames.Alcohol | SubGroupNames.Drugs | SubGroupNames.JumpingOos | SubGroupNames.Other;
}
