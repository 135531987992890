import { TransactionType } from "../../ams/TransactionType";
import { PolicyEnums } from "../../northstar/Policy/Enums";

const TRANSACTION_TYPE_MAP_DTO_TO_ENTITY = new Map<PolicyEnums.TransactionType, TransactionType>([
    [PolicyEnums.TransactionType.ANNIVERSARY_RE_RATE, TransactionType.ANNIVERSARY_RE_RATE],
    [PolicyEnums.TransactionType.BINDER_NEW_BUSINESS, TransactionType.BINDER_NEW_BUSINESS],
    [PolicyEnums.TransactionType.BINDER_BILLABLE, TransactionType.BINDER_BILLABLE],
    [PolicyEnums.TransactionType.BINDER_ENDORSEMENT, TransactionType.BINDER_ENDORSEMENT],
    [PolicyEnums.TransactionType.BINDER_RENEWAL, TransactionType.BINDER_RENEWAL],
    [PolicyEnums.TransactionType.QUOTE, TransactionType.NEW_BUSINESS_QUOTE],
    [PolicyEnums.TransactionType.NEW, TransactionType.NEW_BUSINESS],
    [PolicyEnums.TransactionType.NON_RENEWAL_NOTIFIED_AGENCY, TransactionType.NON_RENEWAL_NOTIFIED_AGENCY],
    [PolicyEnums.TransactionType.PREMIUM_AUDIT, TransactionType.PREMIUM_AUDIT],
    [PolicyEnums.TransactionType.CHANGE, TransactionType.POLICY_CHANGE],
    [PolicyEnums.TransactionType.MONTHLY_REPORT_FINAL, TransactionType.POLICY_CHANGE],
    [PolicyEnums.TransactionType.MONTHLY_REPORT, TransactionType.POLICY_CHANGE],
    [PolicyEnums.TransactionType.CHANGE_QUOTE, TransactionType.POLICY_CHANGE_QUOTE],
    [PolicyEnums.TransactionType.INQUIRY, TransactionType.POLICY_INQUIRY],
    [PolicyEnums.TransactionType.REINSTATEMENT, TransactionType.REINSTATEMENT],
    [PolicyEnums.TransactionType.REWRITE, TransactionType.REWRITE],
    [PolicyEnums.TransactionType.REISSUE, TransactionType.REISSUE],
    [PolicyEnums.TransactionType.REVERSAL_OF_NON_RENEWAL, TransactionType.REVERSAL_OF_NON_RENEWAL],
    [PolicyEnums.TransactionType.RENEWAL_RE_QUOTE, TransactionType.RENEWAL_RE_QUOTE],
    [PolicyEnums.TransactionType.RENEWAL, TransactionType.RENEWAL],
    [PolicyEnums.TransactionType.RENEWAL_QUOTE, TransactionType.RENEWAL_QUOTE],
    [PolicyEnums.TransactionType.RENEWAL_REQUEST, TransactionType.RENEWAL_REQUEST],
    [PolicyEnums.TransactionType.NON_RENEWAL_NOTIFIED_POLICY_HOLDER, TransactionType.NON_RENEWAL_NOTIFIED_POLICY_HOLDER],
    [PolicyEnums.TransactionType.POLICY_SYNCHRONIZATION, TransactionType.POLICY_SYNCHRONIZATION],
    [PolicyEnums.TransactionType.POLICY_SYNCHRONIZATION_REQUEST, TransactionType.POLICY_SYNCHRONIZATION_REQUEST],
    [PolicyEnums.TransactionType.CANCELLATION_CONFIRMATION, TransactionType.CANCELLATION_CONFIRMATION],
    [PolicyEnums.TransactionType.CANCELLATION_REQUEST, TransactionType.CANCELLATION_REQUEST],
]);

const TRANSACTION_TYPE_MAP_ENTITY_TO_DTO = new Map<TransactionType, PolicyEnums.TransactionType>([
    [TransactionType.ANNIVERSARY_RE_RATE, PolicyEnums.TransactionType.ANNIVERSARY_RE_RATE],
    [TransactionType.BINDER_NEW_BUSINESS, PolicyEnums.TransactionType.BINDER_NEW_BUSINESS],
    [TransactionType.BINDER_BILLABLE, PolicyEnums.TransactionType.BINDER_BILLABLE],
    [TransactionType.BINDER_ENDORSEMENT, PolicyEnums.TransactionType.BINDER_ENDORSEMENT],
    [TransactionType.BINDER_RENEWAL, PolicyEnums.TransactionType.BINDER_RENEWAL],
    [TransactionType.NEW_BUSINESS_QUOTE, PolicyEnums.TransactionType.QUOTE],
    [TransactionType.NEW_BUSINESS, PolicyEnums.TransactionType.NEW],
    [TransactionType.NON_RENEWAL_NOTIFIED_AGENCY, PolicyEnums.TransactionType.NON_RENEWAL_NOTIFIED_AGENCY],
    [TransactionType.PREMIUM_AUDIT, PolicyEnums.TransactionType.PREMIUM_AUDIT],
    [TransactionType.POLICY_CHANGE, PolicyEnums.TransactionType.CHANGE],
    [TransactionType.POLICY_CHANGE_QUOTE, PolicyEnums.TransactionType.CHANGE_QUOTE],
    [TransactionType.POLICY_INQUIRY, PolicyEnums.TransactionType.INQUIRY],
    [TransactionType.REINSTATEMENT, PolicyEnums.TransactionType.REINSTATEMENT],
    [TransactionType.REWRITE, PolicyEnums.TransactionType.REWRITE],
    [TransactionType.REISSUE, PolicyEnums.TransactionType.REISSUE],
    [TransactionType.REVERSAL_OF_NON_RENEWAL, PolicyEnums.TransactionType.REVERSAL_OF_NON_RENEWAL],
    [TransactionType.RENEWAL_RE_QUOTE, PolicyEnums.TransactionType.RENEWAL_RE_QUOTE],
    [TransactionType.RENEWAL, PolicyEnums.TransactionType.RENEWAL],
    [TransactionType.RENEWAL_QUOTE, PolicyEnums.TransactionType.RENEWAL_QUOTE],
    [TransactionType.RENEWAL_REQUEST, PolicyEnums.TransactionType.RENEWAL_REQUEST],
    [TransactionType.NON_RENEWAL_NOTIFIED_POLICY_HOLDER, PolicyEnums.TransactionType.NON_RENEWAL_NOTIFIED_POLICY_HOLDER],
    [TransactionType.POLICY_SYNCHRONIZATION, PolicyEnums.TransactionType.POLICY_SYNCHRONIZATION],
    [TransactionType.POLICY_SYNCHRONIZATION_REQUEST, PolicyEnums.TransactionType.POLICY_SYNCHRONIZATION_REQUEST],
    [TransactionType.CANCELLATION_CONFIRMATION, PolicyEnums.TransactionType.CANCELLATION_CONFIRMATION],
    [TransactionType.CANCELLATION_REQUEST, PolicyEnums.TransactionType.CANCELLATION_REQUEST],
]);

export class TransactionTypeConverter {
    static fromNsToAms(transactionType: PolicyEnums.TransactionType): TransactionType {
        const type = TRANSACTION_TYPE_MAP_DTO_TO_ENTITY.get(transactionType);
        return type ? type : TransactionType.POLICY_UNSPECIFIED;
    }

    static fromAmsToNs(transactionType: TransactionType): PolicyEnums.TransactionType {
        const type = TRANSACTION_TYPE_MAP_ENTITY_TO_DTO.get(transactionType);
        return type ? type : PolicyEnums.TransactionType.OTHER;
    }
}
