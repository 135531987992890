import { MotorCarrier } from "@deathstar/motor-carrier";
import { FMCSA } from "@deathstar/types";
import { DateTime } from "@deathstar/types/util";
import { DataTable, Tooltip } from "@deathstar/ui";
import { AccessorColumnDef, ColumnDef } from "@tanstack/react-table";
import DataQIcon from "../../assets/icons/dataq-icon.png";

const ascDateSorter = DateTime.Sorter("ASC");

const icons: AccessorColumnDef<MotorCarrier.IViolation, boolean> = {
    id: "icons",
    header: "",
    accessorFn: (row) => row.isValid,
    cell: (row) => {
        const viol = row.row.original;

        const icons: JSX.Element[] = [];

        if (!viol.isValid) icons.push(<img src={DataQIcon} alt="DataQ Icon" className="m-[1px] h-[20px] w-[20px]" />);

        // if (viol.isNew) icons.push(<img src={NewIcon} alt="New Icon" className="m-[1px] h-[20px] w-[20px]" />);
        // if (viol.hasExpiringWeight) icons.push(<img src={ExpiringIcon} alt="Expiring Icon" className="m-[1px] h-[20px] w-[20px]" />);
        if (viol.carrier.inspections.filterByInvalid().total > 0) icons.push(<div className="m-[1px] h-[20px] w-[20px]"></div>);

        if (viol.isOutOfService) {
            icons.push(
                <div className="flex justify-center rounded-xl bg-danger px-2 text-white">
                    <span>OOS</span>
                </div>
            );
        }

        return (
            <div className="flex gap-1">
                {icons.map((icon, idx) => (
                    <div key={idx}>{icon}</div>
                ))}
            </div>
        );
    },
    meta: { classes: { cell: "tabular-nums p-0 min-w-[45px]" } },
    enableSorting: false,
};

const basic: AccessorColumnDef<MotorCarrier.IViolation, FMCSA.BasicName> = {
    id: "basic",
    header: "BASIC Category",
    accessorFn: (row) => row.get("Basic"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: DataTable.ColumnOptionFilterButton.filterFn,
    enableGlobalFilter: false,
};

const addDate: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "addDate",
    header: "Date",
    accessorFn: (row) => row.date.format("mm/dd/YYYY"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: (a, b) => {
        return ascDateSorter(a.original.date, b.original.date);
    },
};
const removalDate: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "removalDate",
    header: "Removal",
    accessorFn: (row) => DateTime.addYears(row.date, 2).format("mm/dd/YYYY"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: (a, b) => {
        return ascDateSorter(a.original.date, b.original.date);
    },
};
const state: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "state",
    header: "State",
    accessorFn: (row) => row.inspection.get("ReportState"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: (a, b) => {
        return ascDateSorter(a.original.date, b.original.date);
    },
};

const unitVin: AccessorColumnDef<MotorCarrier.IViolation, string | undefined> = {
    id: "unitVin",
    header: "VIN",
    accessorFn: (row) => row.unit?.vin,
    cell: (row) => {
        const viol = row.row.original;
        const year = viol.unit?.year;
        const make = viol.unit?.make;
        const license = viol.unit?.license;
        return <Tooltip title={`${year}, ${make}, ${license}`}>{row.getValue()}</Tooltip>;
    },
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitYear: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "unitYear",
    header: "Year",
    accessorFn: (row) => row.unit?.year,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitMake: AccessorColumnDef<MotorCarrier.IViolation, string | undefined> = {
    id: "unitMake",
    header: "Make",
    accessorFn: (row) => row.unit?.make,
    meta: { classes: { cell: "max-w-[17ch] overflow-hidden text-ellipsis whitespace-nowrap tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitLicenseNumber: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "unitLicenseNumber",
    header: "License Number",
    accessorFn: (row) => row.unit?.license,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitNumber: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "unitNumber",
    header: "Unit Number",
    accessorFn: (row) => row.unit?.number,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitType: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "unitType",
    header: "Type",
    accessorFn: (row) => row.unit?.type,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const offender: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "offender",
    accessorFn: (row) => row.get("ViolationOffender"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const groupDescription: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "groupDescription",
    header: "Group",
    accessorFn: (row) => row.get("GroupDescription"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: DataTable.ColumnOptionFilterButton.filterFn,
};
const sectionDescription: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "sectionDescription",
    header: "Section",
    accessorFn: (row) => row.get("SectionDescription"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const severityWeight: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "severityWeight",
    header: "Severity Weight",
    accessorFn: (row) => row.severityWeight,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const timeWeight: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "timeWeight",
    header: "Time Weight",
    accessorFn: (row) => row.getTimeWeight(),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalWeight: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "totalWeight",
    header: "Total Weight",
    accessorFn: (row) => row.getTotalWeight(),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const weightCalculation: AccessorColumnDef<MotorCarrier.IViolation, { severityWeight: number; timeWeight: number; totalWeight: number }> = {
    id: "weightCalculation",
    header: "Weight Calculation",
    accessorFn: (row) => {
        return {
            severityWeight: row.severityWeight,
            timeWeight: row.getTimeWeight(),
            totalWeight: row.getTotalWeight(),
        };
    },
    cell: (row) => {
        const { severityWeight, timeWeight, totalWeight } = row.getValue();
        return (
            <div className="flex gap-2">
                <span>{severityWeight}</span>
                <span>x</span>
                <span>{timeWeight}</span>
                <span>=</span>
                <span className="w-[2ch] text-right">{totalWeight}</span>
            </div>
        );
    },
    meta: { classes: { cell: "tabular-nums" } },
    enableSorting: false,
};

const violationCode: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "violationCode",
    header: "Violation Code",
    accessorFn: (row) => row.get("ViolationCode"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const county: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "county",
    accessorFn: (row) => row.inspection.county,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const latitude: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "latitude",
    accessorFn: (row) => row.inspection.get("CountyLatitude"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const longitude: AccessorColumnDef<MotorCarrier.IViolation> = {
    id: "longitude",
    accessorFn: (row) => row.inspection.get("CountyLongitude"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

export const columns = [
    icons,
    addDate,
    removalDate,
    state,
    unitVin,
    unitYear,
    unitMake,
    unitLicenseNumber,
    unitNumber,
    unitType,
    offender,
    basic,
    violationCode,
    groupDescription,
    sectionDescription,
    severityWeight,
    timeWeight,
    totalWeight,
    weightCalculation,
    county,
    latitude,
    longitude,
] as ColumnDef<MotorCarrier.IViolation>[];

export default {
    icons,
    addDate,
    removalDate,
    state,
    unitVin,
    unitYear,
    unitMake,
    unitLicenseNumber,
    unitNumber,
    unitType,
    offender,
    basic,
    violationCode,
    groupDescription,
    sectionDescription,
    severityWeight,
    timeWeight,
    totalWeight,
    weightCalculation,
    county,
    latitude,
    longitude,
};
