export class CommissionMetadata {
    id: string;
    transactionType?: CommissionMetadata.TransactionType;
    effectiveDate: Date;
    rate: number | null;
    lineOfBusiness: string | null;
}

export namespace CommissionMetadata {
    export enum TransactionType {
        RENEWAL = "Renewal",
        REWRITE = "Rewrite",
        NEW_BUSINESS = "New Business",
    }
}
