import { RawData } from "@deathstar/reuse";
import { DateTime } from "@deathstar/types/util";

import type { Census } from "../Census/Census";
import type { MotorCarrier } from "../MotorCarrier";

export class Mcs150HistoryEntry extends RawData<Mcs150HistoryEntry.Raw> {
    // ========================================================================
    static sorter(order: "ASC" | "DESC"): (e1: Mcs150HistoryEntry, e2: Mcs150HistoryEntry) => number {
        return (e1: Mcs150HistoryEntry, e2: Mcs150HistoryEntry) => {
            if (e1.date.isBefore(e2.date)) {
                return order === "ASC" ? -1 : 1;
            }
            if (e1.date.isAfter(e2.date)) {
                return order === "ASC" ? 1 : -1;
            }
            return 0;
        };
    }

    #carrier: MotorCarrier;
    #date: DateTime;

    constructor(carrier: MotorCarrier, raw: Mcs150HistoryEntry.Raw) {
        super(raw);

        this.#carrier = carrier;
        this.#date = new DateTime(this.get("Mcs150Date")).freeze();
    }

    // ========================================================================
    get date(): DateTime {
        return this.#date;
    }

    // ========================================================================
    json(): Mcs150HistoryEntry.Raw {
        return super.json();
    }

    // ========================================================================
    raw(): Mcs150HistoryEntry.Raw {
        return super.json();
    }
}

export namespace Mcs150HistoryEntry {
    export interface Raw extends Pick<Census.Raw, "Mcs150Date" | "Mcs150MileageTotal" | "TotalPowerUnits"> {}
}
