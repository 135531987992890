import { FMCSA } from "@deathstar/types";
import { DateTime } from "@deathstar/types/util";

import { Calculation } from "../Calculation";
import { Data } from "../Data";
import { MotorCarrier } from "../MotorCarrier";
import { TimeWeight } from "../TimeWeight/TimeWeight";
import { Unit } from "../Unit/Unit";
import { Violation } from "../Violation/Violation";
import { Violations } from "../Violations/Violations";

// ========================================================================
export class Inspection extends Data<Inspection.Raw> implements Calculation.HasTimeWeight {
    // ========================================================================
    static new(carrier: MotorCarrier, rawInspection: Inspection.Raw, rawViolations?: Violation.Raw[]): Inspection {
        const newInspection = new Inspection(carrier, rawInspection);
        newInspection.#violations = Violations.of(
            carrier,
            rawViolations?.map((rv) => Violation.new(carrier, newInspection, rv))
        );
        return newInspection;
    }

    // ========================================================================
    #date;
    #carrier: MotorCarrier;
    private constructor(carrier: MotorCarrier, raw: Inspection.Raw) {
        super({
            carrier,
            raw,
        });
        this.#carrier = carrier;
        this.#date = DateTime.fromString(this.get("InspectionDate"), "ISO");
    }

    // ========================================================================
    get [Symbol.toStringTag](): string {
        return "Inspection";
    }

    // ========================================================================
    get carrier(): MotorCarrier {
        return this.#carrier;
    }

    // ========================================================================
    /**
     * The `Inspection` ID
     */
    get id(): number {
        return this.get("UniqueId");
    }

    // ========================================================================
    /**
     * If the Inspection is included in the latest BASIC scores (based on the applicable date range)
     */
    get isCurrentlyScored(): boolean {
        if (this.#date.isAfter(DateTime.subtractDays(this.#carrier.dateRange.to, 365 * 2))) return true;
        return false;
    }

    // ========================================================================
    /**
     * Full `Inspection` Report number. ReportState and ReportNumber concatenated
     */
    get reportNumberFormatted(): string {
        return `${this.get("ReportState")}${this.get("ReportNumber")}`;
    }

    // ========================================================================
    /**
     * The Date of the `Inspection`
     */
    get date(): DateTime {
        return this.#date;
    }

    // ========================================================================
    get isHazmatInspection(): boolean {
        return this.get("HazmatInsp");
    }

    // ========================================================================
    get isVehicleMaintenanceInspection(): boolean {
        return this.get("VehicleMaintenanceInsp");
    }

    // ========================================================================
    get isDriverFitnessInspection(): boolean {
        return this.get("DriverFitnessInsp");
    }

    // ========================================================================
    get isUnsafeDrivingInspection(): boolean {
        return this.get("UnsafeDrivingInsp");
    }

    // ========================================================================
    get isHoursOfServiceInspection(): boolean {
        return this.get("HoursOfServiceInsp");
    }

    // ========================================================================
    get isControlledSubstancesInspection(): boolean {
        return this.get("ControlledSubstancesInsp");
    }

    // ========================================================================
    get isValid(): boolean {
        return this.get("IsValid");
    }

    // ========================================================================
    get isOutOfService(): boolean {
        return this.get("TotalOutOfService") > 0;
    }

    // ========================================================================
    get isVehicleOutOfService(): boolean {
        return this.get("TotalVehicleOutOfService") > 0;
    }

    // ========================================================================
    get isDriverOutOfService(): boolean {
        return this.get("TotalDriverOutOfService") > 0;
    }

    // ========================================================================
    get isHazmatOutOfService(): boolean {
        return this.get("TotalHazmatOutOfService") > 0;
    }

    // ========================================================================
    get county(): string {
        return this.get("CountyName")
            ?.toLowerCase()
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    }

    // ========================================================================
    hasBasicViolation(basicName: FMCSA.BasicName): boolean {
        return this.#violations?.has(basicName) || false;
    }

    // ========================================================================
    #violations?: Violations;
    /**
     * The `Violation` objects associated with the `Inspection`
     */
    get violations(): Violations {
        if (this.#violations) return this.#violations;
        this.#violations = Violations.of(this.#carrier);
        return this.#violations;
    }

    // ========================================================================
    #timeWeightMap = new Map<string, number>();
    /**
     * The Time Weight of the Inspection relevant to a date; if no date is passed
     *  in the current time weight is used.
     */
    getTimeWeight(date = this.carrier.dateRange.to): number {
        if (this.#timeWeightMap.has(date.format("YYYYMMDD"))) {
            return this.#timeWeightMap.get(date.format("YYYYMMDD"))!;
        }
        const timeWeight = TimeWeight.of({
            targetDate: date,
            inspectionDate: this.#date,
        });
        this.#timeWeightMap.set(date.format("YYYYMMDD"), timeWeight);
        return timeWeight;
    }

    // ========================================================================
    /**
     * If the inspection has any violations, true if no violations
     */
    get isClean(): boolean {
        return this.violations.total === 0;
    }

    // ========================================================================
    /**
     * If the inspection has any violations, false if no violations
     */
    get isDirty(): boolean {
        return this.violations.total > 0;
    }

    // ========================================================================
    #type?: { driver: boolean; vehicle: boolean; hazmat: boolean };
    #calculateType(): { driver: boolean; vehicle: boolean; hazmat: boolean } {
        this.#type = {
            driver:
                this.isControlledSubstancesInspection ||
                this.isDriverFitnessInspection ||
                this.isHoursOfServiceInspection ||
                this.isUnsafeDrivingInspection,
            vehicle: this.isVehicleMaintenanceInspection || this.isHazmatInspection,
            hazmat: this.isHazmatInspection,
        };
        return this.#type;
    }
    /**
     * Calculates the type of Inspection: Driver and/or Vehicle.
     */
    get type(): { driver: boolean; vehicle: boolean; hazmat: boolean } {
        return this.#type || this.#calculateType();
    }

    // ========================================================================
    get primaryUnit(): Unit | undefined {
        const vin = this.get("UnitVin");
        const license = this.get("UnitLicense");
        if (!vin) return undefined;

        return (
            this.#carrier.units.find({
                vin,
                license,
            }) ||
            Unit.new({
                carrier: this.carrier,
                fmcsa: {
                    license: this.get("UnitLicense"),
                    make: this.get("UnitMake"),
                    number: this.get("UnitDecalNumber"),
                    type: this.get("UnitType"),
                },
                rawUnit: {
                    VIN: vin,
                },
                units: this.carrier.units,
                vin: vin,
            })
        );
    }

    // ========================================================================
    get secondaryUnit(): Unit | undefined {
        const vin = this.get("Unit2Vin");
        const license = this.get("UnitLicense");
        if (!vin) return undefined;

        return (
            this.#carrier.units.find({
                vin,
                license,
            }) ||
            Unit.new({
                carrier: this.carrier,
                fmcsa: {
                    license: this.get("Unit2License"),
                    make: this.get("Unit2Make"),
                    number: this.get("Unit2DecalNumber"),
                    type: this.get("Unit2Type"),
                },
                rawUnit: {
                    VIN: vin,
                },
                units: this.carrier.units,
                vin: vin,
            })
        );
    }

    // ========================================================================
    #isExpired?: boolean;
    #calculateIsExpired(): boolean {
        if (this.#date.isBefore(DateTime.subtractMonths(this.#carrier.dateRange.to, 24))) {
            this.#isExpired = true;
        } else {
            this.#isExpired = false;
        }
        return this.#isExpired;
    }
    get isExpired(): boolean {
        return this.#isExpired ?? this.#calculateIsExpired();
    }

    // ========================================================================
    #isExpiring?: boolean;
    #calculateIsExpiring(): boolean {
        if (
            this.#date.isBefore(this.#carrier.config.get("inspectionIsExpiringIfBeforeDate")) &&
            this.#date.isAfter(DateTime.subtractMonths(this.#carrier.dateRange.to, 24))
        ) {
            this.#isExpiring = true;
        } else {
            this.#isExpiring = false;
        }
        return this.#isExpiring;
    }
    get isExpiring(): boolean {
        return this.#isExpiring ?? this.#calculateIsExpiring();
    }

    // ========================================================================
    #isNew?: boolean;
    #calculateIsNew(): boolean {
        if (this.#date.isAfter(this.#carrier.config.get("inspectionIsNewIfAfterDate"))) {
            this.#isNew = true;
        } else {
            this.#isNew = false;
        }
        return this.#isNew;
    }
    get isNew(): boolean {
        return this.#isNew ?? this.#calculateIsNew();
    }

    // ========================================================================
    #basicsAssessed?: FMCSA.BasicName[] | undefined;
    #calculateBasicsAssessed(): FMCSA.BasicName[] {
        const basicsAssessed: FMCSA.BasicName[] = [];
        if (this.get("HazmatInsp")) {
            basicsAssessed.push(FMCSA.BasicName.HAZMAT);
        }
        if (this.get("DriverFitnessInsp")) {
            basicsAssessed.push(FMCSA.BasicName.DRIVER_FITNESS);
        }
        if (this.get("UnsafeDrivingInsp")) {
            basicsAssessed.push(FMCSA.BasicName.UNSAFE_DRIVING);
        }
        if (this.get("HoursOfServiceInsp")) {
            basicsAssessed.push(FMCSA.BasicName.HOS);
        }
        if (this.get("VehicleMaintenanceInsp")) {
            basicsAssessed.push(FMCSA.BasicName.VEHICLE_MAINTENANCE);
        }
        if (this.get("ControlledSubstancesInsp")) {
            basicsAssessed.push(FMCSA.BasicName.CONTROLLED_SUBSTANCES);
        }

        this.#basicsAssessed = basicsAssessed;
        return this.#basicsAssessed;
    }
    get basicsAssessed(): FMCSA.BasicName[] {
        return this.#basicsAssessed ?? this.#calculateBasicsAssessed();
    }

    // ========================================================================
    /**
     * Tests if the `Inspection` occurred before a particular date
     */
    occurredBeforeDate(date: Date): boolean {
        return DateTime.isBefore(this.#date, date);
    }

    // ========================================================================
    /**
     * Tests if the `Inspection` occurred after a particular date
     */
    occurredAfterDate(date: Date): boolean {
        return DateTime.isAfter(this.#date, date);
    }

    // ========================================================================
    /**
     * Tests if the `Inspection` occurred on a particular date
     */
    occurredOnDate(date: Date): boolean {
        return DateTime.isEqual(this.#date, date);
    }

    // ========================================================================
    /**
     * Returns a snippet of the ID
     * @param length snip length, default is 4
     */
    getIdSnip(length = 4): string {
        const idString = this.id.toString();
        return idString.slice(idString.length - length);
    }

    // ========================================================================
    /**
     * Returns a snippet of the Report Number
     * @param length snip length, default is 4
     */
    getReportNumberSnip(length = 4): string {
        const rptNum = this.get("ReportNumber");
        return rptNum.slice(rptNum.length - length);
    }

    // ========================================================================
    get hasExpiringWeight(): boolean {
        return this.violations.hasExpiringWeight;
    }

    // ========================================================================
    getTotalWeight(): number {
        return this.violations.getTotalWeight();
    }

    // ========================================================================
    json(
        { timeWeightDate }: { timeWeightDate: DateTime } = { timeWeightDate: this.carrier.dateRange.to }
    ): Inspection.JSON & Inspection.Raw {
        return {
            ...super.json(),
            Id: this.id,
            Date: this.#date,
            TotalViolations: this.violations.total,
            TotalViolationsWeight: this.getTotalWeight(),
            TimeWeight: this.getTimeWeight(timeWeightDate),
            ReportNumberFormatted: this.reportNumberFormatted,
        };
    }

    // ========================================================================
    raw(): Inspection.Raw {
        return super.json();
    }
}

export namespace Inspection {
    export enum Type {
        DRIVER = "Driver",
        VEHICLE = "Vehicle",
        HAZMAT = "Hazmat",
    }

    export interface JSON {
        Id: number;
        Date: DateTime;
        TotalViolations: number;
        TotalViolationsWeight: number;
        TimeWeight: number;
        ReportNumberFormatted: string;
    }
    export interface Raw extends FMCSA.Inspection {}
}
