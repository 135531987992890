import { DateTime } from "@deathstar/types/util";
import React from "react";
import ExpiringInspectionIcon from "../../assets/icons/expiring-inspection-icon.png";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import { pluralize } from "../utils/pluralize";
import { ViolationSummaryLegend } from "./ViolationSummaryLegend";

export function ExpiredViolations(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);
    const expiredInspections = motorCarrier.expiredInspections.filterByDateRange({
        from: new DateTime(motorCarrier.dateRange.from).subtractMonths(1),
        to: motorCarrier.dateRange.from,
    });
    const { violations } = expiredInspections;

    return (
        <div>
            <TitleGroup
                title="Expired Violations"
                variant="h5"
                className="mb-8"
                align="left"
                icon={<img src={ExpiringInspectionIcon} alt="Expiring Inspection Icon" className="h-[35px]" />}
                description={`${violations.total} old ${pluralize(violations.total, "violation", "s")} expired`}
            />

            <ViolationSummaryLegend violations={violations} />
        </div>
    );
}
