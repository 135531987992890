import { DefaultCommission, TransactionType } from "../../ams";
import { TransactionTypeConverter } from "../../util";
import { DateTime } from "../../util/DateTime/DateTime";
import { Policy } from "../Policy/Policy";

interface IBase {
    commissionRates: DefaultCommission[];
    commissionOptionCode: string;
    policyEffectiveDate: Date;
    writingCompanyCode: string;
    parentCompanyCode: string;
}

interface IWithAmsPolicyTransactionType extends IBase {
    amsPolicyTransactionType: TransactionType;
    policyTransactionType?: never;
}

interface IWithPolicyTransactionType extends IBase {
    policyTransactionType: Policy.TransactionType;
    amsPolicyTransactionType?: never;
}

type IOption = IWithAmsPolicyTransactionType | IWithPolicyTransactionType;

const writingCompanyCodeValidityMap = new Map<string, boolean>();

function getCompanyCode({
    commissionRates,
    parentCompanyCode,
    writingCompanyCode,
}: Pick<IOption, "commissionRates" | "writingCompanyCode" | "parentCompanyCode">): string {
    if (writingCompanyCodeValidityMap.has(writingCompanyCode) && writingCompanyCodeValidityMap.get(writingCompanyCode))
        return writingCompanyCode;
    const writinCoHasRates = commissionRates.some((cr) => cr.CompanyCode === writingCompanyCode);
    writingCompanyCodeValidityMap.set(writingCompanyCode, writinCoHasRates);
    return writinCoHasRates ? writingCompanyCode : parentCompanyCode;
}

/**
 *  match on LineOfBusinessCode and TransactionType
 *  match on LineOfBusinessCode and TransactionType is null
 *  match on TransactionType and LineOfBusinessCode is null
 *  match on LineOfBusinessCode is null nad TransactionType is null
 */
export function findCommissionRate({
    commissionRates,
    writingCompanyCode,
    parentCompanyCode,
    policyEffectiveDate,
    commissionOptionCode,
    policyTransactionType,
    amsPolicyTransactionType,
}: IOption): DefaultCommission | null {
    if (!writingCompanyCode || !policyEffectiveDate) return null;
    const polEffDate = new DateTime(policyEffectiveDate);
    const companyCode = getCompanyCode({ commissionRates, parentCompanyCode, writingCompanyCode });

    const validCommissionRates = commissionRates
        .filter((cr) => {
            return cr.CompanyCode === companyCode && new DateTime(cr.EffectiveDate).isBeforeOrEqual(polEffDate, { excludeTime: true });
        })
        .reduce((acc, cr) => {
            const key = `${cr.LineOfBusinessCode?.trim()}-${cr.TransactionType}`;
            if (acc.has(key)) {
                const existing = acc.get(key);
                if (existing.EffectiveDate.getTime() < cr.EffectiveDate.getTime()) {
                    acc.set(key, cr);
                }
            } else {
                acc.set(key, cr);
            }
            return acc;
        }, new Map<string, DefaultCommission>());

    const possibleCommissionRates = Array.from(validCommissionRates.values())
        .map((r) => {
            const isCommissionOptionCodeMatch = r.LineOfBusinessCode?.trim() === commissionOptionCode;
            const isTransactionTypeMatch =
                r.TransactionType === (amsPolicyTransactionType || TransactionTypeConverter.fromNsToAms(policyTransactionType));
            const isCommissionOptionCodeMatchOnly = isCommissionOptionCodeMatch && !r.TransactionType;
            const isTransactionTypeCodeMatchOnly = !r.LineOfBusinessCode && isTransactionTypeMatch;
            const isNegativeMatch = !r.LineOfBusinessCode && !r.TransactionType;

            return {
                entity: r,
                isFullMatch: isCommissionOptionCodeMatch && isTransactionTypeMatch,
                isCommissionOptionCodeMatchOnly,
                isTransactionTypeCodeMatchOnly,
                isNegativeMatch,
                isSomeMatch:
                    isCommissionOptionCodeMatch || isCommissionOptionCodeMatchOnly || isTransactionTypeCodeMatchOnly || isNegativeMatch,
            };
        })
        .filter((r) => r.isSomeMatch);

    if (!possibleCommissionRates.length) return null;
    if (possibleCommissionRates.length === 1) return possibleCommissionRates[0].entity;

    if (possibleCommissionRates.some((r) => r.isFullMatch)) {
        return possibleCommissionRates.find((r) => r.isFullMatch).entity;
    }
    if (possibleCommissionRates.some((r) => r.isCommissionOptionCodeMatchOnly)) {
        return possibleCommissionRates.find((r) => r.isCommissionOptionCodeMatchOnly).entity;
    }
    if (possibleCommissionRates.some((r) => r.isTransactionTypeCodeMatchOnly)) {
        return possibleCommissionRates.find((r) => r.isTransactionTypeCodeMatchOnly).entity;
    }
    if (possibleCommissionRates.some((r) => r.isNegativeMatch)) {
        return possibleCommissionRates.find((r) => r.isNegativeMatch).entity;
    }

    return null;
}
