import { FMCSA } from "@deathstar/types";
import { DateTime } from "@deathstar/types/util";
import { MotorCarrier } from "../MotorCarrier";

// ========================================================================
export namespace SafetyEventGroup {
    export namespace GroupNames {
        export type ControlledSubstances =
            | "ControlledSubstances1"
            | "ControlledSubstances2"
            | "ControlledSubstances3"
            | "ControlledSubstances4";

        export type Hazmat = "Hazmat1" | "Hazmat2" | "Hazmat3" | "Hazmat4" | "Hazmat5";

        export type VehicleMaintenance =
            | "VehicleMaintenance1"
            | "VehicleMaintenance2"
            | "VehicleMaintenance3"
            | "VehicleMaintenance4"
            | "VehicleMaintenance5";

        export type HoursOfService = "HoursOfService1" | "HoursOfService2" | "HoursOfService3" | "HoursOfService4" | "HoursOfService5";

        export type DriverFitness = "DriverFitness1" | "DriverFitness2" | "DriverFitness3" | "DriverFitness4" | "DriverFitness5";

        export type UnsafeDriving =
            | "UnsafeDrivingStraight1"
            | "UnsafeDrivingStraight2"
            | "UnsafeDrivingStraight3"
            | "UnsafeDrivingStraight4"
            | "UnsafeDrivingStraight5"
            | "UnsafeDrivingCombination1"
            | "UnsafeDrivingCombination2"
            | "UnsafeDrivingCombination3"
            | "UnsafeDrivingCombination4"
            | "UnsafeDrivingCombination5";

        export type CrashIndicator =
            | "CrashIndicatorStraight1"
            | "CrashIndicatorStraight2"
            | "CrashIndicatorStraight3"
            | "CrashIndicatorStraight4"
            | "CrashIndicatorStraight5"
            | "CrashIndicatorCombination1"
            | "CrashIndicatorCombination2"
            | "CrashIndicatorCombination3"
            | "CrashIndicatorCombination4"
            | "CrashIndicatorCombination5";
    }

    export type GroupNames =
        | GroupNames.ControlledSubstances
        | GroupNames.Hazmat
        | GroupNames.VehicleMaintenance
        | GroupNames.HoursOfService
        | GroupNames.DriverFitness
        | GroupNames.UnsafeDriving
        | GroupNames.CrashIndicator;

    export type Groups = 0 | 1 | 2 | 3 | 4 | 5;

    // ========================================================================
    export class MinMax {
        public constructor(public readonly min: number | null, public readonly max: number | null) {}

        get isUpperLimit(): boolean {
            return this.max === null;
        }

        isWithinLimits(amount: number): boolean {
            if (!this.min) return typeof amount !== "number";
            return this.max ? amount >= this.min && amount <= this.max : amount >= this.min;
        }
    }

    export interface IMinMax {
        vehicleMaintenance: MinMax;
        unsafeDriving: MinMax;
        hos: MinMax;
        controlledSubstances: MinMax;
        hazmat: MinMax;
        driverFitness: MinMax;
        crash: MinMax;
    }

    export abstract class Group<SubGroup> {
        public readonly number: number;
        public readonly name: SubGroup | null;
        public readonly minMax: MinMax | null;
        protected abstract calculate(
            numberOfRelevantInspections: number,
            segment?: FMCSA.Segment | null
        ): Group.ICalculationResults<SubGroup>;
        public isSegmented = false;

        // ========================================================================
        #carrier: MotorCarrier;
        constructor(carrier: MotorCarrier, totalRelevant: number) {
            this.#carrier = carrier;

            const { number, groupName, minMax } = this.calculate(totalRelevant, carrier.segment);

            this.number = number;
            this.name = groupName;
            this.minMax = minMax;
        }

        // ========================================================================
        public get carrier(): MotorCarrier {
            return this.#carrier;
        }
    }

    export namespace Group {
        export interface ICalculationResults<SubGroup> {
            number: number;
            groupName: SubGroup | null;
            minMax: MinMax | null;
        }
        // ========================================================================
        export class VehicleMaintenance extends Group<GroupNames.VehicleMaintenance> {
            static #limits = {
                one: new MinMax(5, 10),
                two: new MinMax(11, 20),
                three: new MinMax(21, 100),
                four: new MinMax(101, 500),
                five: new MinMax(501, null),
            };

            // ========================================================================
            protected calculate(totalVehicleInspections: number): ICalculationResults<GroupNames.VehicleMaintenance> {
                const { one, two, three, four, five } = VehicleMaintenance.#limits;

                if (one.isWithinLimits(totalVehicleInspections)) {
                    return {
                        number: 1,
                        groupName: "VehicleMaintenance1",
                        minMax: one,
                    };
                }
                if (two.isWithinLimits(totalVehicleInspections)) {
                    return {
                        number: 2,
                        groupName: "VehicleMaintenance2",
                        minMax: two,
                    };
                }
                if (three.isWithinLimits(totalVehicleInspections)) {
                    return {
                        number: 3,
                        groupName: "VehicleMaintenance3",
                        minMax: three,
                    };
                }
                if (four.isWithinLimits(totalVehicleInspections)) {
                    return {
                        number: 4,
                        groupName: "VehicleMaintenance4",
                        minMax: four,
                    };
                }
                if (five.isWithinLimits(totalVehicleInspections)) {
                    return {
                        number: 5,
                        groupName: "VehicleMaintenance5",
                        minMax: five,
                    };
                }

                return {
                    number: 0,
                    groupName: null,
                    minMax: new MinMax(null, null),
                };
            }
        }

        // ========================================================================
        export class DriverFitness extends Group<GroupNames.DriverFitness> {
            static #limits = {
                one: new MinMax(5, 10),
                two: new MinMax(11, 20),
                three: new MinMax(21, 100),
                four: new MinMax(101, 500),
                five: new MinMax(501, null),
            };

            // ========================================================================
            protected calculate(totalDriverInspections: number): ICalculationResults<GroupNames.DriverFitness> {
                const { one, two, three, four, five } = DriverFitness.#limits;

                if (one.isWithinLimits(totalDriverInspections)) {
                    return {
                        number: 1,
                        groupName: "DriverFitness1",
                        minMax: one,
                    };
                }
                if (two.isWithinLimits(totalDriverInspections)) {
                    return {
                        number: 2,
                        groupName: "DriverFitness2",
                        minMax: two,
                    };
                }
                if (three.isWithinLimits(totalDriverInspections)) {
                    return {
                        number: 3,
                        groupName: "DriverFitness3",
                        minMax: three,
                    };
                }
                if (four.isWithinLimits(totalDriverInspections)) {
                    return {
                        number: 4,
                        groupName: "DriverFitness4",
                        minMax: four,
                    };
                }
                if (five.isWithinLimits(totalDriverInspections)) {
                    return {
                        number: 5,
                        groupName: "DriverFitness5",
                        minMax: five,
                    };
                }

                return {
                    number: 0,
                    groupName: null,
                    minMax: new MinMax(null, null),
                };
            }
        }

        // ========================================================================
        export class HoursOfService extends Group<GroupNames.HoursOfService> {
            static #limits = {
                one: new MinMax(3, 10),
                two: new MinMax(11, 20),
                three: new MinMax(21, 100),
                four: new MinMax(101, 500),
                five: new MinMax(501, null),
            };

            // ========================================================================
            protected calculate(totalDriverInspections: number): ICalculationResults<GroupNames.HoursOfService> {
                const { one, two, three, four, five } = HoursOfService.#limits;

                if (one.isWithinLimits(totalDriverInspections)) {
                    return {
                        number: 1,
                        groupName: "HoursOfService1",
                        minMax: one,
                    };
                }
                if (two.isWithinLimits(totalDriverInspections)) {
                    return {
                        number: 2,
                        groupName: "HoursOfService2",
                        minMax: two,
                    };
                }
                if (three.isWithinLimits(totalDriverInspections)) {
                    return {
                        number: 3,
                        groupName: "HoursOfService3",
                        minMax: three,
                    };
                }
                if (four.isWithinLimits(totalDriverInspections)) {
                    return {
                        number: 4,
                        groupName: "HoursOfService4",
                        minMax: four,
                    };
                }
                if (five.isWithinLimits(totalDriverInspections)) {
                    return {
                        number: 5,
                        groupName: "HoursOfService5",
                        minMax: five,
                    };
                }
                return {
                    number: 0,
                    groupName: null,
                    minMax: new MinMax(null, null),
                };
            }
        }

        // ========================================================================
        export class Hazmat extends Group<GroupNames.Hazmat> {
            static #limits = {
                one: new MinMax(5, 10),
                two: new MinMax(11, 15),
                three: new MinMax(16, 40),
                four: new MinMax(41, 100),
                five: new MinMax(101, null),
            };

            // ========================================================================
            protected calculate(totalPlacardableHazmatInspections: number): ICalculationResults<GroupNames.Hazmat> {
                const { one, two, three, four, five } = Hazmat.#limits;

                if (one.isWithinLimits(totalPlacardableHazmatInspections)) {
                    return {
                        number: 1,
                        groupName: "Hazmat1",
                        minMax: one,
                    };
                }
                if (two.isWithinLimits(totalPlacardableHazmatInspections)) {
                    return {
                        number: 2,
                        groupName: "Hazmat2",
                        minMax: two,
                    };
                }
                if (three.isWithinLimits(totalPlacardableHazmatInspections)) {
                    return {
                        number: 3,
                        groupName: "Hazmat3",
                        minMax: three,
                    };
                }
                if (four.isWithinLimits(totalPlacardableHazmatInspections)) {
                    return {
                        number: 4,
                        groupName: "Hazmat4",
                        minMax: four,
                    };
                }
                if (five.isWithinLimits(totalPlacardableHazmatInspections)) {
                    return {
                        number: 5,
                        groupName: "Hazmat5",
                        minMax: five,
                    };
                }
                return {
                    number: 0,
                    groupName: null,
                    minMax: new MinMax(null, null),
                };
            }
        }

        // ========================================================================
        export class ControlledSubstances extends Group<GroupNames.ControlledSubstances> {
            static #limits = {
                one: new MinMax(1, 1),
                two: new MinMax(2, 2),
                three: new MinMax(3, 3),
                four: new MinMax(4, null),
            };

            // ========================================================================
            protected calculate(
                totalControlledSubstanceInspectionsWithAtLeastOneViolation: number
            ): ICalculationResults<GroupNames.ControlledSubstances> {
                const { one, two, three, four } = ControlledSubstances.#limits;

                if (one.isWithinLimits(totalControlledSubstanceInspectionsWithAtLeastOneViolation)) {
                    return {
                        number: 1,
                        groupName: "ControlledSubstances1",
                        minMax: one,
                    };
                }
                if (two.isWithinLimits(totalControlledSubstanceInspectionsWithAtLeastOneViolation)) {
                    return {
                        number: 2,
                        groupName: "ControlledSubstances2",
                        minMax: two,
                    };
                }
                if (three.isWithinLimits(totalControlledSubstanceInspectionsWithAtLeastOneViolation)) {
                    return {
                        number: 3,
                        groupName: "ControlledSubstances3",
                        minMax: three,
                    };
                }
                if (four.isWithinLimits(totalControlledSubstanceInspectionsWithAtLeastOneViolation)) {
                    return {
                        number: 4,
                        groupName: "ControlledSubstances4",
                        minMax: four,
                    };
                }

                return {
                    number: 0,
                    groupName: null,
                    minMax: new MinMax(null, null),
                };
            }
        }

        // ========================================================================
        export class UnsafeDriving extends Group<GroupNames.UnsafeDriving> {
            public override isSegmented = true;

            static #limits = {
                combination: {
                    one: new MinMax(3, 8),
                    two: new MinMax(9, 21),
                    three: new MinMax(22, 57),
                    four: new MinMax(58, 149),
                    five: new MinMax(150, null),
                },
                straight: {
                    one: new MinMax(3, 4),
                    two: new MinMax(5, 8),
                    three: new MinMax(9, 18),
                    four: new MinMax(19, 49),
                    five: new MinMax(50, null),
                },
            };

            // ========================================================================
            protected calculate(
                totalInspectionsWithViolation: number,
                segment: FMCSA.Segment
            ): ICalculationResults<GroupNames.UnsafeDriving> {
                const segmentText: "Combination" | "Straight" = segment === FMCSA.Segment.COMBINATION ? "Combination" : "Straight";
                const { one, two, three, four, five } =
                    segment === FMCSA.Segment.COMBINATION ? UnsafeDriving.#limits.combination : UnsafeDriving.#limits.straight;

                if (one.isWithinLimits(totalInspectionsWithViolation)) {
                    return {
                        number: 1,
                        groupName: `UnsafeDriving${segmentText}1`,
                        minMax: one,
                    };
                }
                if (two.isWithinLimits(totalInspectionsWithViolation)) {
                    return {
                        number: 2,
                        groupName: `UnsafeDriving${segmentText}2`,
                        minMax: two,
                    };
                }
                if (three.isWithinLimits(totalInspectionsWithViolation)) {
                    return {
                        number: 3,
                        groupName: `UnsafeDriving${segmentText}3`,
                        minMax: three,
                    };
                }
                if (four.isWithinLimits(totalInspectionsWithViolation)) {
                    return {
                        number: 4,
                        groupName: `UnsafeDriving${segmentText}4`,
                        minMax: four,
                    };
                }
                if (five.isWithinLimits(totalInspectionsWithViolation)) {
                    return {
                        number: 5,
                        groupName: `UnsafeDriving${segmentText}5`,
                        minMax: five,
                    };
                }

                return {
                    number: 0,
                    groupName: null,
                    minMax: new MinMax(null, null),
                };
            }
        }

        // ========================================================================
        export class CrashIndicator extends Group<GroupNames.CrashIndicator> {
            public override isSegmented = true;

            static #limits = {
                combination: {
                    one: new MinMax(2, 3),
                    two: new MinMax(4, 6),
                    three: new MinMax(7, 16),
                    four: new MinMax(17, 45),
                    five: new MinMax(46, null),
                },
                straight: {
                    one: new MinMax(2, 2),
                    two: new MinMax(3, 4),
                    three: new MinMax(5, 8),
                    four: new MinMax(9, 26),
                    five: new MinMax(27, null),
                },
            };

            // ========================================================================
            protected calculate(totalApplicableCrashes: number, segment: FMCSA.Segment): ICalculationResults<GroupNames.CrashIndicator> {
                const segmentText: "Combination" | "Straight" = segment === FMCSA.Segment.COMBINATION ? "Combination" : "Straight";
                const { one, two, three, four, five } =
                    segment === FMCSA.Segment.COMBINATION ? CrashIndicator.#limits.combination : CrashIndicator.#limits.straight;

                if (one.isWithinLimits(totalApplicableCrashes)) {
                    return {
                        number: 1,
                        groupName: `CrashIndicator${segmentText}1`,
                        minMax: one,
                    };
                }
                if (two.isWithinLimits(totalApplicableCrashes)) {
                    return {
                        number: 2,
                        groupName: `CrashIndicator${segmentText}2`,
                        minMax: two,
                    };
                }
                if (three.isWithinLimits(totalApplicableCrashes)) {
                    return {
                        number: 3,
                        groupName: `CrashIndicator${segmentText}3`,
                        minMax: three,
                    };
                }
                if (four.isWithinLimits(totalApplicableCrashes)) {
                    return {
                        number: 4,
                        groupName: `CrashIndicator${segmentText}4`,
                        minMax: four,
                    };
                }
                if (five.isWithinLimits(totalApplicableCrashes)) {
                    return {
                        number: 5,
                        groupName: `CrashIndicator${segmentText}5`,
                        minMax: five,
                    };
                }

                return {
                    number: 0,
                    groupName: null,
                    minMax: new MinMax(null, null),
                };
            }
        }
    }

    export class Results {
        // ========================================================================
        static generateKey(smsResultsRecordDate: DateTime, safetyEventGroupName: SafetyEventGroup.GroupNames, measure: number): string {
            return `${smsResultsRecordDate.format("YYYYMMDD")}-${safetyEventGroupName}-${measure}`;
        }

        // ========================================================================
        #results = new Map<string, number>();
        #fetcher: MotorCarrier.IFetcher;
        constructor(fetcher: MotorCarrier.IFetcher) {
            this.#fetcher = fetcher;
        }

        // ========================================================================
        async fetch(smsResultsRecordDate: DateTime, safetyEventGroupName: SafetyEventGroup.GroupNames, measure: number): Promise<number> {
            const key = Results.generateKey(smsResultsRecordDate, safetyEventGroupName, measure);
            if (this.#results.has(key)) {
                return this.#results.get(key)!;
            }

            return this.#fetcher.fetchSmsScore({
                recordDate: smsResultsRecordDate,
                safetyEventGroupName,
                measure,
            });
        }

        // ========================================================================
        json(): Results.JSON[] {
            return Array.from(this.#results).map(([key, result]) => {
                return {
                    key,
                    value: result,
                };
            });
        }
    }

    export namespace Results {
        export interface JSON {
            key: string;
            value: number;
        }
    }
}
