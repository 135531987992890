import { GlobalCertificateHolder } from "@deathstar/types/northstar";
import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const ownerOperatorEmployers = {
    queryKeys: {
        get: (accountId: string) => ["owner-operator-employers", accountId] as QueryKey,
    },

    getForAccount: (accountId: string) => {
        return blazar.fetchJson<GlobalCertificateHolder[]>(`waypoint/orgs/${accountId}/owner-operator-employers`);
    },

    useGetForAccount: (accountId: string, config?: Partial<UseQueryOptions<GlobalCertificateHolder[], ResponseError>>) => {
        return useQuery({
            queryKey: ownerOperatorEmployers.queryKeys.get(accountId),
            queryFn: () => ownerOperatorEmployers.getForAccount(accountId),
            staleTime: Infinity,
            enabled: !!accountId,
            ...config,
        });
    },

    updateEmployer(accountId: string, id: number, body: Partial<GlobalCertificateHolder>) {
        return blazar.fetchJson<GlobalCertificateHolder>(`waypoint/orgs/${accountId}/owner-operator-employers/${id}`, {
            method: "PATCH",
            body: JSON.stringify(body),
        });
    },
};
