export enum TransactionType {
    ANNIVERSARY_RE_RATE = "ARR",
    BINDER_NEW_BUSINESS = "BNB",
    BINDER_BILLABLE = "BND",
    BINDER_ENDORSEMENT = "BPC",
    BINDER_RENEWAL = "BRW",
    NEW_BUSINESS_QUOTE = "NBQ",
    NEW_BUSINESS = "NBS",
    NON_RENEWAL_NOTIFIED_AGENCY = "NRA",
    PREMIUM_AUDIT = "PAB",
    POLICY_CHANGE = "PCH",
    POLICY_CHANGE_QUOTE = "PCQ",
    POLICY_INQUIRY = "PNQ",
    POLICY_UNSPECIFIED = "POL",
    REINSTATEMENT = "REI",
    REWRITE = "REW",
    REISSUE = "RIX",
    REVERSAL_OF_NON_RENEWAL = "RNR",
    RENEWAL_RE_QUOTE = "RRQ",
    RENEWAL = "RWL",
    RENEWAL_QUOTE = "RWQ",
    RENEWAL_REQUEST = "RWR",
    NON_RENEWAL_NOTIFIED_POLICY_HOLDER = "RWX",
    POLICY_SYNCHRONIZATION = "SYN",
    POLICY_SYNCHRONIZATION_REQUEST = "SYR",
    CANCELLATION_CONFIRMATION = "XLC",
    CANCELLATION_REQUEST = "XLN",
}
