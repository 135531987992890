import { FMCSA } from "@deathstar/types";
import { DateTime } from "@deathstar/types/util";
import { SmsResult } from "../Basic/SmsResult";
import { CensusHistorical } from "../CensusHistorical/CensusHistorical";
import { EnforcementCases } from "../EnforcementCases/EnforcementCases";
import { Inspections } from "../Inspections/Inspections";
import { MotorCarrier } from "../MotorCarrier";

export class IssResult {
    #carrier: MotorCarrier;
    #isHighRiskCarrier: boolean;
    #totalBasicsOverThreshold: number;
    #totalBasicsOverThresholdBestAddressedRoadside: number;

    #recordDate: DateTime;
    #snapshotDate: DateTime;
    #inspections: Inspections;
    #smsResult: SmsResult;
    #censusData: CensusHistorical;
    #enforcementCases: EnforcementCases;

    readonly dateRange: MotorCarrier.IDates;
    readonly algorithmUsed: FMCSA.IssAlgorithm | null;
    readonly inspectionRecommendation: FMCSA.IssInspectionRecommendation | null;
    readonly score: number | null;
    readonly reason: string | null;
    readonly groupNumber: number | null;
    readonly group: FMCSA.IssGroup | null;

    // ========================================================================
    constructor({ carrier, recordDate, snapshotDate }: IssResult.Options) {
        this.#carrier = carrier;
        this.#recordDate = recordDate;
        this.#snapshotDate = snapshotDate;

        this.dateRange = MotorCarrier.calculateDates({
            snapshotDate,
        });

        this.#censusData = this.#carrier.getHistoricalCensusData(this.#recordDate);
        this.#inspections = carrier.inspections.filterByDateRange(this.dateRange);
        this.#smsResult = this.#carrier.smsResults.getByDate(this.#recordDate) || SmsResult.empty(this.#recordDate, this.#carrier);
        this.#totalBasicsOverThreshold = this.#smsResult.getTotalBasicsOverThreshold();
        this.#totalBasicsOverThresholdBestAddressedRoadside = this.#smsResult.getTotalBasicsOverThresholdBestAddressedRoadside();
        this.#isHighRiskCarrier = this.#calculateIsHighRiskCarrier(this.#totalBasicsOverThreshold, this.#smsResult);
        this.#enforcementCases = this.#carrier.enforcementCases.filterByDateRange({
            from: this.dateRange.twelveMonthMark,
            to: this.dateRange.to,
        });

        const results = this.#calculate();
        this.algorithmUsed = results.algorithmUsed;
        this.score = results.score;
        this.inspectionRecommendation = results.inspectionRecommendation;
        this.group = results.group;
        this.reason = FMCSA.IssGroup.getLabel(results.group);
        this.groupNumber = FMCSA.IssGroup.getNumber(results.group);
    }

    // ========================================================================
    getValuesMatchResults() {
        return {
            algorithm: {
                match: this.algorithmUsed === this.#smsResult.get("IssAlgorithm"),
                runtimeCalculated: this.algorithmUsed,
                dbStored: this.#smsResult.get("IssAlgorithm"),
            },
            groupNumber: {
                match: this.#smsResult.get("IssGroupNumber") !== this.groupNumber,
                runtimeCalculated: this.groupNumber,
                dbStored: this.#smsResult.get("IssGroupNumber"),
            },
            score: {
                match: this.score === this.#smsResult.get("IssScore"),
                runtimeCalculated: this.score,
                dbStored: this.#smsResult.get("IssScore"),
            },
        };
    }

    // ========================================================================
    get recordDate(): DateTime {
        return this.#recordDate;
    }

    // ========================================================================
    get snapshotDate(): DateTime {
        return this.#snapshotDate;
    }

    // ========================================================================
    #calculateIsHighRiskCarrier(totalBasicsOverThreshold: number, smsResult: SmsResult): boolean {
        if (totalBasicsOverThreshold >= 4) return true;
        if (totalBasicsOverThreshold >= 2) {
            if (
                (smsResult.unsafeDriving.score || 0) >= 85 ||
                (smsResult.hoursOfService.score || 0) >= 85 ||
                (smsResult.crashIndicator.score || 0) > 85
            ) {
                return true;
            }
        }

        return false;
    }

    // ========================================================================
    #calculate(): {
        algorithmUsed: FMCSA.IssAlgorithm | null;
        score: number | null;
        inspectionRecommendation: FMCSA.IssInspectionRecommendation | null;
        group: FMCSA.IssGroup | null;
    } {
        //#region Safety Algorithm
        if (this.#carrier.isOutOfService) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.OUT_OF_SERVICE_INDICATOR,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.INSPECT,
                score: 100,
                group: FMCSA.IssGroup.OUT_OF_SERVICE,
            };
        }
        if (this.#isHighRiskCarrier) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.INSPECT,
                score: null,
                group: FMCSA.IssGroup.HIGH_RISK,
            };
        }

        if (this.#totalBasicsOverThresholdBestAddressedRoadside >= 3) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.INSPECT,
                score: null,
                group: FMCSA.IssGroup.THREE_PLUS_BASICS_BEST_ADDRESSED_ROADSIDE_PRIORITIZED,
            };
        }

        if (this.#totalBasicsOverThresholdBestAddressedRoadside === 2) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.INSPECT,
                score: null,
                group: FMCSA.IssGroup.TWO_BASICS_BEST_ADDRESSED_ROADSIDE_PRIORITIZED,
            };
        }

        if (this.#totalBasicsOverThreshold > 1 && this.#totalBasicsOverThresholdBestAddressedRoadside === 1) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.INSPECT,
                score: null,
                group: FMCSA.IssGroup.MULTIPLE_PRIORITIZED_WITH_ONE_BASIC_BEST_ADDRESSED_ROADSIDE,
            };
        }

        if (this.#smsResult.hoursOfService.alert) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.INSPECT,
                score: null,
                group: FMCSA.IssGroup.HOS_SCORE_OVER_THRESHOLD,
            };
        }

        if (this.#enforcementCases.total > 0) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score: null,
                group: FMCSA.IssGroup.HAS_ACUTE_CRITICAL_VIOLATION,
            };
        }

        if (this.#smsResult.vehicleMaintenance.isScoreAboveThreshold) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score: null,
                group: FMCSA.IssGroup.VM_ALERT,
            };
        }

        if (this.#smsResult.hazmat.isScoreAboveThreshold) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score: null,
                group: FMCSA.IssGroup.HM_ALERT,
            };
        }

        if (this.#smsResult.driverFitness.isScoreAboveThreshold) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score: null,
                group: FMCSA.IssGroup.DF_ALERT,
            };
        }

        if (this.#smsResult.controlledSubstances.isScoreAboveThreshold) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score: null,
                group: FMCSA.IssGroup.CS_ALERT,
            };
        }

        if (this.#smsResult.unsafeDriving.isScoreAboveThreshold && this.#smsResult.crashIndicator.isScoreAboveThreshold) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score: null,
                group: FMCSA.IssGroup.UD_AND_CR_ALERT,
            };
        }

        if (this.#smsResult.crashIndicator.isScoreAboveThreshold) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score: null,
                group: FMCSA.IssGroup.CR_ALERT,
            };
        }

        if (this.#smsResult.unsafeDriving.isScoreAboveThreshold) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score: null,
                group: FMCSA.IssGroup.UD_ALERT,
            };
        }

        if (this.#smsResult.unsafeDriving.isScoreAboveThreshold) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score: null,
                group: FMCSA.IssGroup.UD_ALERT,
            };
        }

        if (
            this.#totalBasicsOverThreshold === 0 &&
            (this.#inspections.totalDriver >= 3 || this.#inspections.totalVehicle >= 5 || this.#smsResult.getTotalBasicsOverZero() > 0)
        ) {
            return {
                algorithmUsed: FMCSA.IssAlgorithm.SAFETY,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.PASS,
                score: null,
                group: FMCSA.IssGroup.SUFFICIENT_DATA_NO_ALERTS,
            };
        }
        //#endregion Safety Algorithm

        const { violationRate } = this.#inspections;
        if (this.#inspections.totalVehicle === 4) {
            let score = null;
            if (violationRate === 1) score = 74;
            else if (violationRate >= 0.75) score = 73;
            else if (violationRate >= 0.5) score = 72;
            else if (violationRate >= 0.25) score = 71;
            else score = 70;

            return {
                algorithmUsed: FMCSA.IssAlgorithm.INSUFFICIENT_DATA,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score,
                group: FMCSA.IssGroup.INSUFFICIENT_DATA,
            };
        }
        if (this.#inspections.totalDriver === 2) {
            let score = null;
            if (violationRate === 1) score = 74;
            else if (violationRate >= 0.5) score = 72;
            else score = 70;

            return {
                algorithmUsed: FMCSA.IssAlgorithm.INSUFFICIENT_DATA,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score,
                group: FMCSA.IssGroup.INSUFFICIENT_DATA,
            };
        }

        if (this.#inspections.total === 0) {
            const totalPowerunits = this.#censusData.get("TotalPowerUnits");
            const totalDrivers = this.#censusData.get("TotalDrivers");

            let score = null;
            if (totalPowerunits >= 1001 || totalDrivers >= 1001) score = 69;
            else if (totalPowerunits >= 201 || totalDrivers >= 201) score = 68;
            else if (totalPowerunits >= 64 || totalDrivers >= 64) score = 67;
            else if (totalPowerunits >= 16 || totalDrivers >= 16) score = 66;
            else if (totalPowerunits >= 7 || totalDrivers >= 7) score = 65;
            else if (totalPowerunits >= 2 || totalDrivers >= 2) score = 64;
            else if (totalPowerunits === 1 || totalDrivers === 1) score = 63;
            else score = 66;

            return {
                algorithmUsed: FMCSA.IssAlgorithm.INSUFFICIENT_DATA,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score,
                group: FMCSA.IssGroup.INSUFFICIENT_DATA,
            };
        }

        if (this.#inspections.total > 0 && this.#inspections.totalVehicle < 4 && this.#inspections.totalDriver < 2) {
            const inspectionPowerUnitRate = this.#inspections.total / this.#carrier.details.TotalPowerUnits;
            const inspectionDriverRate = this.#inspections.total / this.#carrier.details.TotalDrivers;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const inspectionAverageRate = (inspectionPowerUnitRate + inspectionDriverRate) / 2;

            // TODO: what is the score ???
            return {
                algorithmUsed: FMCSA.IssAlgorithm.INSUFFICIENT_DATA,
                inspectionRecommendation: FMCSA.IssInspectionRecommendation.OPTIONAL,
                score: null,
                group: FMCSA.IssGroup.INSUFFICIENT_DATA,
            };
        }

        return {
            algorithmUsed: null,
            inspectionRecommendation: null,
            score: null,
            group: null,
        };
    }

    // ========================================================================
    json(): IssResult.JSON {
        return {
            algorithmUsed: this.algorithmUsed,
            inspectionRecommendation: this.inspectionRecommendation,
            score: this.score,
            reason: this.reason,
            groupNumber: this.groupNumber,
            group: this.group,
        };
    }

    // ========================================================================
    toString(): string {
        return JSON.stringify(this.json());
    }
}

export namespace IssResult {
    export interface Options {
        carrier: MotorCarrier;
        recordDate: DateTime;
        snapshotDate: DateTime;
    }

    export interface JSON {
        algorithmUsed: FMCSA.IssAlgorithm | null;
        score: number | null;
        inspectionRecommendation: FMCSA.IssInspectionRecommendation | null;
        reason: string | null;
        groupNumber: number | null;
        group: FMCSA.IssGroup | null;
    }
}
