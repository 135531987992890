import { plainToClass, Transform, Type } from "class-transformer";
import { IsDate, IsInt, IsNumber, IsObject, IsOptional, IsString, Length, MaxLength, Validate, ValidateNested } from "class-validator";
import { CoverageOption, GeneralLedgerTransaction, PolicyAccountingDetails, PolicyLayer, PolicyTemplate } from "../";
import { DefaultCommission } from "../../ams/DefaultCommission";
import { Member as WpUser } from "../../waypoint/User";
import { Account } from "../Account";
import { Attribute } from "../Attribute";
import { AttributeOption } from "../AttributeOption";
import { Coverage } from "../Coverage";
import { Customer } from "../Customer";
import { Invoice } from "../Invoice";
import { PayStatementHeader } from "../PayStatementHeader";
import { PaymentPlan } from "../PaymentPlan";
import { findCommissionRate } from "../PolicyAccountingDetails/findCommissionRate";
import { PolicyAch } from "../PolicyAch";
import { GlobalCertificateHolderValidator, PolicyAdditionalInterest } from "../PolicyAdditionalInterest";
import { CommissionsRequiredValidator, PolicyCommission } from "../PolicyCommission";
import type { PolicyDocument } from "../PolicyDocument";
import { PolicyFee } from "../PolicyFee";
import type { User } from "../User";
import { PolicyEnums } from "./Enums";
import { BillMethodValidator, StatusValidator, TransactionTypeValidator } from "./validators";

const IS_NORTHSTAR_ONLY_STATUS = new Map<PolicyEnums.Status, boolean>([
    [PolicyEnums.Status.DRAFT, true],
    [PolicyEnums.Status.ACTIVE, false],
    [PolicyEnums.Status.QUOTE, false],
    [PolicyEnums.Status.RENEWED, false],
    [PolicyEnums.Status.REWRITTEN, false],
    [PolicyEnums.Status.CANCELLED, false],
    [PolicyEnums.Status.EXPIRED, false],
    [PolicyEnums.Status.NON_RENEWED, false],
    [PolicyEnums.Status.NOT_TAKEN, false],
    [PolicyEnums.Status.OTHER, false],
]);

function addCommissionRateMetadata({
    commission,
    commissionRates,
    policy,
}: {
    commissionRates: DefaultCommission[];
    policy: Policy;
    commission: PolicyCommission;
}): PolicyCommission {
    try {
        const data = findCommissionRate({
            commissionRates: commissionRates,
            writingCompanyCode: policy.writingCompanyCode,
            parentCompanyCode: policy.parentCompanyCode,
            commissionOptionCode: commission.commissionOptionCode,
            policyTransactionType: policy.policyTransactionType,
            policyEffectiveDate: policy.effectiveDate,
        });
        if (!commission.commissionOption) {
            console.log("no commission option", {
                commission,
            });
            commission.commissionOption = {
                code: commission.commissionOptionCode,
                description: "Unknown",
                metadata: data?.convertToDto() || null,
            };
            return commission;
        }
        commission.commissionOption.metadata = data?.convertToDto() || null;
        return commission;
    } catch (e) {
        console.log("Error setting commission option metadata", e);
        return commission;
    }
}

export class Policy
    implements
        Policy.IHasStatus,
        Policy.IHasPolicyNumber,
        Policy.IHasEffectiveDate,
        Policy.IHasCreatedDate,
        Policy.IHasLayerEffectiveDate,
        Policy.IHasAttributes,
        Policy.IHasPaymentPlanId,
        Policy.IHasLayerTransactionType,
        Policy.IHasPolicyTransactionType
{
    static hasCoverage(
        policy: {
            coverages?: { coverageOptionId: CoverageOption.Id }[];
        } & Pick<Policy, "description">,
        coverageOptionId: CoverageOption.Id
    ): boolean {
        if (!policy.coverages?.length) {
            policy.description?.includes(CoverageOption.Id.TRUCKERS_AUTO_LIABILITY as unknown as string);
        }
        const coverageIds = new Set(policy.coverages.map((c) => c.coverageOptionId));
        return coverageIds.has(coverageOptionId);
    }

    static async addCommissionRates(policies: Policy[], commissionRates: DefaultCommission[]): Promise<Policy[]> {
        return policies.map((policy) => {
            if (!policy.commissions?.length && !policy.allCommissions?.length) return policy;
            policy.commissions = policy.commissions?.map((commission) =>
                addCommissionRateMetadata({ commission, policy, commissionRates })
            );
            policy.allCommissions = policy.allCommissions?.map((commission) =>
                addCommissionRateMetadata({ commission, policy, commissionRates })
            );

            return policy;
        });
    }

    static sortInvoices(policy: Policy): Invoice[] {
        return [...policy.invoices].sort((a, b) => {
            return b.effectiveDate.valueOf() - a.effectiveDate.valueOf();
        });
    }

    static getLastInvoice(policy: Policy): Invoice | null {
        if (!policy.invoices?.length) return null;
        if (policy.invoices.length === 1) return policy.invoices[0];

        const sorted = [...policy.invoices].sort((a, b) => {
            return b.effectiveDate.valueOf() - a.effectiveDate.valueOf();
        });
        return sorted[0];
    }

    static getMonthInvoices(policy: Policy, date: Date): Invoice[] {
        if (!policy.invoices?.length) return [];

        return policy.invoices.filter((inv) => {
            return inv.effectiveDate.getMonth() === date.getMonth();
        });
    }

    static calculateTotalPaid(policy: Policy): number {
        if (!policy.invoices) return 0;

        return policy.billMethod === Policy.BillMethod.DIRECT
            ? policy.invoices?.reduce((acc, inv) => {
                  return (
                      acc +
                      inv.transactions?.reduce((acc2, t) => {
                          return acc2 + t.premium;
                      }, 0)
                  );
              }, 0) || NaN
            : policy.billMethod === Policy.BillMethod.AGENCY
            ? policy.invoices?.reduce((tot, inv) => {
                  const totalCreditAmount = inv.generalLedgerTransactions.reduce((total, glt) => {
                      if (!GeneralLedgerTransaction.isPossiblePayment(glt, policy.effectiveDate)) {
                          return total;
                      }

                      if (glt.sourceId === 3 && glt.sourceTypeId === 24) {
                          return total - glt.creditAmount;
                      }

                      return glt.sourceId === 4 ? total + glt.creditAmount - glt.debitAmount : total + glt.creditAmount;
                  }, 0);
                  return tot + totalCreditAmount;
              }, 0)
            : 0;
    }

    static equipmentIsLinkedToCoverage(
        policy: Policy,
        equipmentId: number,
        equipmentType: "driver" | "tractor" | "trailer" | "tool" | "businessAuto"
    ): boolean {
        return policy.coverages.some((coverage) => {
            if (equipmentType === "driver") {
                return coverage.linkedDrivers?.some((linkedDriver) => linkedDriver.driverId === equipmentId);
            }
            if (equipmentType === "tractor") {
                return coverage.linkedTractors?.some((linkedTractor) => linkedTractor.tractorId === equipmentId);
            }
            if (equipmentType === "trailer") {
                return coverage.linkedTrailers?.some((linkedTrailer) => linkedTrailer.trailerId === equipmentId);
            }
            if (equipmentType === "tool") {
                return coverage.linkedTools?.some((linkedTool) => linkedTool.toolId === equipmentId);
            }
            if (equipmentType === "businessAuto") {
                return coverage.linkedBusinessAutos?.some((linkedBusinessAuto) => linkedBusinessAuto.businessAutoId === equipmentId);
            }
            return false;
        });
    }

    /**
     * Is Independent Contractor Policy Monitoring
     */
    static isICPM(policy: { coverages?: Pick<Coverage, "coverageOptionId">[]; description?: string }): boolean {
        return (
            policy.coverages?.some(
                (coverage) => coverage.coverageOptionId === CoverageOption.Id.INDEPENDENT_CONTRACTOR_POLICY_MONITORING
            ) ||
            policy.description?.includes(
                CoverageOption.getMetadata(CoverageOption.Id.INDEPENDENT_CONTRACTOR_POLICY_MONITORING).defaultAbbreviation
            )
        );
    }

    static isAaePolicy(policies: Policy.IHasPolicyNumber[] = []): boolean {
        if (!policies?.length) return false;
        return policies.every((policy) => policy.number === Policy.AAE_NUMBER);
    }

    static #isNorthstarOnlyStatus(status: Policy.Status): boolean {
        return IS_NORTHSTAR_ONLY_STATUS.get(status) ?? false;
    }

    static #areValidAmsPolicies(policies: Policy.IHasStatus[] = []): boolean {
        return !Policy.#areNorthstarOnlyPolicies(policies);
    }

    static #areNorthstarOnlyPolicies(policies: Policy.IHasStatus[] = []): boolean {
        if (!policies?.length) return true;
        return policies.map((p) => Policy.#isNorthstarOnlyStatus(p.status)).every((isPreBind) => isPreBind);
    }

    static isDraft(policies: Policy.IHasStatus[] = []): boolean {
        if (!policies?.length) return true;
        return policies.every((policy) => policy.status === Policy.Status.DRAFT);
    }

    static reinstate(policy: Policy): Policy {
        const p = plainToClass(Policy, policy);

        p.status = Policy.Status.ACTIVE;
        p.layerTransactionType = Policy.TransactionType.REINSTATEMENT;
        p.layerEffectiveDate = new Date();
        p.layerDescription = null;

        p.commissions = p.commissions.map((a) => PolicyCommission.reinstate(a));
        p.fees = p.fees.map((f) => PolicyFee.reinstate(f));

        return p;
    }

    static cancel(policy: Policy): Policy {
        const p = plainToClass(Policy, policy);

        p.status = Policy.Status.CANCELLED;
        p.layerDescription = null;
        p.layerEffectiveDate = new Date();
        p.layerTransactionType = Policy.TransactionType.CANCELLATION_CONFIRMATION;

        p.commissions = p.commissions.map((a) => PolicyCommission.cancel(a));
        p.fees = p.fees.map((f) => PolicyFee.cancel(f));

        return p;
    }

    static duplicate(policy: Policy): Policy {
        const p = plainToClass(Policy, policy);

        p.id = null;
        p.amsPolicyId = null;
        p.amsLayerId = null;
        p.number = `${policy.number}-DUP`;
        p.layerTransactionType = policy.policyTransactionType;
        p.policyTransactionType = policy.policyTransactionType;
        p.status = Policy.Status.DRAFT;
        p.layerEffectiveDate = new Date();
        p.layerDescription = "Duplicate";
        p.attributes = p.attributes.map((a) => Attribute.duplicate(a));
        p.coverages = p.coverages.map((c) => Coverage.duplicate(c));
        p.additionalInterests = p.additionalInterests.map((a) => PolicyAdditionalInterest.duplicate(a));
        p.commissions = p.commissions.map((a) => PolicyCommission.duplicate(a));
        p.fees = p.fees.map((f) => PolicyFee.duplicate(f));

        return p;
    }

    static copyNew(policy: Policy): Policy {
        const p = plainToClass(Policy, policy);

        p.id = null;
        p.amsPolicyId = null;
        p.amsLayerId = null;
        p.number = `${policy.number}-COPY`;
        p.attributes = p.attributes.map((a) => Attribute.duplicate(a));
        p.coverages = p.coverages.map((c) => Coverage.duplicate(c));
        p.additionalInterests = p.additionalInterests.map((a) => PolicyAdditionalInterest.duplicate(a));
        p.commissions = p.commissions.map((a) => PolicyCommission.duplicate(a));
        p.fees = p.fees.map((f) => PolicyFee.duplicate(f));

        return p;
    }

    static renew(policy: Policy): Policy {
        const p = Policy.duplicate(policy);

        p.number = `${policy.number}-RWL`;
        p.layerTransactionType = PolicyEnums.TransactionType.RENEWAL;
        p.policyTransactionType = PolicyEnums.TransactionType.RENEWAL;
        p.layerDescription = "Renewal";
        p.effectiveDate = null;
        p.expirationDate = null;

        p.attributes = p.attributes
            .filter((a) => a.attributeOptionId !== AttributeOption.Id.HOUSE_ACCOUNT)
            .map((a) => Attribute.duplicate(a));

        return p;
    }

    static rewrite(policy: Policy): Policy {
        const p = Policy.duplicate(policy);

        p.number = `${policy.number}-RWT`;
        p.layerTransactionType = PolicyEnums.TransactionType.REWRITE;
        p.policyTransactionType = PolicyEnums.TransactionType.REWRITE;
        p.layerDescription = "Rewrite";
        p.parentCompany = null;
        p.parentCompanyCode = null;
        p.writingCompany = null;
        p.writingCompanyCode = null;
        p.effectiveDate = null;
        p.expirationDate = null;

        p.attributes = p.attributes
            .filter((a) => a.attributeOptionId !== AttributeOption.Id.HOUSE_ACCOUNT)
            .map((a) => Attribute.duplicate(a));

        return p;
    }

    static createTemplateFrom(policy: Policy): Policy {
        const p = plainToClass(Policy, policy);

        p.id = null;
        p.accountId = null;
        p.amsPolicyId = null;
        p.amsLayerId = null;
        p.amsClientNumber = null;
        p.number = "TBD";
        p.layerTransactionType = null;
        p.policyTransactionType = null;
        p.status = Policy.Status.DRAFT;
        p.layerEffectiveDate = null;
        p.layerDescription = null;
        p.effectiveDate = null;
        p.expirationDate = null;
        p.fullTermPremium = null;
        p.accountManagerId = null;
        p.accountManager = null;
        p.firstNamedInsured = null;
        p.agentId = null;
        p.agent = null;
        p.attributes = p.attributes.map((a) => Attribute.duplicate(a));
        p.coverages = p.coverages.map((c) => Coverage.createTemplateFrom(c));
        p.additionalInterests = p.additionalInterests
            .filter((ai) => ai.isBlanket)
            .map((ai) => PolicyAdditionalInterest.createTemplateFrom(ai));
        p.commissions = p.commissions.map((a) => ({
            ...PolicyCommission.duplicate(a),
            premium: null,
        }));
        p.fees = p.fees.map((f) => ({
            ...PolicyFee.duplicate(f),
            premium: null,
        }));

        return p;
    }

    static isCaptive(policy: Policy.IHasAttributes): boolean {
        return Policy.hasSomeAttribute(policy, [
            AttributeOption.Id.CAPTIVE_VENTURE,
            AttributeOption.Id.CAPTIVE_VOYAGER,
            AttributeOption.Id.CAPTIVE_VELOCITY,
            AttributeOption.Id.CAPTIVE_POWERTECH_ELITE,
        ]);
    }

    static isScheduled(policy: Policy.IHasCoveragesWithAttributes): boolean {
        if (!policy.coverages?.length) return false;
        for (const cvg of policy.coverages) {
            if (
                cvg.coverageOptionId !== CoverageOption.Id.TRUCKERS_AUTO_LIABILITY &&
                cvg.coverageOptionId !== CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE
            ) {
                continue;
            }
            if (Policy.hasAttribute(cvg, AttributeOption.Id.SYMBOL_SCHEDULED)) return true;
            if (!cvg.limits) continue;
            for (const limit of cvg.limits) {
                if (Policy.hasAttribute(limit, AttributeOption.Id.SYMBOL_SCHEDULED)) return true;
            }
        }
        return false;
    }

    static isTemplate(partialPolicy: Pick<Policy, "accountId" | "amsClientNumber">): boolean {
        if (!partialPolicy) return false;
        return partialPolicy.accountId === PolicyTemplate.accountId && partialPolicy.amsClientNumber === PolicyTemplate.amsClientNumber;
    }

    static isNewBusiness(policy: Policy.IHasAttributes): boolean {
        return Policy.hasSomeAttribute(policy, [AttributeOption.Id.NEW_TO_AGENCY_NEW, AttributeOption.Id.NEW_TO_AGENCY_RENEWAL]);
    }

    /**
     * @deprecated use isComposite
     */
    static isReporter(policy: Policy.IHasAttributes): boolean {
        return Policy.hasSomeAttribute(policy, [AttributeOption.Id.COMPOSITE_ADVANCED, AttributeOption.Id.COMPOSITE_ARREARS]);
    }

    static isComposite(policy: Policy.IHasAttributes): boolean {
        return Policy.hasSomeAttribute(policy, [AttributeOption.Id.COMPOSITE_ADVANCED, AttributeOption.Id.COMPOSITE_ARREARS]);
    }

    static isMonthlyReporter(
        policy: Policy.IHasAttributes & {
            coverages: Pick<Policy["coverages"][0], "frequency" | "compositeRatings">[];
        }
    ): boolean {
        return (
            Policy.isComposite(policy) &&
            !policy.attributes.some((a) => a.attributeOptionId === AttributeOption.Id.CONTRACTOR_BENEFITS_ADMIN) &&
            policy.coverages.some((c) =>
                c.frequency
                    ? c.frequency === Coverage.Frequency.MONTHLY
                    : c.compositeRatings?.some((cr) => cr.frequency === Coverage.Frequency.MONTHLY)
            )
        );
    }

    static isCompositeArrears(policy: Policy.IHasAttributes): boolean {
        return Policy.getCompositeType(policy) === AttributeOption.Id.COMPOSITE_ARREARS;
    }

    static isCompositeAdvanced(policy: Policy.IHasAttributes): boolean {
        return Policy.getCompositeType(policy) === AttributeOption.Id.COMPOSITE_ADVANCED;
    }

    static getCompositeType(policy: Policy.IHasAttributes): AttributeOption.CompositeTypes | null {
        if (Policy.hasAttribute(policy, AttributeOption.Id.COMPOSITE_ADVANCED)) return AttributeOption.Id.COMPOSITE_ADVANCED;
        if (Policy.hasAttribute(policy, AttributeOption.Id.COMPOSITE_ARREARS)) return AttributeOption.Id.COMPOSITE_ARREARS;
        return null;
    }

    static isFinanced(policy: Policy.IHasPaymentPlanId): boolean {
        return policy.paymentPlanId === PaymentPlan.Id.PREMIUM_FINANCE;
    }

    static isHouseAccount(policy: Policy.IHasAttributes): boolean {
        return Policy.hasAttribute(policy, AttributeOption.Id.HOUSE_ACCOUNT);
    }

    static getHouseAccountSegmentOverride(policy: Pick<Policy, "attributes">): number | null {
        const attr = Policy.getAttribute(policy, AttributeOption.Id.HOUSE_ACCOUNT);
        if (!attr || typeof attr?.valueNumber !== "number") return null;
        return attr.valueNumber;
    }

    static isDemo(policy: Policy.IHasAttributes): boolean {
        return Policy.hasAttribute(policy, AttributeOption.Id.DEMO_POLICY);
    }

    static hasAttribute(policy: Partial<Policy.IHasAttributes>, attributeOptionId: AttributeOption.Id): boolean {
        if (!policy.attributes?.length) return false;
        return policy.attributes.some((attr) => attr.attributeOptionId === attributeOptionId) ?? false;
    }

    static getAttribute(policy: Pick<Policy, "attributes">, attributeOptionId: AttributeOption.Id): Attribute | null {
        if (!policy.attributes?.length) return null;
        return policy.attributes.find((attr) => attr.attributeOptionId === attributeOptionId) ?? null;
    }

    static isEdit(policy: Policy.IHasLayerTransactionType): boolean {
        return PolicyLayer.isEdit({ type: policy.layerTransactionType });
    }

    static isEndorsement(policy: Policy.IHasLayerTransactionType & Policy.IHasPolicyTransactionType): boolean {
        return PolicyLayer.isEndorsement({
            type: policy.layerTransactionType,
            policyType: policy.policyTransactionType,
        });
    }

    static isUpdate(policy: Policy.IHasLayerTransactionType & Policy.IHasPolicyTransactionType): boolean {
        return PolicyLayer.isUpdate({
            type: policy.layerTransactionType,
            policyType: policy.policyTransactionType,
        });
    }

    static hasAssociatedAgencyExpenseAttribute(policy: Policy.IHasAttributes): boolean {
        return Policy.hasAttribute(policy, AttributeOption.Id.AGENCY_EXPENSE);
    }

    static hasAssociatedAgencyExpensePolicy(policy: Policy.IHasAttributesWithValue): boolean {
        const agencyExpenseAttr = policy.attributes.find((a) => a.attributeOptionId === AttributeOption.Id.AGENCY_EXPENSE);
        return Boolean(agencyExpenseAttr && agencyExpenseAttr.valueText);
    }

    static getAssociatedAgencyExpenseValue(policy: Policy.IHasAttributesWithValue): number | null {
        const agencyExpenseAttr = policy.attributes.find((a) => a.attributeOptionId === AttributeOption.Id.AGENCY_EXPENSE);
        return typeof agencyExpenseAttr.valueNumber === "number" ? agencyExpenseAttr.valueNumber : null;
    }

    static getAssociatedAgencyExpensePolicyId(policy: Policy.IHasAttributesWithValue): string | null {
        const agencyExpenseAttr = policy.attributes.find((a) => a.attributeOptionId === AttributeOption.Id.AGENCY_EXPENSE);
        return typeof agencyExpenseAttr.valueText === "string" ? agencyExpenseAttr.valueText : null;
    }

    /**
     * If any of the attributes included in the array are found on the policy, returns true otherwise false
     */
    static hasSomeAttribute(policy: Policy.IHasAttributes, attributeOptionIds: AttributeOption.Id[]): boolean {
        if (!policy.attributes?.length) return false;
        return policy.attributes.some((x) => attributeOptionIds.includes(x.attributeOptionId));
    }

    /**
     * If the policy status is ACTIVE or PRE_CANCELLATION, the policy is considered "in-force".
     */
    static isInForce(policy: Partial<Pick<Policy, "status"> & { layerStatus: Policy.Status }>): boolean {
        if ([Policy.Status.ACTIVE, Policy.Status.PRE_CANCELLATION].includes(policy.layerStatus || policy.status)) return true;
        return false;
    }

    static hasEndorsement(policies: (Policy.IHasLayerTransactionType & Policy.IHasPolicyTransactionType)[]): boolean {
        return PolicyLayer.hasEndorsement(policies);
    }

    static hasEndorsementOrUpdate(policies: (Policy.IHasLayerTransactionType & Policy.IHasPolicyTransactionType)[]): boolean {
        return PolicyLayer.hasEndorsementOrUpdate(policies);
    }

    static getTransactionTypeDisplay(transactionType: Policy.TransactionType): string {
        switch (transactionType) {
            case Policy.TransactionType.CHANGE:
                return "Endorsement";
            default:
                return transactionType;
        }
    }

    static AAE_NUMBER = "AGENCY ALLOCATED EXPENSE" as const;
    static ValidationGroups = PolicyEnums.ValidationGroups;
    static TransactionType = PolicyEnums.TransactionType;
    static endorsementTransactionTypes = [
        PolicyEnums.TransactionType.MONTHLY_REPORT,
        PolicyEnums.TransactionType.MONTHLY_REPORT_FINAL,
        PolicyEnums.TransactionType.ANNIVERSARY_RE_RATE,
        PolicyEnums.TransactionType.BINDER_NEW_BUSINESS,
        PolicyEnums.TransactionType.BINDER_BILLABLE,
        PolicyEnums.TransactionType.BINDER_ENDORSEMENT,
        PolicyEnums.TransactionType.BINDER_RENEWAL,
        PolicyEnums.TransactionType.QUOTE,
        PolicyEnums.TransactionType.NEW,
        PolicyEnums.TransactionType.NON_RENEWAL_NOTIFIED_AGENCY,
        PolicyEnums.TransactionType.PREMIUM_AUDIT,
        PolicyEnums.TransactionType.CHANGE,
        PolicyEnums.TransactionType.CHANGE_QUOTE,
        PolicyEnums.TransactionType.INQUIRY,
        PolicyEnums.TransactionType.OTHER,
        PolicyEnums.TransactionType.REINSTATEMENT,
        PolicyEnums.TransactionType.REWRITE,
        PolicyEnums.TransactionType.REISSUE,
        PolicyEnums.TransactionType.REVERSAL_OF_NON_RENEWAL,
        PolicyEnums.TransactionType.RENEWAL_RE_QUOTE,
        PolicyEnums.TransactionType.RENEWAL,
        PolicyEnums.TransactionType.RENEWAL_QUOTE,
        PolicyEnums.TransactionType.RENEWAL_REQUEST,
        PolicyEnums.TransactionType.NON_RENEWAL_NOTIFIED_POLICY_HOLDER,
        PolicyEnums.TransactionType.POLICY_SYNCHRONIZATION,
        PolicyEnums.TransactionType.POLICY_SYNCHRONIZATION_REQUEST,
        PolicyEnums.TransactionType.CANCELLATION_CONFIRMATION,
        PolicyEnums.TransactionType.CANCELLATION_REQUEST,
    ];
    static nonEndoresementTransactionTypes = [
        PolicyEnums.TransactionType.PRE_BIND,
        PolicyEnums.TransactionType.UPDATE,
        PolicyEnums.TransactionType.EDIT,
    ];

    static Status = {
        ...PolicyEnums.Status,
        isValidAms(status: Policy.Status): boolean {
            return Policy.#areValidAmsPolicies([{ status }]);
        },
        isNorthstarOnly(status: Policy.Status): boolean {
            return Policy.#areNorthstarOnlyPolicies([{ status }]);
        },
        includesValidAms(policies: Policy.IHasStatus[] = []): boolean {
            return Policy.#areValidAmsPolicies(policies);
        },
        includesNorthstarOnly(policies: Policy.IHasStatus[] = []): boolean {
            return Policy.#areNorthstarOnlyPolicies(policies);
        },
        getValues(): Policy.Status[] {
            return Object.values(PolicyEnums.Status);
        },
        getAmsStatusList(): Policy.Status[] {
            return [
                PolicyEnums.Status.ACTIVE,
                PolicyEnums.Status.CANCELLED,
                PolicyEnums.Status.EXPIRED,
                PolicyEnums.Status.NON_RENEWED,
                PolicyEnums.Status.NOT_TAKEN,
                PolicyEnums.Status.OTHER,
                PolicyEnums.Status.QUOTE,
                PolicyEnums.Status.RENEWED,
                PolicyEnums.Status.REWRITTEN,
            ];
        },
    };
    static BusinessType = PolicyEnums.BusinessType;
    static BillMethod = PolicyEnums.BillMethod;
    static ParentCompanyType = PolicyEnums.ParentCompanyType;
    static CoveragePackage = PolicyEnums.CoveragePackage;

    static getCoverageSummary<T extends Coverage.ICanGenerateAbbreviation>(policy: {
        description: Policy["description"];
        coverages: T[];
    }): Coverage.Summaries<T> {
        return new Coverage.Summaries(policy.coverages, policy);
    }

    static getCoverageMetadataFromDescription(
        { description }: Pick<Policy, "description">,
        options?: { sorted?: boolean }
    ): CoverageOption.IMetadataWithAbbreviation[] {
        const parts = description.split(",").map((x) => x.trim() as CoverageOption.Abbreviation);
        const metadata = parts.map((part) => {
            return Coverage.getMetadataFromAbbreviation(part);
        });
        if (options?.sorted) {
            CoverageOption.sortMetadata(metadata);
        }
        return metadata;
    }

    static getDescription<T extends Coverage.ICanGenerateAbbreviation>(policy: {
        description: Policy["description"];
        coverages: T[];
    }): string {
        return new Coverage.Summaries(policy.coverages, policy).getDescription();
    }

    //#region Metadata fields
    @IsString({
        groups: [PolicyEnums.ValidationGroups.BOUND],
    })
    @IsOptional({
        groups: [
            PolicyEnums.ValidationGroups.UI_BUILDER,
            PolicyEnums.ValidationGroups.CREATE_DTO,
            PolicyEnums.ValidationGroups.PRE_BIND_DTO,
        ],
    })
    id: string;

    @IsDate({
        groups: [
            PolicyEnums.ValidationGroups.BOUND,
            PolicyEnums.ValidationGroups.UPDATE_DTO,
            PolicyEnums.ValidationGroups.CREATE_DTO,
            PolicyEnums.ValidationGroups.PRE_BIND_DTO,
        ],
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.UI_BUILDER],
    })
    createdDate: Date;

    @IsDate()
    @IsOptional({
        always: true,
    })
    /** Note: This field does not necessarily notate that the layer is no longer in-force. `expiredDate` may
     *  be set and the layer may still be 'active'
     **/
    expiredDate?: Date;

    @IsString({
        groups: [
            PolicyEnums.ValidationGroups.BOUND,
            PolicyEnums.ValidationGroups.UPDATE_DTO,
            PolicyEnums.ValidationGroups.CREATE_DTO,
            PolicyEnums.ValidationGroups.PRE_BIND_DTO,
        ],
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.UI_BUILDER],
    })
    accountId: string;

    @IsNumber(null, {
        groups: [PolicyEnums.ValidationGroups.BOUND, PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.UI_BUILDER, PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    amsClientNumber: number;

    @IsString({
        groups: [PolicyEnums.ValidationGroups.BOUND],
    })
    @IsOptional({
        groups: [
            PolicyEnums.ValidationGroups.UI_BUILDER,
            PolicyEnums.ValidationGroups.UPDATE_DTO,
            PolicyEnums.ValidationGroups.CREATE_DTO,
            PolicyEnums.ValidationGroups.PRE_BIND_DTO,
        ],
    })
    amsPolicyId?: string;

    amsLayerId?: Date;

    @IsString({
        groups: [PolicyEnums.ValidationGroups.BOUND, PolicyEnums.ValidationGroups.UPDATE_DTO],
    })
    @IsOptional({
        groups: [
            PolicyEnums.ValidationGroups.UI_BUILDER,
            PolicyEnums.ValidationGroups.CREATE_DTO,
            PolicyEnums.ValidationGroups.PRE_BIND_DTO,
        ],
    })
    policyTransactionType: Policy.TransactionType;
    //#endregion Metadata fields

    //#region AMS Required Fields (Mostly)
    @IsDate({
        groups: [PolicyEnums.ValidationGroups.UPDATE_DTO],
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    layerEffectiveDate?: Date;

    @IsString({
        message: "Invalid layer description",
    })
    @MaxLength(255, {
        message: (x) => {
            return `${String(x.value).length}/255 characters`;
        },
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.CREATE_DTO],
    })
    layerDescription?: string;

    @Validate(TransactionTypeValidator, {
        groups: [PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
        message: "Invalid transaction type",
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.UI_BUILDER, PolicyEnums.ValidationGroups.CREATE_DTO],
    })
    entityTransactionType: Policy.TransactionType;

    @IsString()
    @IsOptional()
    firstNamedInsured?: string;

    @IsString()
    @IsOptional()
    dealId?: string;

    @IsInt()
    @IsOptional()
    submissionId?: number;

    @Length(1, 25, {
        always: true,
        message: "Invalid policy number",
        context: {
            fieldNameOverride: "policyNumber",
        },
    })
    number: string;

    @Validate(TransactionTypeValidator, {
        groups: [PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
        message: "Invalid transaction type",
        context: {
            fieldNameOverride: "transactionType",
        },
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    layerTransactionType: Policy.TransactionType;

    @IsString()
    @IsOptional({
        always: true,
    })
    description: string;

    @Validate(StatusValidator, {
        always: true,
        message: "Invalid status type",
    })
    status: Policy.Status;

    policyStatus: Policy.Status;

    @Validate(BillMethodValidator, {
        groups: [PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
        message: "Invalid bill method",
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    billMethod: Policy.BillMethod;

    @IsDate({
        groups: [PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
        message: "Invalid effective date",
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    effectiveDate: Date;

    @IsDate({
        groups: [PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
        message: "Invalid expiration date",
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    expirationDate: Date;

    @IsString({
        groups: [PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
        context: { fieldNameOverride: "paymentPlan" },
        message: "Invalid payment plan",
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    paymentPlanId: string;

    @IsString({
        groups: [PolicyEnums.ValidationGroups.BOUND, PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.UI_BUILDER, PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    accountManagerId: string;

    @IsObject({
        groups: [PolicyEnums.ValidationGroups.BOUND, PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
        message: "Please select an AM",
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    accountManager: User;

    @IsString({
        groups: [PolicyEnums.ValidationGroups.BOUND, PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.UI_BUILDER, PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    agentId: string;

    @IsObject({
        groups: [PolicyEnums.ValidationGroups.BOUND, PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
        message: "Please select an agent",
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    agent: User;

    @IsString({
        groups: [PolicyEnums.ValidationGroups.BOUND, PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    parentCompanyType: Policy.ParentCompanyType;

    @Type(() => Customer)
    @IsObject({
        groups: [PolicyEnums.ValidationGroups.BOUND, PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
        message: "Invalid parent company",
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    parentCompany?: Customer;

    @Length(3, 3, {
        groups: [PolicyEnums.ValidationGroups.BOUND, PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
        message: "Invalid parent company",
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.UI_BUILDER, PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    parentCompanyCode?: string;

    @Type(() => Customer)
    @IsObject({
        groups: [PolicyEnums.ValidationGroups.BOUND, PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
        message: "Invalid writing company",
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    writingCompany?: Customer;

    @Length(3, 3, {
        groups: [PolicyEnums.ValidationGroups.BOUND, PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
        message: "Invalid writing company",
    })
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.UI_BUILDER, PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    writingCompanyCode?: string;

    @IsNumber(
        {
            allowInfinity: false,
            allowNaN: false,
        },
        {
            groups: [PolicyEnums.ValidationGroups.BOUND, PolicyEnums.ValidationGroups.UPDATE_DTO, PolicyEnums.ValidationGroups.CREATE_DTO],
            message: "Must be a number",
        }
    )
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO],
    })
    fullTermPremium: number;
    //#endregion AMS Required Fields (Mostly)

    cancelEffectiveDate?: Date | null;
    totalCost: number;
    totalPaid: number;
    remarks: string;
    isCertUseOnly: boolean;
    isDeleted: boolean;
    isHidden: boolean;
    coveragePackage: Policy.CoveragePackage;
    isNorthstarPolicy: boolean;
    isBackdatedEndorsementLayer: boolean;
    isBackdatedEditLayer: boolean;
    isBackdatedUpdateLayer: boolean;
    isCascadeLayer: boolean;
    isBelowThreshold: boolean;
    isDemo: boolean;
    hasBeenBilled: boolean;
    totalBilled: number;
    metadata?: Record<string, unknown>;
    createdByGuid: string;
    createdBy: User;
    createdByWaypointUserId: string;
    createdByWaypointUser: Pick<WpUser, "id" | "displayName">;
    expiredByGuid: string;
    expiredBy: User;
    accountingDetails?: PolicyAccountingDetails;
    accountingDetailsJson?: PolicyAccountingDetails.JSON;

    @Type(() => Account)
    account: Account;

    @Type(() => Coverage)
    @Transform(({ value }) => (!value ? [] : value))
    @IsOptional()
    coverages: Coverage[];

    @Type(() => Attribute)
    @Transform(({ value }) => (!value ? [] : value))
    @IsOptional()
    attributes: Attribute[];

    @Validate(GlobalCertificateHolderValidator, {
        always: true,
        context: { fieldName: "additionalInterests" },
    })
    @Type(() => PolicyAdditionalInterest)
    @Transform(({ value }) => (!value ? [] : value))
    @ValidateNested({
        always: true,
    })
    additionalInterests: PolicyAdditionalInterest[];

    @Validate(CommissionsRequiredValidator, {
        context: { fieldNameOverride: "commissions.0.lineOfBusiness" },
        groups: [PolicyEnums.ValidationGroups.COMMISSION],
    })
    @ValidateNested({
        groups: [PolicyEnums.ValidationGroups.COMMISSION],
    })
    @Type(() => PolicyCommission)
    @Transform(({ value }) => (!value ? [] : value))
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO, PolicyEnums.ValidationGroups.NON_ACCOUNTING_DTO],
    })
    commissions: PolicyCommission[];
    allCommissions: PolicyCommission[];

    @Type(() => PolicyFee)
    @Transform(({ value }) => (!value ? [] : value))
    @IsOptional({
        groups: [PolicyEnums.ValidationGroups.PRE_BIND_DTO, PolicyEnums.ValidationGroups.NON_ACCOUNTING_DTO],
    })
    fees: PolicyFee[];

    @Type(() => Invoice)
    @Transform(({ value }) => (!value ? [] : value))
    invoices?: Invoice[];

    documents?: PolicyDocument[];
    statements?: PayStatementHeader[];

    @Type(() => PolicyAch)
    ach?: PolicyAch;
}

export class PolicyHistoryQueryConfig {
    @IsOptional()
    @IsString({ each: true })
    transactionTypes?: Policy.TransactionType[];

    @IsOptional()
    @IsString()
    number?: string;

    @IsOptional()
    @IsObject()
    order?: {
        field: "layerEffectiveDate" | "effectiveDate" | "number" | "createdDate";
        direction: "ASC" | "DESC";
    };

    @IsOptional()
    @IsString({ each: true })
    createdDate?: [string, string];

    @IsOptional()
    @IsString()
    keyword?: string;

    @IsOptional()
    @IsInt()
    @Transform(({ value }) => (value ? parseInt(value) : undefined))
    pageIndex?: number;

    @IsOptional()
    @IsInt()
    @Transform(({ value }) => (value ? parseInt(value) : undefined))
    pageSize?: number;
}

export interface PolicyHistoryQueryResult {
    data: (Pick<
        Policy,
        | "accountId"
        | "id"
        | "agent"
        | "accountManager"
        | "createdBy"
        | "createdByWaypointUser"
        | "writingCompany"
        | "parentCompany"
        | "layerDescription"
        | "layerEffectiveDate"
        | "entityTransactionType"
        | "layerTransactionType"
        | "createdDate"
        | "createdByGuid"
        | "agentId"
        | "accountManagerId"
        | "effectiveDate"
        | "expirationDate"
        | "number"
        | "policyTransactionType"
        | "amsClientNumber"
        | "status"
        | "isCertUseOnly"
    > & { coverages: string; endorsementDocuments: Omit<PolicyDocument, "policy">[] })[];
    totalCount: number;
    pageIndex: number;
    pageSize: number;
}

export declare namespace Policy {
    export type TransactionType = PolicyEnums.TransactionType;
    export type Status = PolicyEnums.Status;
    export type BillMethod = PolicyEnums.BillMethod;
    export type ParentCompanyType = PolicyEnums.ParentCompanyType;
    export type CoveragePackage = PolicyEnums.CoveragePackage;
    export type ValidationGroups = PolicyEnums.ValidationGroups;

    export interface IHasAttributes {
        attributes: Pick<Attribute, "attributeOptionId">[];
    }

    export interface IHasAttributesWithValue {
        attributes: Pick<Attribute, "attributeOptionId" | "valueText" | "valueNumber">[];
    }

    export interface IHasPaymentPlanId {
        paymentPlanId: string;
    }

    export interface IHasLayerTransactionType {
        layerTransactionType: Policy.TransactionType;
    }

    export interface IHasPolicyTransactionType {
        policyTransactionType: Policy.TransactionType;
    }

    export interface IHasStatus {
        status: Policy.Status;
    }

    export interface IHasPolicyNumber {
        number: string;
    }

    export interface IHasEffectiveDate {
        effectiveDate: Date;
    }

    export interface IHasCreatedDate {
        createdDate: Date;
    }

    export interface IHasLayerEffectiveDate {
        layerEffectiveDate?: Date;
    }

    interface CoverageWithAttribute extends IHasAttributes {
        coverageOptionId: CoverageOption.Id;
        limits?: Partial<IHasAttributes>[];
    }

    export interface IHasCoveragesWithAttributes {
        coverages: CoverageWithAttribute[];
    }
}

export type IPolicyRelations = Partial<{
    agent: boolean;
    accountManager: boolean;
    coverages: boolean;
    commissions: boolean;
    fees: boolean;
    attributes: boolean;
    additionalInterests: boolean;
    createdBy: boolean;
    expiredBy: boolean;
    ach: boolean;
    account:
        | boolean
        | {
              company: boolean;
          };
    customer: boolean;
    cost: boolean;
    invoices:
        | boolean
        | {
              Transactions?: boolean;
              GeneralLedgerTransactions?: boolean;
              ClientPremiums?: boolean;
          };
    statements:
        | boolean
        | {
              Header?: boolean;
          };
}>;
