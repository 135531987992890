/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
    addDays,
    addHours,
    addMilliseconds,
    addMinutes,
    addMonths,
    addSeconds,
    addYears,
    differenceInCalendarDays,
    differenceInCalendarMonths,
    endOfMonth,
    isAfter,
    isBefore,
    isEqual,
    isLeapYear,
    isWithinRange,
    subDays,
    subHours,
    subMilliseconds,
    subMinutes,
    subMonths,
    subSeconds,
    subYears,
} from "date-fns";

function zeroify(num: number, length = 2): string {
    const numberParts: number[] = new Array(length - String(num).length).fill(0);
    numberParts.push(num);
    return numberParts.join("");
}

enum ComparisonPrecision {
    YEAR = "YEAR",
    MONTH = "MONTH",
    DAY_OF_MONTH = "DAY_OF_MONTH",
}

export class DateTime extends Date {
    // ========================================================================
    /**
     * Calculates the totale months between two dates
     */
    static differenceInCalendarMonths(dateLeft: Date, dateRight: Date): number {
        return differenceInCalendarMonths(dateLeft, dateRight);
    }

    /**
     * Calculates the totale days between two dates
     */
    static differenceInCalendarDays(dateLeft: Date, dateRight: Date, options?: { includeEndDate?: boolean }): number {
        const diff = differenceInCalendarDays(dateLeft, dateRight);
        if (!options?.includeEndDate) return diff;
        return diff + 1;
    }

    static convert24HrTimeTo12HrTime(timeStr: string): string {
        const timeArr = Array.from(timeStr);
        if (timeArr.length === 3) {
            timeArr.unshift("0");
        }
        const timeFinalArr: [string, ":", string, string?] = [`${timeArr[0]}${timeArr[1]}`, ":", `${timeArr[2]}${timeArr[3]}`];
        const timeHours = parseInt(timeFinalArr[0]);

        if (timeHours < 12) {
            timeFinalArr.push("am");
            if (timeHours > 0 && timeHours < 10) {
                timeFinalArr[0] = timeFinalArr[0].slice(1);
            } else if (timeHours === 0) {
                timeFinalArr[0] = "12";
            }
        } else {
            timeFinalArr.push("pm");
            timeFinalArr[0] = timeHours === 12 ? "12" : String(timeHours - 12);
        }
        const timeParsed = timeFinalArr.join("");
        return timeParsed;
    }

    static ComparisonPrecision = ComparisonPrecision;
    static partition({
        start,
        end,
        options,
    }: {
        start: DateTime;
        end: DateTime;
        options: {
            duration?: {
                days?: number;
                months?: number;
                years?: number;
            };
            endExclusive?: boolean;
            monthly?: boolean;
            yearly?: boolean;
        };
    }): { start: DateTime; end: DateTime }[] {
        if (end.isBefore(start)) {
            throw new Error("Invalid partition range: end is before partition start");
        }

        const partitions: { start: DateTime; end: DateTime }[] = [];
        const lastPartition = (): undefined | { start: DateTime; end: DateTime } => {
            return partitions[partitions.length - 1];
        };
        let nextPart: { start: DateTime; end: DateTime };

        if (options.duration) {
            do {
                const lastPart = lastPartition();
                let partStart = lastPart ? DateTime.addDays(lastPart.end, 1) : start;
                if (lastPart && options.endExclusive) {
                    partStart = lastPart.end;
                }

                let endPart: DateTime;
                if (options.duration.days) {
                    endPart = DateTime.addDays(partStart, options.duration.days);
                } else if (options.duration.months) {
                    endPart = DateTime.addMonths(partStart, options.duration.months);
                } else if (options.duration.years) {
                    endPart = DateTime.addYears(partStart, options.duration.years);
                } else {
                    throw new Error("Partition duration invalid");
                }

                nextPart = {
                    start: partStart,
                    end: endPart.isBefore(end) ? endPart : end,
                };
                partitions.push(nextPart);
            } while (nextPart.end.isBefore(end));
        } else if (options.monthly) {
            do {
                const lastPart = lastPartition();
                let partStart = lastPart ? DateTime.addDays(lastPart.end, 1) : start;
                if (lastPart && options.endExclusive) {
                    partStart = lastPart.end;
                }
                const endPart = DateTime.last(partStart);

                nextPart = {
                    start: partStart,
                    end: endPart.isBefore(end) ? endPart : end,
                };
                partitions.push(nextPart);
            } while (nextPart.end.isBefore(end));
        } else if (options.yearly) {
            do {
                const lastPart = lastPartition();
                let partStart = lastPart ? DateTime.addDays(lastPart.end, 1) : start;
                if (lastPart && options.endExclusive) {
                    partStart = lastPart.end;
                }
                const endPart = DateTime.fromObject({
                    year: partStart.getFullYear(),
                    month: 12,
                    day: 31,
                });

                nextPart = {
                    start: partStart,
                    end: endPart.isBefore(end) ? endPart : end,
                };
                partitions.push(nextPart);
            } while (nextPart.end.isBefore(end));
        }

        return partitions;
    }

    // ========================================================================
    static sort(dates: DateTime[], direction: DateTime.SortDirection = "ASC"): DateTime[] {
        return dates.sort(DateTime.Sorter(direction));
    }

    // ========================================================================
    static Sorter(direction: DateTime.SortDirection = "ASC"): (date1: DateTime, date2: DateTime) => number {
        return (date1: DateTime, date2: DateTime): number => {
            if (date1.isBefore(date2)) return direction === "ASC" ? -1 : 1;
            if (date1.isAfter(date2)) return direction === "ASC" ? 1 : -1;
            return 0;
        };
    }

    static #monthAbbrRegExp = new RegExp(/jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec/, "gi");
    static #monthRegExp = new RegExp(/january|february|march|april|may|june|july|august|september|october|november|december/, "gi");
    // ========================================================================
    static get months(): [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ] {
        return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }

    // ========================================================================
    static isValidMonthAbbreviation(month: string): boolean {
        if (month.length !== 3) return null;
        return DateTime.months.map((m) => m.slice(0, 3).toUpperCase()).includes(month.toUpperCase());
    }

    // ========================================================================
    static getMonthDetails(month: string): { number: number; month: DateTime.Months; monthAbbr: DateTime.MonthAbbreviations } {
        const isAbbr = month.length === 3;

        if (isAbbr && !DateTime.isValidMonthAbbreviation(month)) {
            throw new Error(`Invalid month abbreviation: "${month}"`);
        }

        const monthAbbr = month.slice(0, 3);
        const monthNumber = DateTime.months.map((m) => m.slice(0, 3).toUpperCase()).findIndex((m) => m === monthAbbr.toUpperCase());
        if (monthNumber === -1) {
            throw new Error(`Invlid month: "${month}"`);
        }
        return {
            number: monthNumber + 1,
            month: DateTime.months[monthNumber],
            monthAbbr: DateTime.months[monthNumber].slice(0, 3) as DateTime.MonthAbbreviations,
        };
    }

    // ========================================================================
    static parseMonthAbbreviation(monthStr: string): DateTime.MonthAbbreviations | null {
        const month = DateTime.months.find((m) => m.toLowerCase().includes(monthStr.toLowerCase()));
        if (!month) return null;
        return month.slice(0, 3) as DateTime.MonthAbbreviations;
    }

    // ========================================================================
    static getMonthAbbreviation(date: Date): DateTime.MonthAbbreviations {
        const month = date.getUTCMonth();
        if (month < 1 && month > 12) {
            throw new Error(`Invalid month: ${month}`);
        }
        return DateTime.months[month].slice(0, 3) as DateTime.MonthAbbreviations;
    }

    // ========================================================================
    static isLeapYear(date: Date): boolean {
        return isLeapYear(date);
    }

    // ========================================================================
    static isToday(date: Date): boolean {
        const today = new Date();
        return date.getMonth() === today.getMonth() && date.getDate() === today.getDate() && date.getFullYear() === today.getFullYear();
    }

    // ========================================================================
    static compare(date: Date, dateToCompare: Date, precision: ComparisonPrecision): DateTime.IComparisonResult {
        const year1 = date.getUTCFullYear();
        const year2 = dateToCompare.getUTCFullYear();
        const year = {
            isAfter: year1 > year2,
            isBefore: year1 < year2,
            isEqual: year1 === year2,
        };
        if (precision === ComparisonPrecision.YEAR) {
            return year;
        }
        const month1 = date.getUTCMonth();
        const month2 = dateToCompare.getUTCMonth();
        const month = {
            isAfter: year.isAfter || (year.isEqual && month1 > month2),
            isBefore: year.isBefore || (year.isEqual && month1 < month2),
            isEqual: year.isEqual && month1 === month2,
        };
        if (precision === ComparisonPrecision.MONTH) {
            return month;
        }
        if (precision === ComparisonPrecision.DAY_OF_MONTH) {
            const day1 = date.getUTCDate();
            const day2 = dateToCompare.getUTCDate();
            const day = {
                isAfter: month.isAfter || (month.isEqual && day1 > day2),
                isBefore: month.isBefore || (month.isEqual && day1 < day2),
                isEqual: month.isEqual && day1 === day2,
            };

            return day;
        }
        throw new Error(`Invalid comparison precision: ${precision}`);
    }

    // ========================================================================
    static isAfter(date: Date, dateToCompare: Date, options?: { excludeTime?: boolean }): boolean {
        if (options?.excludeTime) {
            const yearComparison = DateTime.compare(date, dateToCompare, ComparisonPrecision.YEAR);
            if (yearComparison.isBefore) return false;
            if (yearComparison.isAfter) return true;

            const monthComparison = DateTime.compare(date, dateToCompare, ComparisonPrecision.MONTH);
            if (monthComparison.isBefore) return false;
            if (monthComparison.isAfter) return true;

            const dateComparison = DateTime.compare(date, dateToCompare, ComparisonPrecision.DAY_OF_MONTH);
            if (dateComparison.isBefore) return false;
            if (dateComparison.isAfter) return true;

            if (yearComparison.isEqual && monthComparison.isEqual && dateComparison.isEqual) return false;

            return false;
        }
        return isAfter(date, dateToCompare);
    }

    // ========================================================================
    static isAfterOrEqual(date: Date, dateToCompare: Date, options?: { excludeTime?: boolean }): boolean {
        return DateTime.isAfter(date, dateToCompare, options) || DateTime.isEqual(date, dateToCompare, options);
    }

    // ========================================================================
    static isBefore(date: Date, dateToCompare: Date, options?: { excludeTime?: boolean }): boolean {
        if (options?.excludeTime) {
            const yearComparison = DateTime.compare(date, dateToCompare, ComparisonPrecision.YEAR);
            if (yearComparison.isAfter) return false;
            if (yearComparison.isBefore) return true;

            const monthComparison = DateTime.compare(date, dateToCompare, ComparisonPrecision.MONTH);
            if (monthComparison.isAfter) return false;
            if (monthComparison.isBefore) return true;

            const dateComparison = DateTime.compare(date, dateToCompare, ComparisonPrecision.DAY_OF_MONTH);
            if (dateComparison.isAfter) return false;
            if (dateComparison.isBefore) return true;

            if (yearComparison.isEqual && monthComparison.isEqual && dateComparison.isEqual) return false;

            return false;
        }
        return isBefore(date, dateToCompare);
    }

    // ========================================================================
    static isBeforeOrEqual(date: Date, dateToCompare: Date, options?: { excludeTime?: boolean }): boolean {
        return DateTime.isBefore(date, dateToCompare, options) || DateTime.isEqual(date, dateToCompare, options);
    }

    // ========================================================================
    static isEqual(date: Date, dateToCompare: Date, options?: { excludeTime?: boolean }): boolean {
        if (options?.excludeTime) {
            if (date.getUTCDate() !== dateToCompare.getUTCDate()) return false;
            if (date.getUTCMonth() !== dateToCompare.getUTCMonth()) return false;
            if (date.getUTCFullYear() !== dateToCompare.getUTCFullYear()) return false;
            return true;
        }
        return isEqual(date, dateToCompare);
    }

    // ========================================================================
    static isNotEqual(date: Date, dateToCompare: Date, options?: { excludeTime?: boolean }): boolean {
        return !DateTime.isEqual(date, dateToCompare, options);
    }

    // ========================================================================
    static isBetween(
        date: Date,
        dateRangeStart: Date,
        dateRangeEnd: Date,
        options?: { excludeTime?: boolean; equalStart?: boolean; equalEnd?: boolean }
    ): boolean {
        const equalStart = options?.equalStart ?? true;
        const equalEnd = options?.equalEnd ?? true;
        const checkOne = equalStart
            ? DateTime.isAfterOrEqual(date, dateRangeStart, options)
            : DateTime.isAfter(date, dateRangeStart, options);
        const checkTwo = equalEnd ? DateTime.isBeforeOrEqual(date, dateRangeEnd, options) : DateTime.isBefore(date, dateRangeEnd, options);
        return checkOne && checkTwo;
    }

    // ========================================================================
    static #updateInstance(staleDate: Date, newDate: Date): void {
        staleDate.setTime(newDate.getTime());
    }

    // ========================================================================
    static subtractMonths(date: Date, amount: number): DateTime {
        return DateTime.fromDate(subMonths(date, amount));
    }

    // ========================================================================
    static subtractYears(date: Date, amount: number): DateTime {
        return DateTime.fromDate(subYears(date, amount));
    }

    // ========================================================================
    static subtractDays(date: Date, amount: number): DateTime {
        return DateTime.fromDate(subDays(date, amount));
    }

    // ========================================================================
    static subtractHours(date: Date, amount: number): DateTime {
        return DateTime.fromDate(subHours(date, amount));
    }

    // ========================================================================
    static subtractMinutes(date: Date, amount: number): DateTime {
        return DateTime.fromDate(subMinutes(date, amount));
    }

    // ========================================================================
    static subtractSeconds(date: Date, amount: number): DateTime {
        return DateTime.fromDate(subSeconds(date, amount));
    }

    // ========================================================================
    static subtractMilliseconds(date: Date, amount: number): DateTime {
        return DateTime.fromDate(subMilliseconds(date, amount));
    }

    // ========================================================================
    static addMonths(date: Date, amount: number): DateTime {
        return DateTime.fromDate(addMonths(date, amount));
    }

    // ========================================================================
    static addYears(date: Date, amount: number): DateTime {
        return DateTime.fromDate(addYears(date, amount));
    }

    // ========================================================================
    static addDays(date: Date, amount: number): DateTime {
        return DateTime.fromDate(addDays(date, amount));
    }

    // ========================================================================
    static addHours(date: Date, amount: number): DateTime {
        return DateTime.fromDate(addHours(date, amount));
    }

    // ========================================================================
    static addMinutes(date: Date, amount: number): DateTime {
        return DateTime.fromDate(addMinutes(date, amount));
    }

    // ========================================================================
    static addSeconds(date: Date, amount: number): DateTime {
        return DateTime.fromDate(addSeconds(date, amount));
    }

    // ========================================================================
    static addMilliseconds(date: Date, amount: number): DateTime {
        return DateTime.fromDate(addMilliseconds(date, amount));
    }

    // ========================================================================
    static parseString(dateString: string, yearPrefix?: number): DateTime {
        const date = new DateTime();
        date.setTime(DateTime.parse(dateString));
        if (yearPrefix) {
            const year = date.getUTCFullYear().toString();
            return DateTime.fromObject({
                day: date.getDate() as DateTime.DayOption,
                month: date.getMonthActual() as DateTime.MonthOption,
                year: Number(`${yearPrefix}${year[2]}${year[3]}`),
            });
        }

        return date;
    }

    // ========================================================================
    static parse(dateString: string): number {
        if (/^\d{4}-\d{2}-\d{2}$/g.test(dateString)) {
            return DateTime.fromString(dateString, "YYYY-MM-DD").getTime();
        }

        if (/^\d{1,2}-\d{1,2}-\d{2}$/g.test(dateString)) {
            return DateTime.fromString(dateString, "mm/dd/YY").getTime();
        }

        if (/^\d{1,2}-\d{1,2}-\d{4}$/g.test(dateString)) {
            return DateTime.fromString(dateString, "mm/dd/YYYY").getTime();
        }

        if (/^\d{8}$/g.test(dateString)) {
            return DateTime.fromString(dateString, "YYYYMMDD").getTime();
        }

        if (/^\d{2}\/\d{2}\/\d{2}$/g.test(dateString)) {
            return DateTime.fromString(dateString, "MM/DD/YY").getTime();
        }

        if (/^\d{2}\/\d{2}\/\d{4}$/g.test(dateString)) {
            return DateTime.fromString(dateString, "MM/DD/YYYY").getTime();
        }

        if (/^\d{1,2}-[JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC]{3}-\d{2}$/gi.test(dateString)) {
            return DateTime.fromString(dateString, "dd-MMM-YY").getTime();
        }

        if (/^\d{1,2}-[JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC]{3}-\d{4}$/gi.test(dateString)) {
            return DateTime.fromString(dateString, "DD-MMM-YYYY").getTime();
        }

        if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/g.test(dateString)) {
            return DateTime.fromString(dateString, "ISO").getTime();
        }

        return Date.parse(dateString);
    }

    // ========================================================================
    static convert2DigitYearTo4(year: string | number, relativeDate = new Date()): number {
        const targetYear = Number(year);
        if (String(targetYear).length === 4) return targetYear;

        const relativeCentury = Number(String(relativeDate.getFullYear()).substr(0, 2));
        const relativeYear = Number(String(relativeDate.getFullYear()).substr(2, 4));

        if (targetYear - relativeYear > 48) {
            return Number(`${relativeCentury - 1}${String(targetYear).length === 2 ? targetYear : `0${targetYear}`}`);
        }
        return Number(`${relativeCentury}${String(targetYear).length === 2 ? targetYear : `0${targetYear}`}`);
    }

    // ========================================================================
    static fromString(date: string, format: DateTime.StringFormats, options?: DateTime.FromStringOptions): DateTime {
        const resetTime = options?.resetTime ?? true;
        const dt = new DateTime();
        let year: string, month: string, day: string;

        if (!date) {
            throw new Error(`Invalid date string: "${date}"`);
        }

        if (format === "YYYY-MM-DD") {
            [year, month, day] = date.split("-");
        } else if (format === "mm/dd/YYYY") {
            [month, day, year] = date.split("/");
        } else if (format === "YYYYMMDD") {
            year = date.slice(0, 4);
            month = date.slice(4, 6);
            day = date.slice(6);
        } else if (format === "mm/dd/YY" || format === "MM/DD/YY") {
            [month, day, year] = date.split("/");
            year = options?.yearPrefix ? `${options?.yearPrefix}${year}` : String(DateTime.convert2DigitYearTo4(year));
        } else if (format === "MM/DD/YYYY") {
            [month, day, year] = date.split("/");
        } else if (format === "DD-MMM-YYYY" || format === "DD-Mmm-YYYY" || format === "dd-MMM-YYYY" || format === "dd-Mmm-YYYY") {
            [day, month, year] = date.split("-");
            month = DateTime.getMonthDetails(month).number.toString();
        } else if (format === "DD-MMM-YY" || format === "DD-Mmm-YY" || format === "dd-MMM-YY" || format === "dd-Mmm-YY") {
            [day, month, year] = date.split("-");
            month = DateTime.getMonthDetails(month).number.toString();
            year = options?.yearPrefix ? `${options?.yearPrefix}${year}` : String(DateTime.convert2DigitYearTo4(year));
        } else if (format === "ISO") {
            const [tempDate] = date.split("T");
            return DateTime.fromString(tempDate, "YYYY-MM-DD", options);
        } else {
            throw new Error("Unrecognized date format");
        }
        const yearNum = Number(year);
        const monthNum = Number(month) - 1;
        const dayNum = Number(day);
        if (monthNum < 0 || monthNum >= 12) {
            throw new Error(`Invalid date month: ${monthNum} (date: ${date})`);
        }
        if (dayNum < 1 || dayNum > 31) {
            throw new Error(`Invalid date day: ${dayNum} (date: ${date})`);
        }
        dt.setFullYear(yearNum, monthNum, dayNum);
        if (resetTime) {
            dt.setHours(0, 0, 0, 0);
        }
        return dt;
    }

    // ========================================================================
    /**
     * @returns A new instance of a Time object. Does NOT mutate the Time instance
     *  passed as an argument.
     */
    static last(date: DateTime): DateTime {
        const last = DateTime.fromObject({
            year: date.getFullYear(),
            month: date.getMonthActual() as DateTime.MonthOption,
            day: "last",
        });
        return last;
    }

    // ========================================================================
    /**
     * @returns A new instance of a Time object. Does NOT mutate the Time instance
     *  passed as an argument.
     */
    static first(date: DateTime): DateTime {
        const first = DateTime.fromObject({
            year: date.getFullYear(),
            month: date.getMonthActual() as DateTime.MonthOption,
            day: 1,
        });
        return first;
    }

    // ========================================================================
    static fromObject(
        {
            year,
            month,
            day,
        }: {
            year: number;
            month: DateTime.MonthOption;
            day: DateTime.DayOption;
        },
        options?: DateTime.InitOptions
    ): DateTime {
        const validMonth = typeof month === "string" ? DateTime.months.map((mo) => mo.slice(0, 3)).indexOf(month) : month - 1;

        const dt = new DateTime(year, validMonth, day === "last" ? 1 : day);
        if (day === "last") {
            DateTime.#updateInstance(dt, endOfMonth(dt));
        }
        const resetTime = options?.resetTime ?? true;
        if (resetTime) {
            dt.setHours(0, 0, 0, 0);
        }
        return dt;
    }

    // ========================================================================
    static get today(): DateTime {
        const dt = new DateTime();
        dt.setHours(0, 0, 0, 0);
        return dt;
    }

    // ========================================================================
    static fromDate(date: Date): DateTime {
        return new DateTime(date);
    }

    // ========================================================================
    static isBusinessDay(date: Date): boolean {
        const day = date.getDay();
        return day >= 1 && day < 6;
    }

    // ========================================================================
    static getLastBusinessDay(date: Date = new Date()): DateTime {
        const dt = new DateTime(date);
        const day = dt.getDay();
        if (day === 1) {
            // day is Monday => subtract 3 days
            dt.subtractDays(3);
        } else if (day === 0) {
            // day is Sunday => subtract 2 days
            dt.subtractDays(2);
        } else {
            // all other days subtract 1 day
            dt.subtractDays(1);
        }
        return dt;
    }

    // ========================================================================
    static getNextBusinessDay(date: Date = new Date()): DateTime {
        const dt = new DateTime(date);
        const day = dt.getDay();
        if (day === 5) {
            // day is Friday => add 3 days
            dt.addDays(3);
        } else if (day === 6) {
            // day is Saturday => add 2 days
            dt.addDays(2);
        } else {
            // all other days add 1 day
            dt.addDays(1);
        }
        return dt;
    }

    // ========================================================================
    static getDaysInMonth(month: DateTime.MonthNumbers, year: number): number {
        return DateTime.fromString(`${month}/1/${year}`, "mm/dd/YYYY").getDaysInMonth();
    }

    // ========================================================================
    #isFrozen = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(...args: any) {
        // @ts-expect-error ignore this
        super(...args);
    }

    // ========================================================================
    get isBusinessDay(): boolean {
        return DateTime.isBusinessDay(this);
    }

    // ========================================================================
    get isToday(): boolean {
        return DateTime.isToday(this);
    }

    // ========================================================================
    get isBeforeToday(): boolean {
        return DateTime.isBefore(this, DateTime.today);
    }

    // ========================================================================
    get isAfterToday(): boolean {
        return DateTime.isAfter(this, DateTime.today);
    }

    // ========================================================================
    getLastBusinessDay(): DateTime {
        return DateTime.getLastBusinessDay(this);
    }

    // ========================================================================
    getNextBusinessDay(): DateTime {
        return DateTime.getNextBusinessDay(this);
    }

    // ========================================================================
    isLeapYear(): boolean {
        return DateTime.isLeapYear(this);
    }

    // ========================================================================
    isAfter(date: Date, options?: { excludeTime?: boolean }): boolean {
        return DateTime.isAfter(this, date, options);
    }

    // ========================================================================
    isAfterOrEqual(date: Date, options?: { excludeTime?: boolean }): boolean {
        return DateTime.isAfterOrEqual(this, date, options);
    }

    // ========================================================================
    isBefore(date: Date, options?: { excludeTime?: boolean }): boolean {
        return DateTime.isBefore(this, date, options);
    }

    // ========================================================================
    isBeforeOrEqual(date: Date, options?: { excludeTime?: boolean }): boolean {
        return DateTime.isBeforeOrEqual(this, date, options);
    }

    // ========================================================================
    isEqual(date: Date, options?: { excludeTime?: boolean }): boolean {
        return DateTime.isEqual(this, date, options);
    }

    // ========================================================================
    isNotEqual(date: Date, options?: { excludeTime?: boolean }): boolean {
        return DateTime.isNotEqual(this, date, options);
    }

    // ========================================================================
    isBetween(
        dateRangeStart: Date,
        dateRangeEnd: Date,
        options?: { excludeTime?: boolean; equalStart?: boolean; equalEnd?: boolean }
    ): boolean {
        return DateTime.isBetween(this, dateRangeStart, dateRangeEnd, options);
    }

    // ========================================================================
    subtractMilliseconds(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.subtractMilliseconds(this, amount));
        return this;
    }

    // ========================================================================
    subtractSeconds(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.subtractSeconds(this, amount));
        return this;
    }

    // ========================================================================
    subtractMinutes(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.subtractMinutes(this, amount));
        return this;
    }

    // ========================================================================
    subtractHours(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.subtractHours(this, amount));
        return this;
    }

    // ========================================================================
    subtractDays(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.subtractDays(this, amount));
        return this;
    }

    // ========================================================================
    subtractMonths(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.subtractMonths(this, amount));
        return this;
    }

    // ========================================================================
    subtractYears(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.subtractYears(this, amount));
        return this;
    }

    // ========================================================================
    addMilliseconds(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.addMilliseconds(this, amount));
        return this;
    }

    // ========================================================================
    addSeconds(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.addSeconds(this, amount));
        return this;
    }

    // ========================================================================
    addMinutes(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.addMinutes(this, amount));
        return this;
    }

    // ========================================================================
    addHours(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.addHours(this, amount));
        return this;
    }

    // ========================================================================
    addDays(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.addDays(this, amount));
        return this;
    }

    // ========================================================================
    addMonths(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.addMonths(this, amount));
        return this;
    }

    // ========================================================================
    addYears(amount: number): DateTime {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        DateTime.#updateInstance(this, DateTime.addYears(this, amount));
        return this;
    }

    // ========================================================================
    /**
     *
     * @returns `super.getUTCMonth() + 1;`
     *
     * @example
     * ```
     * DateTime.fromString('1/1/2020', 'mm/dd/YYYY').getMonth()
     * // => 1
     * ```
     */
    getMonthActual(): number {
        return super.getUTCMonth() + 1;
    }

    // ========================================================================
    getMonthString({ abbreviate }: { abbreviate: boolean } = { abbreviate: false }): string {
        const month = DateTime.months[this.getUTCMonth()];
        return abbreviate ? month.slice(0, 3) : month;
    }

    // ========================================================================
    /**
     * Sets the day of the Time object to the last day of the month.
     *
     * @example
     * ```
     * DateTime.fromString('1/1/2020', 'mm/dd/YYYY').last()
     * // => 1/31/2020
     * ```
     */
    last(): this {
        DateTime.#updateInstance(this, DateTime.last(this));
        return this;
    }

    // ========================================================================
    /**
     * Sets the day of the Time object to the first day of the month.
     *
     * @example
     * ```
     * DateTime.fromString('1/31/2020', 'mm/dd/YYYY').first()
     * // => 1/1/2020
     * ```
     */
    first(): this {
        DateTime.#updateInstance(this, DateTime.first(this));
        return this;
    }

    // ========================================================================
    getHours12HourClock(): number {
        const hour = this.getHours();
        if (hour === 0) return 12;
        return hour <= 12 ? hour : hour - 12;
    }

    // ========================================================================
    /**
     * Returns a customized formatted date string
     *
     * Options:
     *  - Use DD or dd for the day
     *  - Use YY or YYYY for the year
     *  - Use MMMM or Mmmm for full month (e.g. August)
     *  - Use MMM or Mmm for month abbreviation (e.g. Aug)
     *  - Use MM or mm for month number
     *
     *
     * Examples:
     * 1. 'iso' or 'ISO' returns ISO formatted string
     * 2. 'ams' or 'AMS' return AMS layer formatted date (2024-01-01 00:00:00.000)
     * 3. Mmmm dd, YYYY => August 1, 1991
     * 4. dd-MMM-YY => 1-AUG-91
     * 5. mm/dd/YYYY => 8/1/1991
     * 6. MM/DD/YY => 08/01/91
     * 7. hh(am|pm) => 12pm
     * 8. hh(AM|PM) => 12AM
     * 9. MN/mn (minutes) => 01/1
     * 10. SS/ss (seconds) => 01/1
     * 11. SSS/sss (milliseconds) => 001/1
     */
    format(format: string): string {
        if (format.toLowerCase() === "iso") {
            return this.toISOString();
        }

        if (format.toLowerCase() === "ams") {
            const year = this.getUTCFullYear();
            const month = zeroify(this.getUTCMonth() + 1);
            const day = zeroify(this.getUTCDate());
            const seconds = zeroify(this.getUTCSeconds());

            return `${year}-${month}-${day} 00:00:${seconds}.000`;
        }

        if (format.includes("MMMM")) {
            format = format.replace("MMMM", this.getMonthString().toUpperCase());
        } else if (format.includes("Mmmm")) {
            format = format.replace("Mmmm", this.getMonthString());
        } else if (format.includes("MMM")) {
            format = format.replace("MMM", this.getMonthAbbreviation().toUpperCase());
        } else if (format.includes("Mmm")) {
            format = format.replace("Mmm", this.getMonthAbbreviation());
        } else if (format.includes("MM")) {
            format = format.replace("MM", zeroify(this.getMonthActual()));
        } else if (format.includes("mm")) {
            format = format.replace("mm", this.getMonthActual().toString());
        }

        if (format.includes("DD")) {
            format = format.replace("DD", zeroify(this.getUTCDate()));
        } else {
            format = format.replace("dd", this.getUTCDate().toString());
        }

        if (format.includes("YYYY")) {
            format = format.replace("YYYY", this.getUTCFullYear().toString());
        } else {
            format = format.replace("YY", this.getShortYear().toString());
        }

        if (format.includes("hh")) {
            format = format.replace("hh", this.getHours12HourClock().toString());
        } else if (format.includes("HH")) {
            format = format.replace("HH", zeroify(this.getHours()));
        }
        if (format.includes("mn")) {
            format = format.replace("mn", this.getMinutes().toString());
        } else if (format.includes("MN")) {
            format = format.replace("MN", zeroify(this.getMinutes()));
        }
        if (format.includes("ss")) {
            format = format.replace("ss", this.getSeconds().toString());
        } else if (format.includes("SS")) {
            format = format.replace("SS", zeroify(this.getSeconds()));
        }
        if (format.includes("sss")) {
            format = format.replace("sss", this.getMilliseconds().toString());
        } else if (format.includes("SSS")) {
            format = format.replace("SSS", zeroify(this.getMilliseconds(), 3));
        }

        if (format.includes("(am|pm)")) {
            format = format.replace("(am|pm)", this.getHours() >= 12 ? "pm" : "am");
        } else if (format.includes("(AM|PM)")) {
            format = format.replace("(AM|PM)", this.getHours() >= 12 ? "PM" : "AM");
        }

        return format;
    }

    // ========================================================================
    getMonthAbbreviation(): DateTime.MonthAbbreviations {
        return DateTime.getMonthAbbreviation(this);
    }

    // ========================================================================
    /**
     * Freezes the Date object
     */
    freeze(): this {
        this.#isFrozen = true;
        return this;
    }

    // ========================================================================
    isWithinMonthsOfDate(dateToCompare: DateTime, numMonthsWithin: number): boolean {
        return isWithinRange(this, DateTime.subtractMonths(dateToCompare, numMonthsWithin), dateToCompare);
    }

    // ========================================================================
    /**
     * Sets the numeric day-of-the-month value of the Date object using local DateTime.
     * @param date A numeric value equal to the day of the month.
     */
    setDate(date: number): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setDate(date);
    }

    // ========================================================================
    /**
     * Sets the year of the Date object using local DateTime.
     * @param year A numeric value for the year.
     * @param month A zero-based numeric value for the month (0 for January, 11 for December). Must be specified if numDate is specified.
     * @param date A numeric value equal for the day of the month.
     */
    setFullYear(year: number, month?: number | undefined, date?: number | undefined): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setFullYear(year, month, date);
    }

    // ========================================================================
    /**
     * Sets the hour value in the Date object using local DateTime.
     * @param hours A numeric value equal to the hours value.
     * @param min A numeric value equal to the minutes value.
     * @param sec A numeric value equal to the seconds value.
     * @param ms A numeric value equal to the milliseconds value.
     */
    setHours(hours: number, min?: number | undefined, sec?: number | undefined, ms?: number | undefined): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setHours(hours, min, sec, ms);
    }

    // ========================================================================
    /**
     * Sets the milliseconds value in the Date object using local DateTime.
     * @param ms A numeric value equal to the millisecond value.
     */
    setMilliseconds(ms: number): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setMilliseconds(ms);
    }

    // ========================================================================
    /**
     * Sets the minutes value in the Date object using local DateTime.
     * @param min A numeric value equal to the minutes value.
     * @param sec A numeric value equal to the seconds value.
     * @param ms A numeric value equal to the milliseconds value.
     */
    setMinutes(min: number, sec?: number | undefined, ms?: number | undefined): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setMinutes(min, sec, ms);
    }

    // ========================================================================
    /**
     * Sets the month value in the Date object using local DateTime.
     * @param month A numeric value equal to the month. The value for January is 0, and other month values follow consecutively.
     * @param date A numeric value representing the day of the month. If this value is not supplied, the value from a call to the getDate method is used.
     */
    setMonth(month: number, date?: number | undefined): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setMonth(month, date);
    }

    // ========================================================================
    /**
     * Sets the seconds value in the Date object using local DateTime.
     * @param sec A numeric value equal to the seconds value.
     * @param ms A numeric value equal to the milliseconds value.
     */
    setSeconds(sec: number, ms?: number | undefined): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setSeconds(sec, ms);
    }

    // ========================================================================
    /**
     * Sets the date and time value in the Date object.
     * @param time A numeric value representing the number of elapsed milliseconds since midnight, January 1, 1970 GMT.
     */
    setTime(time: number): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setTime(time);
    }

    // ========================================================================
    /**
     * Sets the numeric day of the month in the Date object using Universal Coordinated Time (UTC).
     * @param date A numeric value equal to the day of the month.
     */
    setUTCDate(date: number): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setUTCDate(date);
    }

    // ========================================================================
    /**
     * Sets the year value in the Date object using Universal Coordinated Time (UTC).
     * @param year A numeric value equal to the year.
     * @param month A numeric value equal to the month. The value for January is 0, and other month values follow consecutively. Must be supplied if numDate is supplied.
     * @param date A numeric value equal to the day of the month.
     */
    setUTCFullYear(year: number, month?: number | undefined, date?: number | undefined): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setUTCFullYear(year, month, date);
    }

    // ========================================================================
    /**
     * Sets the hours value in the Date object using Universal Coordinated Time (UTC).
     * @param hours A numeric value equal to the hours value.
     * @param min A numeric value equal to the minutes value.
     * @param sec A numeric value equal to the seconds value.
     * @param ms A numeric value equal to the milliseconds value.
     */
    setUTCHours(hours: number, min?: number | undefined, sec?: number | undefined, ms?: number | undefined): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setUTCHours(hours, min, sec, ms);
    }

    // ========================================================================
    /**
     * Sets the milliseconds value in the Date object using Universal Coordinated Time (UTC).
     * @param ms A numeric value equal to the millisecond value.
     */
    setUTCMilliseconds(ms: number): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setUTCMilliseconds(ms);
    }

    // ========================================================================
    /**
     * Sets the minutes value in the Date object using Universal Coordinated Time (UTC).
     * @param min Sets the minutes value in the Date object using Universal Coordinated Time (UTC).
     * @param min — A numeric value equal to the minutes value.
     * @param sec — A numeric value equal to the seconds value.
     * @param ms — A numeric value equal to the milliseconds value.
     */
    setUTCMinutes(min: number, sec?: number | undefined, ms?: number | undefined): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setUTCMinutes(min, sec, ms);
    }

    // ========================================================================
    /**
     * Sets the month value in the Date object using Universal Coordinated Time (UTC).
     * @param month — A numeric value equal to the month. The value for January is 0, and other month values follow consecutively.
     * @param date — A numeric value representing the day of the month. If it is not supplied, the value from a call to the getUTCDate method is used.
     */
    setUTCMonth(month: number, date?: number | undefined): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setUTCMonth(month, date);
    }

    // ========================================================================
    /**
     * Sets UTC milliseconds, seconds, minutes, and hours to 0.
     */
    clearUTCTime(): this {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        super.setUTCMilliseconds(0);
        super.setUTCSeconds(0);
        super.setUTCMinutes(0);
        super.setUTCHours(0);
        return this;
    }

    // ========================================================================
    /**
     * Sets the seconds value in the Date object using Universal Coordinated Time (UTC).
     * @param sec — A numeric value equal to the seconds value.
     * @param ms — A numeric value equal to the milliseconds value.
     */
    setUTCSeconds(sec: number, ms?: number | undefined): number {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        return super.setUTCSeconds(sec, ms);
    }

    // ========================================================================
    getShortYear(): number {
        return Number(String(this.getUTCFullYear()).substr(2, 2));
    }

    // ========================================================================
    getDaysInMonth(): number {
        const month = this.getMonth();
        if (month === 1 && isLeapYear(this)) {
            return 29;
        }
        return daysInMonthMap.get(month);
    }

    // ========================================================================
    /**
     * Determines if the date is in the first half of the month.
     * Includes the half delimeter as the first half.
     * @returns {boolean}
     */
    isFirstHalfOfMonth(): boolean {
        const halfDelimeter = Math.floor(this.getDaysInMonth() / 2);
        return this.getDate() <= halfDelimeter;
    }

    // ========================================================================
    /**
     * Determines if the date is in the first half of the month.
     * Excludes the half delimeter as the first half.
     * @returns {boolean}
     */
    isSecondHalfOfMonth(): boolean {
        return !this.isFirstHalfOfMonth();
    }

    // ========================================================================
    /**
     * Compare with another date to specified precision point.
     * @param date — The date to compare against.
     * @param precision — The precision level of the comparison.
     */
    compare(date: Date, precision: ComparisonPrecision): DateTime.IComparisonResult {
        return DateTime.compare(this, date, precision);
    }

    // ========================================================================
    /**
     * Removes the timezone offset from the date.
     */
    removeTimezoneOffset(): this {
        const offsetHours = this.getUTCHours() - this.getTimezoneOffset() / 60;
        this.setUTCHours(offsetHours);
        return this;
    }

    // ========================================================================
    /**
     * Resets the time to all zeros.
     */
    resetTime(): this {
        if (this.#isFrozen) {
            throw new Error("Time object is frozen");
        }
        this.setHours(0, 0, 0, 0);
        return this;
    }

    // ========================================================================
    /**
     * Calculates the totale months between two dates
     */
    differenceInCalendarMonths(date: Date): number {
        return DateTime.differenceInCalendarMonths(this, date);
    }

    // ========================================================================
    /**
     * Calculates the totale days between two dates
     */
    differenceInCalendarDays(date: Date, options?: { includeEndDate?: boolean }): number {
        return DateTime.differenceInCalendarDays(this, date, options);
    }
}

const daysInMonthMap = new Map([
    [0, 31],
    [1, 28],
    [2, 31],
    [3, 30],
    [4, 31],
    [5, 30],
    [6, 31],
    [7, 31],
    [8, 30],
    [9, 31],
    [10, 30],
    [11, 31],
]);

// ========================================================================
export namespace DateTime {
    export interface IComparisonResult {
        isAfter: boolean;
        isBefore: boolean;
        isEqual: boolean;
    }
    export type SortDirection = "ASC" | "DESC";

    export type StringFormats =
        | "YYYY-MM-DD"
        | "mm/dd/YYYY"
        | "MM/DD/YYYY"
        | "YYYYMMDD"
        | "mm/dd/YY"
        | "MM/DD/YY"
        | "DD-MMM-YY"
        | "dd-MMM-YY"
        | "dd-Mmm-YY"
        | "DD-Mmm-YY"
        | "DD-MMM-YYYY"
        | "dd-MMM-YYYY"
        | "dd-Mmm-YYYY"
        | "DD-Mmm-YYYY"
        | "ISO";

    export interface InitOptions {
        resetTime?: boolean;
    }
    export interface FromStringOptions extends InitOptions {
        yearPrefix?: number;
    }

    export type MonthAbbreviations = "Jan" | "Feb" | "Mar" | "Apr" | "May" | "Jun" | "Jul" | "Aug" | "Sep" | "Oct" | "Nov" | "Dec";
    export type Months =
        | "January"
        | "February"
        | "March"
        | "April"
        | "May"
        | "June"
        | "July"
        | "August"
        | "September"
        | "October"
        | "November"
        | "December";

    export type MonthNumbers = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    export type MonthOption = MonthAbbreviations | MonthNumbers;
    export type DayOption =
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 18
        | 19
        | 20
        | 21
        | 22
        | 23
        | 24
        | 25
        | 26
        | 27
        | 28
        | 29
        | 30
        | 31
        | "last";

    /**
    export abstract class Format {
        static Factory(format: DateTime.StringFormats): Format {
            switch (format) {
                case 'DD-MMM-YY':
                    return new FormatDD_MMM_YY();
                default:
                    throw new Error('Format not supported');
            }
        }
        
        constructor(format: DateTime.StringFormats) {
            return Format.Factory(format);
        }

        abstract parse(date: string): DateTime;
        abstract format(date: DateTime): string;
    }

    class FormatDD_MMM_YY extends Format {
        constructor() {
            super('DD-MMM-YY');
        }

        parse(date: string): DateTime {
            const dt = new DateTime();

            const [day, month, year] = date.split("-");

            const yearNum = Number(year);
            const monthNum = Number(DateTime.getMonthDetails(month).number.toString()) - 1;
            const dayNum = Number(day);
            if (monthNum < 0 || monthNum >= 12) {
                throw new Error(`Invalid date month: ${monthNum} (date: ${date})`);
            }
            if (dayNum < 1 || dayNum > 31) {
                throw new Error(`Invalid date day: ${dayNum} (date: ${date})`);
            }
            dt.setFullYear(yearNum, monthNum, dayNum);
            dt.setHours(0, 0, 0, 0);

            return dt;
        }

        format(date: DateTime): string {
            const year = date.getFullYear().toString().slice(2);
            const month = DateTime.months[date.getMonth()].slice(0, 3).toUpperCase();
            const day: string | number = zeroify(date.getDate());

            return `${day}-${month}-${year}`;
        }
    }
    */
}

/**

    // ========================================================================
    static fromStringCustom(date: string, format: string, options?: DateTime.FromStringOptions): DateTime {
        const resetTime = options?.resetTime ?? true;

        
        const dt = new DateTime();
        let month: string, year: string, day: string;

        if (format.toLowerCase().includes("mmmm")) {
            const lcFormat = format.toLowerCase();

            const dayFormatStart = lcFormat.indexOf("dd");
            const monthFormatStart = lcFormat.indexOf("mmmm");
            const yearFormatStart = lcFormat.indexOf("yy");
            
            const monthAbbr = date.substr(monthFormatStart, 3);
            const monthDetails = DateTime.getMonthDetails(monthAbbr);
            
            
            const dayStart = dayFormatStart < monthFormatStart ? dayFormatStart : dayFormatStart + monthDetails.month.length - 4;

            const is2DigitDay = format.includes("DD") || /[\d]/gi.test(date.substr(dayStart + 1, 1));
            day = date.substr(dayStart, is2DigitDay ? 2 : 1);

            month = monthDetails.number.toString();

            const yearLength = lcFormat.includes("yyyy") ? 4 : 2;

            year = date.substr(
                yearFormatStart < monthFormatStart ? yearFormatStart : yearFormatStart + monthDetails.month.length - (is2DigitDay ? 4 : 5),
                yearLength
            );

            console.log({
                day,
                month,
                year,
                dayStart,
                is2DigitDay,
                dayFormatStart,
                yearFormatStart,
                monthFormatStart,
                monthDetails,
                monthLength: monthDetails.month.length,
                start: yearFormatStart < monthFormatStart ? yearFormatStart : yearFormatStart + monthDetails.month.length - 4,
                yearLength,
                math: `${yearFormatStart} + ${monthDetails.month.length} - 4 = ${yearFormatStart + monthDetails.month.length - 4}`,
            });
        } // else if (format.includes("Mmmm")) {
        //     format = format.replace("Mmmm", this.getMonthString());
        // } else if (format.includes("MMM")) {
        //     format = format.replace("MMM", this.getMonthAbbreviation().toUpperCase());
        // } else if (format.includes("Mmm")) {
        //     format = format.replace("Mmm", this.getMonthAbbreviation());
        // } else if (format.includes("MM")) {
        //     format = format.replace("MM", zeroify(this.getMonthActual()));
        // } else if (format.includes("mm")) {
        //     format = format.replace("mm", this.getMonthActual().toString());
        // }

        if (!day) {
            if (format.includes("DD")) {
                day = date.substr(format.indexOf("DD"), 2);
            } //else {
            //     format = format.replace("dd", this.getDate().toString());
            // }
        }

        if (!year) {
            if (format.includes("YYYY")) {
                year = date.substr(format.indexOf("YYYY"), 4);
            } // else {
            //     format = format.replace("YY", this.getShortYear().toString());
            // }
        }

        // return format;

        const yearNum = Number(year);
        const monthNum = Number(month) - 1;
        const dayNum = Number(day);
        if (monthNum < 0 || monthNum >= 12) {
            throw new Error(`Invalid date month: ${monthNum} (date: ${date})`);
        }
        if (dayNum < 1 || dayNum > 31) {
            throw new Error(`Invalid date day: ${dayNum} (date: ${date})`);
        }
        dt.setFullYear(yearNum, monthNum, dayNum);
        if (resetTime) {
            dt.setHours(0, 0, 0, 0);
        }
        return dt;

        // console.warn(`Format "${format}" not supported`);
        // return this.toISOString();
    }
 */
