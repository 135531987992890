import { Expose } from "class-transformer";
import { IsArray, IsBoolean, IsDateString, IsInt, IsOptional, IsString, Length, Min, ValidateBy } from "class-validator";
import { Driver, GlobalCertificateHolder } from "../northstar";
import { EquipmentDriverChangeDto } from "./Equipment";

export type DriverRow = Omit<Driver, "exp" | "doh" | "ownerOperator">;

export class DriverDto extends EquipmentDriverChangeDto {
    @Expose()
    @IsString()
    name: string;

    @Expose()
    @IsString()
    @Length(2, 2)
    state: string;

    @Expose()
    @IsString()
    @Length(0, 25)
    license: string;

    @Expose()
    @IsString()
    @IsDateString()
    @Length(10, 10)
    dob: string;

    @Expose()
    @IsString()
    @IsDateString()
    @Length(10, 10)
    doh: string;

    @Expose()
    @IsOptional()
    @IsInt()
    @Min(0)
    exp: number;

    @Expose()
    @IsBoolean()
    ownerOperator: boolean;

    @Expose()
    @IsBoolean()
    wpUserConfirmationMeetsGuidelines: boolean;

    @Expose()
    @IsOptional()
    @IsArray()
    @ValidateBy({
        name: "isArrayOfIDs",
        validator: { validate: (value: object[]) => value.every((x) => "id" in x), defaultMessage: () => "Each item must have an id" },
    })
    tractors: { id: number }[];

    @Expose()
    @IsOptional()
    @IsArray()
    @ValidateBy({
        name: "isArrayOfIDs",
        validator: { validate: (value: object[]) => value.every((x) => "id" in x), defaultMessage: () => "Each item must have an id" },
    })
    trailers: { id: number }[];

    @Expose()
    @IsOptional()
    @ValidateBy({
        name: "isIntOrHolderObject",
        validator: {
            validate: (value: unknown) =>
                (typeof value === "number" && value === Math.floor(value)) ||
                (typeof value === "object" &&
                    "name" in value &&
                    "street" in value &&
                    "city" in value &&
                    "state" in value &&
                    "zip" in value),
        },
    })
    employer: number | Pick<GlobalCertificateHolder, "name" | "street" | "street2" | "city" | "state" | "zip" | "email" | "fax"> | null;
}
