import { FMCSA } from "@deathstar/types";
import { DateTime } from "@deathstar/types/util";
import { Calculation } from "../Calculation";
import { Data } from "../Data";
import { MotorCarrier } from "../MotorCarrier";
import { TimeWeight } from "../TimeWeight/TimeWeight";
import { Unit } from "../Unit/Unit";

export class Crash extends Data<Crash.Raw> implements Calculation.HasTimeWeight {
    // ========================================================================
    static new(carrier: MotorCarrier, rawCrash: Crash.Raw): Crash {
        return new Crash(carrier, rawCrash);
    }

    // ========================================================================
    #date: DateTime;
    #carrier: MotorCarrier;
    private constructor(carrier: MotorCarrier, raw: Crash.Raw) {
        super({
            carrier,
            raw,
        });
        this.#carrier = carrier;
        this.#date = DateTime.fromString(this.get("ReportDate"), "ISO");
    }

    // ========================================================================
    get unit(): Unit | undefined {
        const vin = this.get("UnitVin");
        const license = this.get("UnitLicense");
        if (!vin) return undefined;
        return (
            this.#carrier.units.find({
                vin,
                license,
            }) ||
            Unit.new({
                carrier: this.carrier,
                fmcsa: {
                    license: this.get("UnitLicense"),
                    make: undefined,
                    number: undefined,
                    type: undefined,
                },
                rawUnit: {
                    VIN: vin,
                },
                units: this.carrier.units,
                vin: vin,
            })
        );
    }

    // ========================================================================
    get id(): string {
        return `${this.get("ReportNumber")}-${this.get("UnitVin")}`;
    }

    // ========================================================================
    get date(): DateTime {
        return this.#date;
    }

    // ========================================================================
    get isValid(): boolean {
        return this.get("IsValid");
    }

    // ========================================================================
    get carrier(): MotorCarrier {
        return this.#carrier;
    }

    // ========================================================================
    get county(): string | undefined {
        return this.get("CountyName")
            ?.toLowerCase()
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    }

    // ========================================================================
    #timeWeightMap = new Map<string, number>();
    /**
     * The Time Weight of the Crash
     */
    getTimeWeight(date = this.carrier.dateRange.to): number {
        if (this.#timeWeightMap.has(date.format("YYYYMMDD"))) {
            return this.#timeWeightMap.get(date.format("YYYYMMDD"))!;
        }
        const timeWeight = TimeWeight.of({
            targetDate: date,
            inspectionDate: this.#date,
        });
        this.#timeWeightMap.set(date.format("YYYYMMDD"), timeWeight);
        return timeWeight;
    }

    /**
     * The Total Weight of the Crash
     */
    getTotalWeight({ timeWeightDate } = { timeWeightDate: this.carrier.dateRange.to }): number {
        return this.severityWeight * this.getTimeWeight(timeWeightDate);
    }

    // ========================================================================
    get severityWeight(): number {
        return this.get("SeverityWeight");
    }

    // ========================================================================
    /**
     * Only crashes that are listed as preventable, and include at least one of the following
     * - Fatality
     * - Unjury
     * - Vehicle Tow
     */
    get isIncludedInSms(): boolean {
        return (
            this.get("Preventable") !== false && (this.get("TotalFatalities") > 0 || this.get("TotalInjuries") > 0 || this.get("TowAway"))
        );
    }

    // ========================================================================
    /**
     * If the Crash is included in the latest BASIC scores (based on the applicable date range)
     */
    get isScored(): boolean {
        const isWithinDateRange = this.#date.isAfter(DateTime.subtractDays(this.#carrier.dateRange.to, 365 * 2));
        if (isWithinDateRange && this.isIncludedInSms) return true;
        return false;
    }

    // ========================================================================
    raw(): Crash.Raw {
        return super.json();
    }
}

// ========================================================================
export namespace Crash {
    export interface Options {
        carrier: MotorCarrier;
    }

    export interface Raw {
        CrashId?: number;
        DotNumber: number;
        ReportNumber: string;
        ReportDate: string;
        ReportTime?: string;
        Location?: string;
        City?: string;
        CityCode?: string;
        CountyCode?: number;
        CountyName?: string;
        CountyLatitude?: string;
        CountyLongitude?: string;
        ReportState: string;
        TotalFatalities: number;
        TotalInjuries: number;
        TowAway: boolean;
        HazmatReleased: boolean;
        Trafficway: FMCSA.Trafficway;
        RoadAccessControl: FMCSA.RoadAccessControl;
        RoadSurfaceCondition: FMCSA.RoadSurfaceCondition;
        WeatherCondition: FMCSA.WeatherCondition;
        LightCondition: FMCSA.LightCondition;
        UnitVin: string;
        UnitLicense: string;
        UnitLicenseState: string;
        UnitType?: FMCSA.VehicleType;
        GrossUnitWeightRating?: FMCSA.GrossVehicleWeightRating;
        VehicleConfiguration?: FMCSA.VehicleConfiguration;
        CargoBodyType?: FMCSA.CargoBodyType;
        UnitHazmatPlacard?: boolean;
        SeverityWeight: number;
        CitationIssueDescription: string;
        Preventable: boolean | null;
        TotalVehiclesInAccident?: number;
        ReportingAgency?: string;
        FederalRecordable?: boolean;
        StateRecordable?: boolean;
        SequenceNumber: number;
        ReportSequenceNumber: number;
        IsValid: boolean;
        DateUpdated: Date;
        DateAdded: Date;
    }
}
