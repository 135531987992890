import { FMCSA } from "@deathstar/types";
import { DateTime } from "@deathstar/types/util";
import type { MotorCarrier } from "../../../MotorCarrier";
import type { Violation } from "../../../Violation/Violation";
import { Violations } from "../../Violations";
import type { Breakdown } from "../Breakdown";
import { BASIC } from "./BASIC";

export class Hazmat extends BASIC<Hazmat.GroupNames, Hazmat.SubGroupNames> {
    constructor(breakdown: Breakdown, carrier: MotorCarrier, date: DateTime, vmViolations: Violations) {
        if (!Array.from(vmViolations).every((viol) => viol.basic === FMCSA.BasicName.HAZMAT)) {
            throw new Error("Not all violations are Hazmat violations");
        }

        const violations: Record<string, Violation[]> = {
            route: [],
            fire: [],
            packageTesting: [],
            markings: [],
            loadSecurement: [],
            cargoProtection: [],
            packageIntegrity: [],
            documentation: [],
            other: [],
        };

        Array.from(vmViolations).forEach((violation) => {
            const groupDescription = violation.get("GroupDescription");

            switch (groupDescription) {
                case "HM Route":
                    violations.route.push(violation);
                    break;
                case "Fire Hazard - HM":
                    violations.fire.push(violation);
                    break;
                case "Package Testing - HM":
                    violations.packageTesting.push(violation);
                    break;
                case "Markings - HM":
                    violations.markings.push(violation);
                    break;
                case "Load Securement - HM":
                    violations.loadSecurement.push(violation);
                    break;
                case "Cargo Protection - HM":
                    violations.cargoProtection.push(violation);
                    break;
                case "Package Integrity - HM":
                    violations.packageIntegrity.push(violation);
                    break;
                case "Documentation - HM":
                    violations.documentation.push(violation);
                    break;
                default:
                    violations.other.push(violation);
                    break;
            }
        });

        super(breakdown, carrier, date, vmViolations, [
            {
                name: "Route",
                subGroups: [{ name: "Wrong Route", violations: Violations.of(carrier, violations.route) }],
            },
            {
                name: "Preventative Measures",
                subGroups: [
                    { name: "Fire Hazard", violations: Violations.of(carrier, violations.fire) },
                    { name: "Package Testing", violations: Violations.of(carrier, violations.packageTesting) },
                    { name: "Markings", violations: Violations.of(carrier, violations.markings) },
                ],
            },
            {
                name: "Cargo",
                subGroups: [
                    { name: "Load Securement", violations: Violations.of(carrier, violations.loadSecurement) },
                    { name: "Cargo Protection", violations: Violations.of(carrier, violations.cargoProtection) },
                    { name: "Package Integrity", violations: Violations.of(carrier, violations.packageIntegrity) },
                ],
            },
            {
                name: "Other",
                subGroups: [
                    { name: "Documentation", violations: Violations.of(carrier, violations.documentation) },
                    { name: "Other", violations: Violations.of(carrier, violations.other) },
                ],
            },
        ]);
    }

    // ========================================================================
    get(groupName: "Route"): Breakdown.Group<Hazmat.GroupNames, Hazmat.SubGroupNames.Route>;
    get(groupName: "Preventative Measures"): Breakdown.Group<Hazmat.GroupNames, Hazmat.SubGroupNames.PreventativeMeasures>;
    get(groupName: "Cargo"): Breakdown.Group<Hazmat.GroupNames, Hazmat.SubGroupNames.Cargo>;
    get(groupName: "Other"): Breakdown.Group<Hazmat.GroupNames, Hazmat.SubGroupNames.Other>;
    get(groupName: Hazmat.GroupNames): Breakdown.Group<Hazmat.GroupNames, Hazmat.SubGroupNames> {
        return super.get(groupName);
    }

    // ========================================================================
    getHistory(): Hazmat[] {
        return this.breakdown.getHistory().hazmat;
    }
}

export namespace Hazmat {
    export type GroupNames = "Route" | "Preventative Measures" | "Cargo" | "Other";

    export namespace SubGroupNames {
        export type Route = "Wrong Route";

        export type PreventativeMeasures = "Fire Hazard" | "Package Testing" | "Markings";

        export type Cargo = "Load Securement" | "Cargo Protection" | "Package Integrity";

        export type Other = "Documentation" | "Other";
    }

    export type SubGroupNames = SubGroupNames.Route | SubGroupNames.PreventativeMeasures | SubGroupNames.Cargo | SubGroupNames.Other;
}
