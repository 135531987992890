import { FMCSA } from "@deathstar/types";
import { DateTime } from "@deathstar/types/util";
import { MotorCarrier } from "../MotorCarrier";
import { SmsResult } from "./SmsResult";

export class SmsResults {
    // ========================================================================
    static from(smsResults: SmsResults, recordDate: Date): SmsResults {
        const newSmsResults = new SmsResults(smsResults.#carrier);
        const desiredResult = smsResults.#results.find((result) => {
            if (result.recordDate.isEqual(recordDate)) return result;
            return null;
        });
        if (desiredResult) {
            newSmsResults.#results = [desiredResult];
        }
        return newSmsResults;
    }

    // ========================================================================
    /**
     * Async constructor
     */
    static async new(carrier: MotorCarrier, dates: Date[], rawSmsData: MotorCarrier.Raw.SmsData[]): Promise<SmsResults> {
        const smsResults = new SmsResults(carrier);

        const smsResultPromises: Promise<SmsResult>[] = [];
        dates
            .map((d) => DateTime.fromDate(d))
            .forEach((date) => {
                const recordDate = date.last().freeze();
                const snapshotDate = MotorCarrier.getSnapshotDateFromRecordDate(carrier, recordDate).freeze();
                const rawData = rawSmsData.find((m) => {
                    return DateTime.isEqual(new Date(m.Date), recordDate, { excludeTime: true });
                });

                smsResultPromises.push(
                    SmsResult.new({
                        recordDate,
                        snapshotDate,
                        carrier,
                        rawSmsData: rawData || null,
                    })
                );
            });
        const results = await Promise.all(smsResultPromises);

        results.forEach((result) => {
            smsResults.#results.push(result);
        });

        return smsResults;
    }

    // ========================================================================
    #carrier: MotorCarrier;
    #results: SmsResult[] = [];
    private constructor(carrier: MotorCarrier) {
        this.#carrier = carrier;
    }

    // ========================================================================
    *[Symbol.iterator](): IterableIterator<SmsResult> {
        for (const smsResult of this.#results) {
            yield smsResult;
        }
    }

    // ========================================================================
    get total(): number {
        return this.#results.length;
    }

    // ========================================================================
    get latest(): SmsResult {
        const sorted = this.#results.sort((v1, v2) => {
            if (v1.recordDate.isBefore(v2.recordDate)) {
                return 1;
            }
            if (v1.recordDate.isAfter(v2.recordDate)) {
                return -1;
            }
            return 0;
        });
        return sorted[0];
    }

    // ========================================================================
    getByDate(date: DateTime | Date | string): SmsResult | undefined {
        const dateStr =
            date instanceof DateTime
                ? date.format("YYYYMMDD")
                : date instanceof Date
                ? DateTime.fromDate(date).format("YYYYMMDD")
                : new DateTime(date).format("YYYYMMDD");
        return this.#results.find((result) => {
            if (result.recordDate.format("YYYYMMDD") === dateStr) return result;
            return null;
        });
    }

    // ========================================================================
    json(): SmsResult.JSON[] {
        return this.#results.map((result) => result.json());
    }

    // ========================================================================
    sortByDate(order: "ASC" | "DESC" = "DESC"): this {
        this.#results = this.#results.sort((v1, v2) => {
            if (v1.recordDate.isBefore(v2.recordDate)) {
                return order === "ASC" ? -1 : 1;
            }
            if (v1.recordDate.isAfter(v2.recordDate)) {
                return order === "ASC" ? 1 : -1;
            }
            return 0;
        });
        return this;
    }
}

// ========================================================================
export namespace SmsResults {
    export interface Raw {
        Date: string;
        ControlledSubstanceMeasure: number;
        ControlledSubstanceScore: number;
        CrashIndicatorMeasure: number;
        CrashIndicatorScore: number;
        DriverFitnessMeasure: number;
        DriverFitnessScore: number;
        HazmatMeasure: number;
        HazmatScore: number;
        HoursOfServiceMeasure: number;
        HoursOfServiceScore: number;
        UnsafeDrivingMeasure: number;
        UnsafeDrivingScore: number;
        VehicleMaintenanceMeasure: number;
        VehicleMaintenanceScore: number;
        IssAlgorithm: FMCSA.IssAlgorithm | null;
        IssGroupNumber: FMCSA.IssGroup.Id | null;
        IssScore: number | null;
    }
}
