import { CoverageOption, Driver, MonthlyReportDataUnit, Tractor, Trailer } from "@deathstar/types/northstar";
import { DriverRow, OOProgramPolicyStatus } from "@deathstar/types/waypoint";
import { Alert, InputField, Popover, PrimaryButton, SecondaryButton, useSnackbar } from "@deathstar/ui";
import {
    ArrowRightCircleIcon,
    BuildingOfficeIcon,
    CheckCircleIcon,
    CheckIcon,
    EllipsisVerticalIcon,
    MinusCircleIcon,
    PencilSquareIcon,
    PlusCircleIcon,
    ShieldCheckIcon,
    TruckIcon,
} from "@heroicons/react/24/outline";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { AccessorColumnDef, CellContext, DisplayColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import { AccountIdProvider } from "../../api/useAccountId";
import { Dialog, DialogProps } from "../../components/dialog/Dialog";
import { stringifyAddress } from "../certificates/certificatesUtil";
import equipmentColumns from "../equipment/EquipmentColumns";
import PolicyViewDialog from "../policies/policy-view/PolicyViewDialog";
import OOProgramInviteForm from "./OOProgramInviteForm";

export interface DriverTableMeta {
    openTractorLinker(driverId: number): void;
    openTrailerLinker(driverId: number): void;
    openEmployerLinker(driverId: number): void;
    companyName?: string;
    policyId?: string;
}

const mobileCell: DisplayColumnDef<DriverRow> = {
    id: "mobile-only",
    cell: function MobileDriverCell(info) {
        const meta = info.table.options.meta as DriverTableMeta;
        const row = info.row.original;

        const { data: allTractors } = api.equipment.useFindTractors(row.accountId);
        const { data: allTrailers } = api.equipment.useFindTrailers(row.accountId);

        const [changeDialogOpen, setChangeDialogOpen] = useState(false);
        const [removalDialogOpen, setRemovalDialogOpen] = useState(false);

        const tractors = row.tractors?.map((t1) => allTractors?.find((t2) => t1.id === t2.id)).filter(Boolean) as Tractor[];
        const trailers = row.trailers?.map((t1) => allTrailers?.find((t2) => t1.id === t2.id)).filter(Boolean) as Trailer[];

        return (
            <>
                <Popover>
                    <Popover.Content>
                        <Popover.Item
                            onClick={() => {
                                setChangeDialogOpen(true);
                            }}
                            disabled={info.row.original.requiresApproval}
                        >
                            <PencilSquareIcon className="h-5 w-5" />
                            <span>Edit{info.row.original.requiresApproval ? " (pending)" : ""}</span>
                        </Popover.Item>
                        <Popover.Item
                            onClick={() => {
                                meta.openTractorLinker(info.row.original.id);
                            }}
                        >
                            <TruckIcon className="h-5 w-5" />
                            <span>Change tractor</span>
                        </Popover.Item>
                        <Popover.Item
                            onClick={() => {
                                meta.openTrailerLinker(info.row.original.id);
                            }}
                        >
                            <TruckIcon className="h-5 w-5" />
                            <span>Change trailer</span>
                        </Popover.Item>
                        <Popover.Item
                            onClick={() => {
                                meta.openEmployerLinker(info.row.original.id);
                            }}
                        >
                            <BuildingOfficeIcon className="h-5 w-5" />
                            <span>Change employer</span>
                        </Popover.Item>
                        <Popover.Item
                            disabled={info.row.original.requiresApproval}
                            onClick={() => {
                                setRemovalDialogOpen(true);
                            }}
                        >
                            <MinusCircleIcon className="h-5 w-5" />
                            <span>Remove</span>
                        </Popover.Item>
                    </Popover.Content>
                    <Popover.Button className="w-full space-y-1 text-left text-stone-600">
                        <div className="flex justify-between gap-2">
                            <p className="line-clamp-1 font-medium text-stone-800">{row.name}</p>
                            {row.requiresApproval && (
                                <p className="whitespace-nowrap rounded-full bg-yellow-50 px-2 text-yellow-700">
                                    Pending {row.coverageOptions?.length ? "change" : "addition"}
                                </p>
                            )}
                        </div>
                        <p className="tabular-nums">{row.license || "[No license provided]"}</p>
                        <div className="flex gap-4">
                            <p className="text-sm">Born {row.dob ? moment(row.dob).format("M/D/YY") : "[Unknown]"}</p>
                            <span>•</span>
                            <p className="text-sm">Hired {row.policyDoh ? moment(row.policyDoh).format("M/D/YY") : "[Unknown]"}</p>
                        </div>
                        {tractors?.length ? (
                            <div className="">Drives tractor #{tractors[0]!.unitNumber || `...${tractors[0]!.vin?.slice(-4) || ""}`}</div>
                        ) : null}
                        {trailers?.length ? (
                            <div className="">Pulls trailer #{trailers[0]!.unitNumber || `...${trailers[0]!.vin?.slice(-4) || ""}`}</div>
                        ) : null}
                        <div className="flex gap-4">
                            <p className="text-sm tabular-nums">{row.policyExp} years of experience</p>
                            {row.policyOwnerOperator && (
                                <>
                                    <span>•</span>
                                    <p className="text-sm">Owner operator</p>
                                </>
                            )}
                        </div>
                        <div className="flex gap-2">
                            {row.coverageOptions?.length ? (
                                <span className="text-stone-600">
                                    {row.coverageOptions
                                        ?.map((c) => c.name)
                                        .sort()
                                        .join(", ")}
                                </span>
                            ) : (
                                <span className="text-stone-500">No coverages</span>
                            )}
                        </div>
                        {row.policyOwnerOperator ? (
                            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-stone-500">Employed by {meta.companyName}</p>
                        ) : row.employer ? (
                            <Tooltip
                                classes={{ tooltip: "bg-white shadow-lg rounded p-2 text-stone-800 font-normal border" }}
                                title={
                                    <div>
                                        <p>{row.employer.name}</p>
                                        <p>{stringifyAddress(row.employer)}</p>
                                        <p>{row.employer.email}</p>
                                        <p>{row.employer.fax}</p>
                                    </div>
                                }
                            >
                                <p className="overflow-hidden text-ellipsis whitespace-nowrap text-stone-500">
                                    Employed by {row.employer.name}
                                </p>
                            </Tooltip>
                        ) : (
                            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-yellow-600">Employer not set</p>
                        )}
                    </Popover.Button>
                </Popover>
                <ChangeDialog open={changeDialogOpen} onClose={() => setChangeDialogOpen(false)} row={info.row.original} />
                <RemovalDialog
                    open={removalDialogOpen}
                    onClose={() => setRemovalDialogOpen(false)}
                    row={info.row.original}
                    policyId={meta.policyId}
                />
            </>
        );
    },
    meta: { responsive: true },
};

export const nameColumn: AccessorColumnDef<DriverRow> = {
    accessorFn: (row) => row.name,
    cell: (info) => (
        <Tooltip
            classes={{ tooltip: "font-normal" }}
            title={
                <div>
                    <p>Date of birth: {info.row.original.dob ? moment(info.row.original.dob).format("MM/DD/YYYY") : "-"}</p>
                    <p>Experience: {info.row.original.policyExp}</p>
                </div>
            }
        >
            <div>
                <p className="font-medium">{info.row.original.name}</p>
                <p className="text-stone-600">{info.row.original.license}</p>
            </div>
        </Tooltip>
    ),
    header: "Name",
};

export const licenseColumn: AccessorColumnDef<DriverRow> = {
    accessorFn: (row) => row.license,
    header: "License",
    meta: {
        hidden: true,
    },
};

export const dobColumn: AccessorColumnDef<DriverRow> = {
    accessorFn: (row) => row.dob,
    id: "dob",
    header: () => <p className="text-right">DOB</p>,
    cell: (info) => <span>{info.getValue() ? moment.utc(info.getValue() as string).format("MM/DD/YYYY") : null}</span>,
    enableGlobalFilter: false,
    meta: {
        align: "right",
        classes: { cell: "tabular-nums" },
        hidden: true,
    },
    sortingFn: "datetime",
};

export const dohColumn: AccessorColumnDef<DriverRow> = {
    accessorFn: (row) => row.policyDoh,
    id: "doh",
    header: () => <p className="text-right">Date hired</p>,
    cell: (info) => <span>{info.getValue() ? moment.utc(info.getValue() as string).format("MM/DD/YYYY") : null}</span>,
    enableGlobalFilter: false,
    meta: {
        align: "right",
        classes: { cell: "tabular-nums" },
    },
    sortingFn: "datetime",
};

export const expColumn: AccessorColumnDef<DriverRow> = {
    id: "exp",
    accessorFn: (row) => row.policyExp,
    header: () => <p className="text-right">Experience (yrs)</p>,
    meta: {
        align: "right",
        classes: { cell: "tabular-nums" },
        hidden: true,
    },
    enableGlobalFilter: false,
    sortingFn: "alphanumeric",
};

export const actionsColumn: DisplayColumnDef<DriverRow> = {
    id: "actions",
    cell: function ActionsCell(info: CellContext<DriverRow, void>) {
        const meta = info.table.options.meta as DriverTableMeta;
        const navigate = useNavigate();
        const { data: ooProgram, refetch } = api.ooProgram.useGet(info.row.original.accountId);
        const [inviteFormOpen, setInviteFormOpen] = useState(false);
        const [decPageOpen, setDecPageOpen] = useState(false);
        const [changeDialogOpen, setChangeDialogOpen] = useState(false);
        const [removalDialogOpen, setRemovalDialogOpen] = useState(false);

        const invite = api.ooProgram.useInvite({
            onSuccess: () => {
                setInviteFormOpen(false);
                refetch();
            },
        });

        const remove = api.ooProgram.useRemove(info.row.original.accountId, {
            onSuccess: () => {
                useSnackbar.add("Removal request sent", { variant: "success", autoHideDuration: 7500, persist: true });
            },
        });

        return (
            <>
                <Popover>
                    <Popover.Content>
                        {info.row.original.policyOwnerOperator ? (
                            info.row.original.ownerOperatorMembership ? (
                                <>
                                    <Popover.Item
                                        onClick={() => {
                                            setDecPageOpen(true);
                                        }}
                                    >
                                        <ShieldCheckIcon className="h-5 w-5" />
                                        <span>View dec page</span>
                                    </Popover.Item>
                                    <Popover.Item
                                        onClick={() => {
                                            navigate(`/oo/${info.row.original.ownerOperatorMembership!.id}/policies/active`);
                                        }}
                                    >
                                        <ArrowRightCircleIcon className="h-5 w-5" />
                                        <span>View owner operator</span>
                                    </Popover.Item>
                                    <Popover.Item
                                        onClick={() => {
                                            remove.mutate(info.row.original.ownerOperatorMembership!.id);
                                        }}
                                    >
                                        <MinusCircleIcon className="h-5 w-5" />
                                        Request to remove from program
                                    </Popover.Item>
                                    <hr className="my-1" />
                                </>
                            ) : ooProgram?.invitations.some((i) => i.license === info.row.original.license) ? (
                                <Popover.Item disabled>
                                    <CheckCircleIcon className="h-5 w-5" />
                                    <span>Invited to Navigator O/O program</span>
                                </Popover.Item>
                            ) : (
                                <Popover.Item onClick={() => setInviteFormOpen(true)}>
                                    <PlusCircleIcon className="h-5 w-5" />
                                    <span>Invite to Navigator O/O program</span>
                                </Popover.Item>
                            )
                        ) : null}
                        <Popover.Item
                            onClick={() => {
                                setChangeDialogOpen(true);
                            }}
                            disabled={info.row.original.requiresApproval}
                        >
                            <PencilSquareIcon className="h-5 w-5" />
                            <span>Edit{info.row.original.requiresApproval ? " (pending)" : ""}</span>
                        </Popover.Item>
                        <Popover.Item
                            disabled={info.row.original.requiresApproval}
                            onClick={() => {
                                setRemovalDialogOpen(true);
                            }}
                        >
                            <MinusCircleIcon className="h-5 w-5" />
                            <span>Remove</span>
                        </Popover.Item>
                    </Popover.Content>
                    <Popover.Button className="rounded p-1 hover:bg-stone-100">
                        <EllipsisVerticalIcon className="h-5 w-5" />
                    </Popover.Button>
                </Popover>
                <ChangeDialog open={changeDialogOpen} onClose={() => setChangeDialogOpen(false)} row={info.row.original} />
                <RemovalDialog
                    open={removalDialogOpen}
                    onClose={() => setRemovalDialogOpen(false)}
                    row={info.row.original}
                    policyId={meta.policyId}
                />
                {info.row.original.ownerOperatorMembership && (
                    <AccountIdProvider accountId={info.row.original.ownerOperatorMembership.id}>
                        <PolicyViewDialog
                            policyId={info.row.original.ownerOperatorMembership.policies[0]?.id}
                            open={decPageOpen}
                            onClose={() => setDecPageOpen(false)}
                        />
                    </AccountIdProvider>
                )}
                <OOProgramInviteForm
                    open={inviteFormOpen}
                    onClose={() => setInviteFormOpen(false)}
                    onSubmit={(data) => {
                        invite.mutate({
                            accountId: info.row.original.accountId,
                            ...data,
                        });
                    }}
                    defaultValues={{
                        name: info.row.original.name || "",
                        license: info.row.original.license || "",
                    }}
                />
            </>
        );
    },
};

export const ownerOperatorColumn: AccessorColumnDef<DriverRow> = {
    id: "owner-operator",
    accessorFn: (row) => row.policyOwnerOperator,
    header: "Owner op.",
    cell: function OwnerOperatorCell(info) {
        const { data: ooProgram } = api.ooProgram.useGet(info.row.original.accountId);

        if (!info.row.original.policyOwnerOperator) {
            return null;
        }

        if (info.row.original.ownerOperatorMembership) {
            return (
                <div className="flex w-max items-center gap-2 rounded-full bg-emerald-50 px-2 text-xs text-emerald-600 small-caps">
                    <CheckIcon className="h-3 w-3" />
                    <span>joined</span>
                </div>
            );
        }

        if (ooProgram?.invitations.some((i) => i.license === info.row.original.license)) {
            return (
                <div className="flex w-max items-center gap-2 rounded-full bg-yellow-50 px-2 text-xs text-yellow-600 small-caps">
                    <CheckIcon className="h-3 w-3" />
                    <span>invited</span>
                </div>
            );
        }

        return <CheckIcon className="mx-2 h-3 w-3" />;
    },
};

export const coveragesColumn: DisplayColumnDef<DriverRow> = {
    id: "coverages",
    cell: (info) => {
        const coverages = info.row.original.coverageOptions;
        const ooCoverages = info.row.original.ownerOperatorMembership?.coverages;
        if (coverages?.length || ooCoverages?.length) {
            return (
                <Tooltip
                    classes={{ tooltip: "font-normal" }}
                    title={
                        coverages?.length || ooCoverages?.length ? (
                            <ul>
                                {coverages?.map((c) => (
                                    <li key={c.name}>
                                        {c.name}: <span className="text-emerald-600">Active</span>
                                    </li>
                                ))}
                                {ooCoverages?.map((c) => (
                                    <li key={c}>
                                        {CoverageOption.getMetadata(c).name}:{" "}
                                        <span
                                            className={
                                                !info.row.original.ownerOperatorMembership?.status ||
                                                info.row.original.ownerOperatorMembership?.status === OOProgramPolicyStatus.Active
                                                    ? "text-emerald-600"
                                                    : info.row.original.ownerOperatorMembership?.status === OOProgramPolicyStatus.Inactive
                                                    ? "text-red-600"
                                                    : info.row.original.ownerOperatorMembership?.status ===
                                                      OOProgramPolicyStatus.PendingCancellation
                                                    ? "text-yellow-600"
                                                    : ""
                                            }
                                        >
                                            {info.row.original.ownerOperatorMembership?.status || "Active"}
                                        </span>
                                    </li>
                                ))}
                                {info.row.original.policyOwnerOperator &&
                                    !info.row.original.coverageOptions?.some((c) => c.id === CoverageOption.Id.NON_TRUCKING_LIABILITY) &&
                                    !info.row.original.ownerOperatorMembership && (
                                        <li>
                                            Non-Trucking Liability: <span className="text-stone-400">Unknown</span>
                                        </li>
                                    )}
                            </ul>
                        ) : (
                            ""
                        )
                    }
                >
                    <div className="flex flex-wrap gap-1">
                        {coverages
                            ?.map((option) => option.abbreviation || CoverageOption.getMetadata(option.id)?.defaultAbbreviation)
                            .sort()
                            .map((abbr) => (
                                <span key={abbr}>{abbr}</span>
                            ))}
                        {info.row.original.policyOwnerOperator ? (
                            info.row.original.ownerOperatorMembership ? (
                                ooCoverages?.length ? (
                                    (ooCoverages.map((c) => CoverageOption.getMetadata(c).defaultAbbreviation) || []).sort().map((abbr) => (
                                        <span
                                            key={abbr}
                                            className={
                                                info.row.original.ownerOperatorMembership!.status === OOProgramPolicyStatus.Inactive
                                                    ? "text-red-600"
                                                    : info.row.original.ownerOperatorMembership!.status ===
                                                      OOProgramPolicyStatus.PendingCancellation
                                                    ? "text-yellow-600"
                                                    : ""
                                            }
                                        >
                                            {abbr}
                                        </span>
                                    ))
                                ) : (
                                    <span className="text-red-600">NTL?</span>
                                )
                            ) : null
                        ) : null}
                    </div>
                </Tooltip>
            );
        } else {
            return <span className="font-medium">[Not covered]</span>;
        }
    },
    header: "Coverages",
    enableGlobalFilter: false,
};

export const linkedTractorsColumn: AccessorColumnDef<DriverRow> = {
    id: "tractors",
    header: "Tractor(s)",
    accessorFn: (row) => row.tractors,
    cell: function LinkedTractorCell(info) {
        const { data: tractors } = api.equipment.useFindTractors(info.row.original.accountId);
        const meta = info.table.options.meta as DriverTableMeta;
        const linked = info.row.original.tractors?.map((t1) => tractors?.find((t2) => t1.id === t2.id)).filter(Boolean) as Tractor[];

        return (
            <Tooltip
                classes={{ tooltip: "bg-white shadow-lg rounded p-2 text-stone-800 font-normal border" }}
                title={
                    linked?.length ? (
                        <div className="space-y-4">
                            {linked.map((tractor) => (
                                <div key={tractor.id}>
                                    <p>Unit #{tractor.unitNumber}</p>
                                    <p>{tractor.make}</p>
                                    <p>{tractor.type?.name}</p>
                                    <p>VIN #{tractor.vin}</p>
                                    <p>
                                        {tractor.policyAcv
                                            ? "ACV"
                                            : tractor.policyValue?.toLocaleString("en-us", {
                                                  style: "currency",
                                                  currency: "usd",
                                                  maximumFractionDigits: 0,
                                              })}
                                    </p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        ""
                    )
                }
            >
                <button
                    className="whitespace-nowrap rounded px-2 py-1 text-left ring-blue-700 hover:bg-stone-50 focus:bg-stone-100 focus:ring-1"
                    onClick={() => {
                        meta.openTractorLinker(info.row.original.id);
                    }}
                >
                    {linked?.length ? (
                        <span>
                            {linked.map((t) => (t!.unitNumber ? `#${t!.unitNumber}` : `#...${t!.vin?.slice(-4) || ""}`)).join(", ")}
                        </span>
                    ) : (
                        <span className="italic text-stone-400">Unassigned</span>
                    )}
                </button>
            </Tooltip>
        );
    },
    enableSorting: false,
    enableGlobalFilter: false,
};

export const linkedTrailersColumn: AccessorColumnDef<DriverRow> = {
    id: "trailers",
    header: "Trailer(s)",
    accessorFn: (row) => row.trailers,
    cell: function LinkedTrailerCell(info) {
        const { data: trailers } = api.equipment.useFindTrailers(info.row.original.accountId);
        const meta = info.table.options.meta as DriverTableMeta;
        const linked = info.row.original.trailers?.map((t1) => trailers?.find((t2) => t1.id === t2.id)).filter(Boolean) as Trailer[];

        return (
            <Tooltip
                classes={{ tooltip: "bg-white shadow-lg rounded p-2 text-stone-800 font-normal border" }}
                title={
                    linked?.length ? (
                        <div className="space-y-4">
                            {linked.map((tractor) => (
                                <div key={tractor.id}>
                                    <p>Unit #{tractor.unitNumber}</p>
                                    <p>{tractor.make}</p>
                                    <p>{tractor.type?.name}</p>
                                    <p>VIN #{tractor.vin}</p>
                                    <p>
                                        {tractor.policyAcv
                                            ? "ACV"
                                            : tractor.policyValue?.toLocaleString("en-us", {
                                                  style: "currency",
                                                  currency: "usd",
                                                  maximumFractionDigits: 0,
                                              })}
                                    </p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        ""
                    )
                }
            >
                <button
                    className="whitespace-nowrap rounded px-2 py-1 text-left ring-blue-700 hover:bg-stone-50 focus:bg-stone-100 focus:ring-1"
                    onClick={() => {
                        meta.openTrailerLinker(info.row.original.id);
                    }}
                >
                    {linked?.length ? (
                        <span>
                            {linked.map((t) => (t!.unitNumber ? `#${t!.unitNumber}` : `#...${t!.vin?.slice(-4) || ""}`)).join(", ")}
                        </span>
                    ) : (
                        <span className="italic text-stone-400">Unassigned</span>
                    )}
                </button>
            </Tooltip>
        );
    },
    enableSorting: false,
    enableGlobalFilter: false,
};

export const employerColumn: AccessorColumnDef<DriverRow | MonthlyReportDataUnit<Driver>> = {
    id: "employer",
    header: "Employer",
    accessorFn: (row) => row.employer,
    cell: function EmployerCell(info) {
        const meta = info.table.options.meta as DriverTableMeta;
        const employer = info.row.original.employer;

        if (!info.row.original.policyOwnerOperator && (!("isOwnerOperator" in info.row.original) || !info.row.original.isOwnerOperator)) {
            return <div className="max-w-36 overflow-hidden text-ellipsis whitespace-nowrap px-2 text-stone-500">{meta.companyName}</div>;
        }

        return (
            <Tooltip
                classes={{ tooltip: "bg-white shadow-lg rounded p-2 text-stone-800 font-normal border" }}
                title={
                    employer ? (
                        <div>
                            <p>{employer.name}</p>
                            <p>{stringifyAddress(employer)}</p>
                            <p>{employer.email}</p>
                            <p>{employer.fax}</p>
                        </div>
                    ) : (
                        ""
                    )
                }
            >
                <button
                    className="max-w-36 overflow-hidden text-ellipsis whitespace-nowrap rounded px-2 py-1 text-left ring-blue-700 hover:bg-stone-50 focus:bg-stone-100 focus:ring-1"
                    onClick={() => {
                        meta.openEmployerLinker(info.row.original.id);
                    }}
                >
                    {employer ? <span>{employer.name}</span> : <span className="italic text-stone-400">Unassigned</span>}
                </button>
            </Tooltip>
        );
    },
    enableSorting: false,
    enableGlobalFilter: false,
};

export function RemovalDialog({
    row,
    open,
    onClose,
    dialogProps,
    policyId,
    defaultRemovalDate,
}: {
    row: DriverRow;
    open: boolean;
    onClose(): void;
    dialogProps?: Partial<Omit<DialogProps, "children" | "open" | "onClose">>;
    policyId?: string;
    defaultRemovalDate?: string;
}) {
    const [removalDate, setRemovalDate] = useState(
        defaultRemovalDate
            ? moment(defaultRemovalDate, "YYYY-MM-DD").isAfter(moment(), "day")
                ? moment().format("YYYY-MM-DD")
                : defaultRemovalDate
            : moment().format("YYYY-MM-DD")
    );

    const removeDriver = api.drivers.useRequestRemoval(row.accountId, {
        onSuccess: () => {
            onClose();
        },
    });

    const removalDateError = useMemo(() => {
        const m = moment(removalDate);
        if (!m.isValid()) {
            return <Alert variant="warning">Invalid date</Alert>;
        }
        if (m.isAfter(moment(), "day")) {
            return (
                <Alert variant="warning">
                    If you want to remove this driver at a future date, either come back here when you are ready or call our office
                </Alert>
            );
        }

        if (moment().date() >= 15 && m.isBefore(moment(), "month")) {
            return <Alert variant="warning">Date is too far in the past</Alert>;
        }

        if (moment().date() < 15 && m.isBefore(moment().subtract(1, "month"), "month")) {
            return <Alert variant="warning">Date is too far in the past</Alert>;
        }

        return null;
    }, [removalDate]);

    return (
        <Dialog
            {...dialogProps}
            open={open}
            onClose={() => {
                //noop
            }}
        >
            <div className="space-y-8 p-4">
                <h1 className="text-xl font-bold">Are you sure?</h1>
                <p className="text-stone-700">Please confirm that you want to remove the following driver from your coverages:</p>
                <div className="ml-6 flex items-center gap-2">
                    <MinusCircleIcon className="h-4 w-4 text-red-600" />
                    <p>
                        {row.name} {row.license}
                    </p>
                </div>
                <InputField
                    value={removalDate}
                    onChange={(e) => setRemovalDate(e.target.value)}
                    type="date"
                    label="Remove from coverage on"
                />
                {removalDateError}
                {removeDriver.isError && (
                    <Alert variant="error">{removeDriver.error.message || "An error occurred. Please try again later."}</Alert>
                )}
                <div className="flex w-full justify-end gap-6">
                    <SecondaryButton onClick={() => onClose()} disabled={removeDriver.isPending}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            removeDriver.mutate({ id: row.id, requestDate: removalDate, policyId });
                        }}
                        disabled={removeDriver.isPending || !!removalDateError}
                    >
                        {removeDriver.isPending ? (
                            <>
                                <CircularProgress size="1rem" classes={{ circle: "text-white" }} />
                                <span>Removing...</span>
                            </>
                        ) : (
                            "Remove"
                        )}
                    </PrimaryButton>
                </div>
            </div>
        </Dialog>
    );
}

export function ChangeDialog({
    row,
    open,
    onClose,
    dialogProps,
}: {
    row: DriverRow;
    open: boolean;
    onClose(): void;
    dialogProps?: Partial<Omit<DialogProps, "children" | "open" | "onClose">>;
}) {
    const { mutate: requestChange, isPending } = api.drivers.useRequestChange(row.accountId, {
        onSuccess: () => {
            onClose();
            useSnackbar.add("Request submitted", { variant: "success" });
        },
        onError: (error) => {
            if (error.status === 403) {
                useSnackbar.add("You do not have permission to manage drivers.", {
                    variant: "error",
                });
            } else {
                useSnackbar.add("Could not submit your request. Please try again later.", {
                    variant: "error",
                });
            }
        },
    });

    const form = useForm({ defaultValues: { requestComment: "", requestDate: new Date().toISOString().substring(0, 10) } });

    return (
        <Dialog {...dialogProps} open={open} onClose={onClose} className="w-screen max-w-[95vw] sm:max-w-sm">
            <form
                className="w-full space-y-4 p-4"
                onSubmit={form.handleSubmit((data) => {
                    requestChange({ ...data, id: row.id });
                })}
            >
                <p className="text-stone-500">Change request for driver {row.name}</p>
                <InputField
                    label="Date of change"
                    type="date"
                    {...form.register("requestDate", {
                        required: true,
                        validate: (value) => (value && moment(value).isSameOrAfter(moment(), "day")) || "Date must be today or later",
                    })}
                    required
                />
                <InputField
                    label="Change description"
                    {...form.register("requestComment", {
                        required: true,
                        maxLength: { value: 256, message: "Description cannot be more than 256 characters" },
                    })}
                    required
                />
                <p className="text-sm text-stone-500">
                    Please be as verbose and clear as possible, providing all the necessary information to make the requested change.
                </p>
                <p className="text-red-600">
                    {Object.values(form.formState.errors)
                        .map((error) => error.message)
                        .join("; ")}
                </p>
                <PrimaryButton type="submit" className="float-right" disabled={isPending || row.requiresApproval}>
                    Submit request
                </PrimaryButton>
            </form>
        </Dialog>
    );
}

export const DriverColumns = {
    mobileCell,
    nameColumn,
    pending: equipmentColumns.pending as AccessorColumnDef<DriverRow>,
    upcomingDate: equipmentColumns.upcomingDate as AccessorColumnDef<DriverRow>,
    licenseColumn,
    dobColumn,
    dohColumn,
    expColumn,
    ownerOperatorColumn,
    coveragesColumn,
    linkedTractorsColumn,
    linkedTrailersColumn,
    employerColumn,
    actionsColumn,
};
