import { FMCSA } from "@deathstar/types";
import { DateTime } from "@deathstar/types/util";
import { EnforcementCase } from "../EnforcementCase/EnforcementCase";
import { MotorCarrier } from "../MotorCarrier";

export class EnforcementCases {
    // ========================================================================
    static sorter(order: "ASC" | "DESC"): (a: EnforcementCase, b: EnforcementCase) => number {
        return (a: EnforcementCase, b: EnforcementCase) => {
            if (a.date < b.date) {
                return order === "ASC" ? -1 : 1;
            }
            if (a.date > b.date) {
                return order === "ASC" ? 1 : -1;
            }
            return 0;
        };
    }

    // ========================================================================
    static sort(cases: EnforcementCase[], order: "ASC" | "DESC"): EnforcementCase[] {
        const sortFn = EnforcementCases.sorter(order);
        return cases.sort(sortFn);
    }

    // ========================================================================
    static from(inspections: EnforcementCases, rawCases?: EnforcementCase[]): EnforcementCases {
        const newCases = new EnforcementCases(inspections.#carrier);
        if (rawCases) {
            newCases.#addCases(rawCases);
        }
        return newCases;
    }

    // ========================================================================
    static of(carrier: MotorCarrier, cases?: EnforcementCase[]): EnforcementCases {
        const newCases = new EnforcementCases(carrier);
        if (cases) {
            newCases.#addCases(cases);
        }
        return newCases;
    }

    // ========================================================================
    static new(carrier: MotorCarrier, rawEnforcementCases: FMCSA.EnforcementCase[]): EnforcementCases {
        const newCases = new EnforcementCases(carrier);
        newCases.#addCases(rawEnforcementCases.map((rcs) => EnforcementCase.new(carrier, rcs)));
        return newCases;
    }

    // ========================================================================
    #carrier: MotorCarrier;
    #caseNumbers = new Set<string>();
    #cases: EnforcementCase[] = [];
    private constructor(carrier: MotorCarrier) {
        this.#carrier = carrier;
    }

    // ========================================================================
    *[Symbol.iterator](): IterableIterator<EnforcementCase> {
        for (const enforcementCase of this.#cases) {
            yield enforcementCase;
        }
    }

    // ========================================================================
    #addCases(cases: EnforcementCase[]): void {
        cases.forEach((cs) => {
            if (this.#caseNumbers.has(cs.number)) return;
            this.#caseNumbers.add(cs.number);
            this.#cases.push(cs);
        });
    }

    // ========================================================================
    get carrier(): MotorCarrier {
        return this.#carrier;
    }

    // ========================================================================
    get total(): number {
        return this.#cases.length;
    }

    // ========================================================================
    sortByDate(order: "ASC" | "DESC" = "DESC"): this {
        EnforcementCases.sort(this.#cases, order);
        return this;
    }

    // ========================================================================
    /**
     * Filters the EnforcementCases by date.
     * @param date the date used in the filter
     */
    filterOccurredAfterDate(date: Date): EnforcementCases {
        const cases = this.#cases.filter((cs) => {
            if (cs.occurredAfterDate(date)) return cs;
            return null;
        });
        return EnforcementCases.of(this.#carrier, cases);
    }

    // ========================================================================
    /**
     * Filters the EnforcementCases by date.
     * @param date the date used in the filter
     */
    filterOccurredBeforeDate(date: Date): EnforcementCases {
        const cases = this.#cases.filter((cs) => {
            if (cs.occurredBeforeDate(date)) return cs;
            return null;
        });
        return EnforcementCases.of(this.#carrier, cases);
    }

    // ========================================================================
    /**
     * Filters the EnforcementCases by date.
     * @param date the date used in the filter
     */
    filterOccurredOnDate(date: Date): EnforcementCases {
        const cases = this.#cases.filter((cs) => {
            if (cs.occurredOnDate(date)) return cs;
            return null;
        });
        return EnforcementCases.of(this.#carrier, cases);
    }

    // ========================================================================
    #filteredByDateRangeMap: Map<string, EnforcementCases> = new Map();
    #calculateFilteredByDateRange({ from, to }: { from: DateTime; to: DateTime }): EnforcementCases {
        const dateRangeId = `${from.format("YYYYMMDD")}-${to.format("YYYYMMDD")}`;
        if (this.#filteredByDateRangeMap.has(dateRangeId)) {
            return this.#filteredByDateRangeMap.get(dateRangeId)!;
        }
        const cases = EnforcementCases.of(
            this.#carrier,
            this.#cases.filter((insp) => {
                if (insp.date.isBetween(from, to)) return insp;
                return null;
            })
        );
        this.#filteredByDateRangeMap.set(dateRangeId, cases);
        return cases;
    }
    /**
     * Filters the EnforcementCases by date range.
     */
    filterByDateRange({ from, to }: { from: DateTime; to: DateTime }): EnforcementCases {
        return this.#calculateFilteredByDateRange({ from, to });
    }

    // ========================================================================
    filterBySubjectType(type: FMCSA.EnforcementCaseSubjectType): EnforcementCases {
        const cases = this.#cases.filter((cs) => {
            if (type === cs.get("SubjectType")) return cs;
            return null;
        });
        return EnforcementCases.of(this.#carrier, cases);
    }

    // ========================================================================
    /**
     * Filters the Enforcement Cases by BASIC
     */
    filterByBasic(basic: FMCSA.BasicName): EnforcementCases {
        return this.#cases.reduce((cases, enfCase) => {
            if (enfCase.hasViolationOfBasic(basic)) {
                cases.#addCases([enfCase]);
            }
            return cases;
        }, new EnforcementCases(this.#carrier));
    }

    // ========================================================================
    json(): EnforcementCase[] {
        return this.#cases;
    }

    // ========================================================================
    raw(): EnforcementCase.Raw[] {
        return this.#cases.map((cs) => cs.raw());
    }

    // ========================================================================
    array(options?: { valid?: boolean }): EnforcementCase[] {
        if (options?.valid) return Array.from(this.#cases) ?? [];
        return Array.from(this.#cases) ?? [];
    }

    // ========================================================================
    take(amount: number): EnforcementCases {
        const newCases = new EnforcementCases(this.#carrier);
        newCases.#cases = Array.from(this).slice(0, amount);
        return newCases;
    }
}

// ========================================================================
export namespace Inspections {
    interface IFilterInspectionLevelsByLevelIds {
        levels?: never;
        levelIds: FMCSA.InspectionLevelId[] | number[];
    }
    interface IFilterInspectionLevelsByLevelNames {
        levels: FMCSA.InspectionLevel[];
        levelIds?: never;
    }
    export type IFilterInspectionLevelsOptions = IFilterInspectionLevelsByLevelIds | IFilterInspectionLevelsByLevelNames;
    export interface InitOptions {
        carrier: MotorCarrier;
        fetcher: MotorCarrier.IFetcher;
        options: {
            targetDate: Date;
        };
    }
}
