import { DateTime } from "@deathstar/types/util";
import { MotorCarrier } from "../../MotorCarrier";
import { Violations } from "../Violations";
import { BASIC } from "./BASIC/BASIC";
import { ControlledSubstances } from "./BASIC/ControlledSubstances";
import { DriverFitness } from "./BASIC/DriverFitness";
import { Hazmat } from "./BASIC/Hazmat";
import { HoursOfService } from "./BASIC/HoursOfService";
import { UnsafeDriving } from "./BASIC/UnsafeDriving";
import { VehicleMaintenance } from "./BASIC/VehicleMaintenance";
import { Breakdowns } from "./Breakdowns";

export class Breakdown {
    static #jsonMapper<GroupNames extends string, SubGroupNames extends string>(
        group: Breakdown.Group<GroupNames, SubGroupNames>
    ): ReturnType<Breakdown.Group<GroupNames, SubGroupNames>["json"]> {
        return group.json();
    }

    // ========================================================================
    readonly dateRange: {
        from: DateTime;
        to: DateTime;
    };
    #violations: Violations;
    #carrier: MotorCarrier;
    #breakdowns: Breakdowns;

    readonly unsafeDriving: UnsafeDriving;
    readonly hoursOfService: HoursOfService;
    readonly vehicleMaintenance: VehicleMaintenance;
    readonly driverFitness: DriverFitness;
    readonly hazmat: Hazmat;
    readonly controlledSubstances: ControlledSubstances;

    public constructor(breakdowns: Breakdowns, carrier: MotorCarrier, date: DateTime, violations: Violations) {
        this.#breakdowns = breakdowns;
        this.#violations = violations;
        this.#carrier = carrier;
        this.dateRange = {
            from: DateTime.subtractYears(date, 2).freeze(),
            to: date.freeze(),
        };

        const filtered = this.#violations.filterByDateRange(this.dateRange).filterByBasics();
        this.unsafeDriving = new UnsafeDriving(this, carrier, date, filtered.unsafeDriving);
        this.hoursOfService = new HoursOfService(this, carrier, date, filtered.hoursOfService);
        this.vehicleMaintenance = new VehicleMaintenance(this, carrier, date, filtered.vehicleMaintenance);
        this.driverFitness = new DriverFitness(this, carrier, date, filtered.driverFitness);
        this.hazmat = new Hazmat(this, carrier, date, filtered.hazmat);
        this.controlledSubstances = new ControlledSubstances(this, carrier, date, filtered.controlledSubstances);
    }

    // ========================================================================
    sort(order: "ASC" | "DESC" = "ASC"): this {
        this.unsafeDriving.sort(order);
        this.hoursOfService.sort(order);
        this.vehicleMaintenance.sort(order);
        this.driverFitness.sort(order);
        this.hazmat.sort(order);
        this.controlledSubstances.sort(order);
        return this;
    }

    // ========================================================================
    getTopFour(): {
        unsafeDriving: Breakdown.Group<UnsafeDriving.GroupNames, UnsafeDriving.SubGroupNames>[];
        hoursOfService: Breakdown.Group<HoursOfService.GroupNames, HoursOfService.SubGroupNames>[];
        vehicleMaintenance: Breakdown.Group<VehicleMaintenance.GroupNames, VehicleMaintenance.SubGroupNames>[];
        driverFitness: Breakdown.Group<DriverFitness.GroupNames, DriverFitness.SubGroupNames>[];
        hazmat: Breakdown.Group<Hazmat.GroupNames, Hazmat.SubGroupNames>[];
        controlledSubstances: Breakdown.Group<ControlledSubstances.GroupNames, ControlledSubstances.SubGroupNames>[];
    } {
        return {
            unsafeDriving: this.unsafeDriving.getTopFourGroups(),
            hoursOfService: this.hoursOfService.getTopFourGroups(),
            vehicleMaintenance: this.vehicleMaintenance.getTopFourGroups(),
            driverFitness: this.driverFitness.getTopFourGroups(),
            hazmat: this.hazmat.getTopFourGroups(),
            controlledSubstances: this.controlledSubstances.getTopFourGroups(),
        };
    }

    // ========================================================================
    getHistory(): {
        unsafeDriving: UnsafeDriving[];
        hoursOfService: HoursOfService[];
        vehicleMaintenance: VehicleMaintenance[];
        driverFitness: DriverFitness[];
        hazmat: Hazmat[];
        controlledSubstances: ControlledSubstances[];
    } {
        const basic = this.#breakdowns.getByBasic();
        const filter = (bd: BASIC<string, string>) => {
            if (bd.date <= this.dateRange.to) return bd;
            return null;
        };
        return {
            unsafeDriving: basic.unsafeDriving.filter(filter),
            hoursOfService: basic.hoursOfService.filter(filter),
            vehicleMaintenance: basic.vehicleMaintenance.filter(filter),
            driverFitness: basic.driverFitness.filter(filter),
            hazmat: basic.hazmat.filter(filter),
            controlledSubstances: basic.controlledSubstances.filter(filter),
        };
    }
}

// ========================================================================
export namespace Breakdown {
    // ========================================================================
    class SubGroup<GroupNames extends string, SubGroupNames extends string> {
        // ========================================================================
        readonly total: number;
        readonly score: number;

        #carrier: MotorCarrier;
        #group: Group<GroupNames, SubGroupNames>;
        constructor(
            carrier: MotorCarrier,
            grouping: Group<GroupNames, SubGroupNames>,
            readonly name: SubGroupNames,
            readonly violations: Violations
        ) {
            this.#carrier = carrier;
            this.#group = grouping;
            this.total = this.violations.getTotalWeight();
            this.score = this.#calculateScore();
        }

        // ========================================================================
        #calculateScore(): number {
            if (this.total === 0 || this.#group.total === 0) return 0;
            return Math.round((this.total / this.#group.total) * 100);
        }

        // ========================================================================
        json(): { name: string; total: number; score: number } {
            return {
                name: this.name,
                total: this.total,
                score: this.score,
            };
        }
    }

    // ========================================================================
    export class Group<GroupNames extends string, SubGroupNames extends string> {
        // ========================================================================
        readonly total: number;
        readonly score: number;

        #carrier: MotorCarrier;
        #basic: BASIC<GroupNames, SubGroupNames>;
        #subGroups: SubGroup<GroupNames, SubGroupNames>[];
        constructor(
            carrier: MotorCarrier,
            basic: BASIC<string, string>,
            readonly name: GroupNames,
            groups: { name: SubGroupNames; violations: Violations }[]
        ) {
            this.#carrier = carrier;
            this.#basic = basic;

            this.total = groups.reduce((total, group) => {
                return total + group.violations.getTotalWeight();
            }, 0);

            this.#subGroups = groups.map((grp) => {
                return new SubGroup(carrier, this, grp.name, grp.violations);
            });

            this.score = this.#calculateScore();
        }

        // ========================================================================
        #calculateScore(): number {
            if (this.total === 0 || this.#basic.total === 0) return 0;
            return Math.round((this.total / this.#basic.total) * 100);
        }

        // ========================================================================
        get date(): DateTime {
            return this.#basic.date;
        }

        // ========================================================================
        get subGroups(): SubGroup<GroupNames, SubGroupNames>[] {
            return this.#subGroups;
        }

        // ========================================================================
        get(subGroupName: SubGroupNames): SubGroup<GroupNames, SubGroupNames> | undefined {
            return this.subGroups.find((group) => group.name === subGroupName);
        }

        // ========================================================================
        json(): { name: string; score: number; total: number; subGroups: ReturnType<SubGroup<GroupNames, SubGroupNames>["json"]>[] } {
            return {
                name: this.name,
                score: this.score,
                total: this.total,
                subGroups: this.#subGroups.map((subGroup) => subGroup.json()),
            };
        }

        // ========================================================================
        getHistory(): Group<GroupNames, SubGroupNames>[] {
            const historical = this.#basic.getHistory();

            const reducer = (groups: Group<GroupNames, SubGroupNames>[], basic: BASIC<GroupNames, SubGroupNames>) => {
                groups.push(basic.groups.find((grp) => grp.name === this.name)!);
                return groups;
            };

            return historical.reduce(reducer, []);
        }
    }
}

export namespace Breakdown {
    export type GroupNames =
        | UnsafeDriving.GroupNames
        | HoursOfService.GroupNames
        | VehicleMaintenance.GroupNames
        | DriverFitness.GroupNames
        | Hazmat.GroupNames
        | ControlledSubstances.GroupNames;

    export type SubGroupNames =
        | UnsafeDriving.SubGroupNames
        | HoursOfService.SubGroupNames
        | VehicleMaintenance.SubGroupNames
        | DriverFitness.SubGroupNames
        | Hazmat.SubGroupNames
        | ControlledSubstances.SubGroupNames;
}
